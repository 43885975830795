export class ScoreBug {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    vizCommand,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.vizCommand = vizCommand;
    this.name = "ScoreBug";
    this.scene = "ScoreBug";
    this.data = [];
    this.page = 10024;
  }

  async preview({ options }) {
    this.onAir = false;
  }
  animate({ options }) {
    let score = this.getScore(options.game);

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HomeBaseColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.home_team?.variables?.[
          options.game?.home_team_kit + "_kit_bg_viz"
        ] || ""),
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_AwayBaseColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.away_team?.variables?.[
          options.game?.away_team_kit + "_kit_bg_viz"
        ] || ""),
    });

    this.vizCommand({
      viz_command:
        "10 RENDERER*FRONT_LAYER SET_OBJECT SCENE*__SKY_SPORTS/SCENES_LIMA_3/RUGBY_LEAGUE/CLOCK/CLOCK_RGL_V107",
    });
    this.vizSharedMemory({
      shared_memory: `SMMSystem_SetValue|SHMSend_HomeTeamName|${
        options?.game?.home_team?.code || ""
      }`,
    });
    this.vizSharedMemory({
      shared_memory: `SMMSystem_SetValue|SHMSend_AwayTeamName|${
        options?.game?.away_team?.code || ""
      }`,
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HomeTextColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.home_team?.variables?.[
          options.game?.home_team_kit + "_kit_text_viz"
        ] || ""),
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_AwayTextColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.away_team?.variables?.[
          options.game?.away_team_kit + "_kit_text_viz"
        ] || ""),
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HomeScore|" + score?.home_score,
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_AwayScore|" + score?.away_score,
    });
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_TackleDistanceActive|0",
    });

    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_StatComponents|REG_Score,#,#",
    });

    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_PTSValue|" + options?.status,
    });
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_PTSState|2",
    });

    if (
      options.golden_point &&
      (!options.popout || options?.popout?.type === "none")
    ) {
      let winner_id =
        score?.home_score > score?.away_score
          ? "1"
          : score?.home_score < score?.away_score
          ? "2"
          : null;
      setTimeout(() => {
        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_StaticInsertComponents|" +
            (winner_id === "1"
              ? options?.game?.home_team?.short_name + " win in GP"
              : winner_id === "2"
              ? options?.game?.away_team?.short_name + " win in GP"
              : "After Golden Point") +
            "," +
            (winner_id || "0") +
            ",0,1",
        });
      }, 3000);
    } else if (options.popout && options.popout?.type !== "none") {
      let team =
        options.popout?.type === "home"
          ? "1"
          : options.popout?.type === "away"
          ? "2"
          : null;
      setTimeout(() => {
        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_StaticInsertComponents|" +
            options.popout.text +
            "," +
            (team ?? "0") +
            ",0,1",
        });
      }, 3000);
    } else {
      this.vizSharedMemory({
        shared_memory:
          "SMMSystem_SetValue|SHMSend_StaticInsertComponents|REG_SIOut,#,#,#",
      });
    }

    if (this.onAir === false) {
      this.update({ options: { ...options, action: "ON" } });
    }
    this.onAir = true;
  }

  animateOff({ options }) {
    this.onAir = false;
    this.update({ options: { ...options, action: "OFF" } });
  }
  update({ options }) {
    switch (options?.action || options?.type) {
      case "ON":
        let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
          return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
        }, 0);

        let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
          return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
        }, 0);

        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_HSentOff|" +
            (home_reds > 0
              ? new Array(home_reds)
                  ?.fill(1)
                  ?.map((y) => "r")
                  ?.join("")
              : ""),
        });

        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_ASentOff|" +
            (away_reds > 0
              ? new Array(away_reds)
                  ?.fill(1)
                  ?.map((y) => "r")
                  ?.join("")
              : ""),
        });

        this.vizCommand({
          viz_command: "10 FRONT_SCENE*STAGE*DIRECTOR*Default START",
        });
        this.onAir = true;
        break;
      case "OFF":
        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_StaticInsertComponents|REG_SIOut,#,#,#",
        });
        setTimeout(() => {
          this.vizCommand({
            viz_command: "10 FRONT_SCENE*STAGE*DIRECTOR*Default GOTO $IN $OUT",
          });
        }, 1000);
        this.onAir = false;
        break;
      default:
        break;
    }
  }
  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let home_tries =
      game?.home_scorers?.filter((g) => g.type === "try").length || 0;
    let home_conv =
      game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
    let home_pen_kick =
      game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let home_pen_try =
      game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let home_drop_goal =
      game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_tries =
      game?.away_scorers?.filter((g) => g.type === "try").length || 0;
    let away_conv =
      game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
    let away_pen_kick =
      game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let away_pen_try =
      game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let away_drop_goal =
      game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    return {
      home_score,
      away_score,
      home_tries,
      home_conv,
      home_pen_kick,
      home_pen_try,
      home_drop_goal,
      away_tries,
      away_conv,
      away_pen_kick,
      away_pen_try,
      away_drop_goal,
    };
  }
}
