import moment from "moment";

export class CBManual {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CBMANUAL";
    this.scene = "CBMANUAL";
    this.data = [];
    this.page = 7050;
  }

  async preview({ options }) {
    this.onAir = false;

    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = options?.box?.number_rows;

      let folder = "";
      if (options?.box?.title_badge_folder === "team") {
        folder = "IMAGE*___MEDIA/RUGBY_LEAGUE/TEAM_BADGES/";
      } else if (options?.box?.title_badge_folder === "competition") {
        folder = "IMAGE*___MEDIA/RUGBY_LEAGUE/COMPETITION_LOGOS/";
      }
      xml.payload.field.find((item) => {
        return item.attr.name === "001";
      }).value = folder + (options?.box?.title_badge ?? "");
      xml.payload.field.find((item) => {
        return item.attr.name === "00101";
      }).value = folder + (options?.box?.title_badge ?? "");
      xml.payload.field.find((item) => {
        return item.attr.name === "00101.active.active";
      }).value = options?.box?.show_badges ? "0" : "1";
      let lines = options?.box?.heading?.split("\n");

      xml.payload.field.find((item) => {
        return item.attr.name === "002";
      }).value = lines?.length > 1 ? lines?.[0] : "";
      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value = lines?.length > 1 ? lines?.[1] : lines?.[0];
      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = options?.box?.subtitle;
      xml.payload.field.find((item) => {
        return item.attr.name === "0003";
      }).value =
        options?.box?.subtitle && options?.box?.subtitle !== "" ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.ColBadge";
      }).value = options?.box?.show_badges ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.Col1";
      }).value =
        options?.box?.rows?.filter((r) => r?.number && r?.number !== "")
          ?.length > 0
          ? "1"
          : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.Col3";
      }).value = options?.box?.number_columns >= 1 ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.Col4";
      }).value = options?.box?.number_columns >= 2 ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.Col5";
      }).value = options?.box?.number_columns >= 3 ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.Col6";
      }).value = options?.box?.number_columns >= 4 ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "006";
      }).value = options?.box?.column_1;

      xml.payload.field.find((item) => {
        return item.attr.name === "007";
      }).value = options?.box?.column_2;

      xml.payload.field.find((item) => {
        return item.attr.name === "008";
      }).value = options?.box?.column_3;

      xml.payload.field.find((item) => {
        return item.attr.name === "009";
      }).value = options?.box?.column_4;

      for (let r = 0; r < options?.box?.number_rows; r++) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "0";
        }).value = options?.box?.rows?.[r]?.highlighted ? "1" : "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "9";
        }).value = options?.box?.rows?.[r]?.dots ? "1" : "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "1";
        }).value = options?.box?.rows?.[r]?.number ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "2";
        }).value = options?.box?.rows?.[r]?.badge ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "3";
        }).value = options?.box?.rows?.[r]?.text ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "4";
        }).value = options?.box?.rows?.[r]?.small_text ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "5";
        }).value = options?.box?.rows?.[r]?.col_1text ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "6";
        }).value = options?.box?.rows?.[r]?.col_2text ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "7";
        }).value = options?.box?.rows?.[r]?.col_3text ?? "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (r + 1) + "8";
        }).value = options?.box?.rows?.[r]?.col_4text ?? "";
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
