import React, { useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { VizContext } from "../../contexts/VizContext";

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  opacity: 0.7;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  padding: 0.5em;
  font-weight: 100;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelColour};
  width: 700px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.highlightColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;

const Group = styled.div`
  display: flex;
  padding: 1em;
  border-bottom: 1px solid black;
`;

const Command = styled.div`
  display: flex;
  padding: 1em;
  min-width: 200px;
`;

const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
  min-width: 300px;
`;

export default function Config({ title, onClose }) {
  const { hyperClock, setHyperClock } = useContext(ControlContext);

  const {
    hyperLink,
    setHyperLink,
    mse,
    setMSE,
    trio,
    vizEngine,
    setTrio,
    setVizEngine,
    setVizEngineSharedMemory,
    vizEngineSharedMemory,
    trioShow,
    setTrioShow,
    vizProfile,
    setVizProfile,
  } = useContext(VizContext);
  return (
    <>
      <Screen></Screen>
      <Main>
        <Content>
          <Header>{title}</Header>
          <Group>
            <Command>Hyper Link Server </Command>
            <Input
              value={hyperLink}
              onChange={(e) => setHyperLink(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>MSE </Command>
            <Input
              value={mse}
              onChange={(e) => setMSE(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>Trio </Command>
            <Input
              value={trio}
              onChange={(e) => setTrio(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>Trio Show </Command>
            <Input
              value={trioShow}
              onChange={(e) => setTrioShow(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>Viz Engine </Command>
            <Input
              value={vizEngine}
              onChange={(e) => setVizEngine(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>Viz Engine Shared Memory </Command>
            <Input
              value={vizEngineSharedMemory}
              onChange={(e) => setVizEngineSharedMemory(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>Viz Profile </Command>
            <Input
              value={vizProfile}
              onChange={(e) => setVizProfile(e.currentTarget.value)}
            />
          </Group>
          <Group>
            <Command>Stadium Clock </Command>
            <Input
              value={hyperClock}
              onChange={(e) => setHyperClock(e.currentTarget.value)}
            />
          </Group>
          {/* <Group>
            <Command>Hyper command </Command>
            <Input
              value={hyperCommand}
              onChange={(e) => setHyperCommand(e.currentTarget.value)}
            />
          </Group> */}
          <Buttons>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Save
            </Button>
          </Buttons>
        </Content>
      </Main>
    </>
  );
}
