import moment from "moment";

export class LTSinBin {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "LTSINBIN";
    this.scene = "LTSINBIN";
    this.data = [];
    this.page = 7002;
    this.normal_page = 7002;
    this.countrdown_page = 7003;
  }

  async preview({ options }) {
    this.onAir = false;
    if (options?.countdown) {
      this.page = this.countrdown_page;
    } else {
      this.page = this.normal_page;
    }
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value = options.team?.variables?.badge || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "101";
      }).value = options.show_number ? options.player?.shirt_number || "" : "";

      xml.payload.field.find((item) => {
        return item.attr.name === "102";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "104";
      }).value =
        "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
        (options.player?.nationality?.code?.toLowerCase() ||
          options.player?.nationality?.opta_code?.toLowerCase() ||
          "");
      debugger;
      if (options?.countdown) {
        try {
          let game_mins =
            options?.clockType === "manual"
              ? parseInt(document.getElementById("manual_minutes").innerText)
              : parseInt(document.getElementById("stadium_minutes").innerText);

          let game_secs =
            options?.clockType === "manual"
              ? parseInt(document.getElementById("manual_seconds").innerText)
              : parseInt(document.getElementById("stadium_seconds").innerText);

          let duration = moment.duration({
            minutes: game_mins,
            seconds: game_secs,
          });
          debugger;
          let duration_2 = moment.duration({
            minutes: options?.player?.sinbins?.at(-1)?.game_time_minutes,
            seconds: options?.player?.sinbins?.at(-1)?.game_time_seconds,
          });

          let total_sinbin = moment.duration({ minutes: 10 });

          let diff = total_sinbin.subtract(duration.subtract(duration_2));

          let future = moment().add(diff);

          xml.payload.field.find((item) => {
            return item.attr.name === "211";
          }).value = future.format("HH|mm|ss|DD|MM|YYYY");
        } catch (err) {}
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
