import moment from "moment";

export class LTPlayerScored {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "PlayerScored";
    this.scene = "PlayerScored";
    this.data = [];
    this.page = 7000;
  }

  async preview({ options }) {
    this.onAir = false;

    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = 1;

      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value = options.team?.variables?.badge || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "101";
      }).value = options.player?.shirt_number || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "102";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "104";
      }).value =
        "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
        (options.player?.nationality?.code?.toLowerCase() ||
          options.player?.nationality?.opta_code?.toLowerCase() ||
          "");

      let stats = options.stats || {};
      let scorers = [
        ...(options?.game?.home_scorers?.map((s) => {
          return { ...s, team: "home" };
        }) || []),
        ...(options?.game?.away_scorers?.map((s) => {
          return { ...s, team: "away" };
        }) || []),
      ];

      let tries = 0;

      scorers
        .filter(
          (s) => s.type === "try" && s?.player === options?.player?.squad?._id
        )
        .forEach((player) => {
          tries += 1;
        });
      tries = parseInt(options?.player?.squad?.stats?.tries || 0) + tries;

      let goals = 0;
      scorers
        .filter(
          (s) =>
            s.type === "conversion" && s?.player === options?.player?.squad?._id
        )
        .forEach((player) => {
          goals += 1;
        });
      goals = parseInt(options?.player?.squad?.stats?.goals || 0) + goals;
      debugger;
      if (
        options.type === "conversion" ||
        options.type === "missed_conversion"
      ) {
        xml.payload.field.find((item) => {
          return item.attr.name === "200";
        }).value =
          "conversion " +
          options.made +
          "/" +
          ((options.made || 0) + (options.missed || 0));
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "200";
        }).value =
          (options?.game?.competition?.variables?.try_scorer_text || "") +
          " " +
          tries;
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
