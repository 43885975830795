import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Stat from "./Stat";
import ActionAreas from "./ActionAreas";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
import { ClockContext } from "../../contexts/ClockContext";
import { ScrollBar } from "../../Theme/Hyper";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Scroll = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${(props) => (props.short ? "400px" : "600px")};
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: ${(props) =>
    props.selected ? "#db0a41" : props.green ? "#0adb93" : "#202731"};
  color: ${(props) => (props.green ? "#000" : "#fff")};
  justify-content: center;
  align-items: center;
  height: 55%;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
    cursor: pointer;
  }
`;

const names = [
  {
    id: "attacking_kicks",
    name: "Attacking kicks",
  },
  {
    id: "av_gain",
    name: "Average gain",
  },
  {
    id: "carries",
    name: "Carries",
  },
  {
    id: "clean_break",
    name: "Linebreaks",
  },
  {
    id: "drop_goals",
    name: "",
  },
  {
    id: "errors",
    name: "Errors",
  },
  {
    id: "forty_twenty",
    name: "40/20's",
  },
  {
    id: "game_id",
    name: "",
  },
  {
    id: "goals",
    name: "",
  },
  {
    id: "kicks_in_gen_play",
    name: "Kicks in general play",
  },
  {
    id: "marker_tackles",
    name: "Marker tackles",
  },
  {
    id: "metres",
    name: "Metres",
  },
  {
    id: "missed_goals",
    name: "",
  },
  {
    id: "missed_tackles",
    name: "Missed tackles",
  },
  {
    id: "offload",
    name: "Offloads",
  },
  {
    id: "penalties",
    name: "Penalties conceded",
  },
  {
    id: "player_id",
    name: "",
  },
  {
    id: "red_card",
    name: "",
  },
  {
    id: "run_from_dummy_half",
    name: "Runs from dummy half",
  },
  {
    id: "tackle_busts",
    name: "Tackle busts",
  },
  {
    id: "tackles",
    name: "Tackles",
  },
  {
    id: "team_id",
    name: "",
  },
  {
    id: "tries",
    name: "Tries",
  },
  {
    id: "try_assists",
    name: "Try assists",
  },
  {
    id: "yellow_card",
    name: "Yellow cards",
  },
  {
    id: "break_assist",
    name: "Linebreak assists",
  },
  {
    id: "conversions",
    name: "",
  },
  {
    id: "dummy_half_metres",
    name: "Dummy half metres",
  },
  {
    id: "hit_up_metres",
    name: "Hit up metres",
  },
  {
    id: "hit_ups",
    name: "Hit ups",
  },
  {
    id: "kick_from_hand_metres",
    name: "Kick from hand metres",
  },
  {
    id: "kick_return_metres",
    name: "Kick return metres",
  },
  {
    id: "kick_returns",
    name: "Kick returns",
  },
  {
    id: "minutes_player_total",
    name: "",
  },
  {
    id: "missed_conversions",
    name: "",
  },
  {
    id: "missed_penalty_goals",
    name: "",
  },
  {
    id: "passes",
    name: "Passes",
  },
  {
    id: "penalty_goals",
    name: "",
  },
  {
    id: "twenty_forty",
    name: "20/40's",
  },
  {
    id: "ruck_infringements",
    name: "Ruck infringements",
  },
  {
    id: "set_restart_conceded_10m_offside",
    name: "Six agains conceded (10m offside)",
    line_2: "(10m offside)",
  },
  {
    id: "set_restart_won",
    name: "Six agains won",
  },
  {
    id: "kick_defused",
    name: "Kicks defused",
  },
  {
    id: "kick_not_defused",
    name: "Kicks not defused",
  },
  {
    id: "ball_run_metres",
    name: "Carry metres",
  },
  {
    id: "post_contact_metres",
    name: "Post contact metres",
  },
  {
    id: "one_on_one_steal",
    name: "One-on-one steal",
  },
  {
    id: "one_point_field_goal",
    name: "",
  },
  {
    id: "two_point_field_goal",
    name: "",
  },
  {
    id: "tackle_efficiency",
    name: "Tackle success",
    percent: true,
  },
  {
    id: "goal_line_dropout",
    name: "Goal line dropouts",
  },
  {
    id: "possession",
    name: "Possession",
  },
  {
    id: "missed_field_goals",
    name: "",
  },
  {
    id: "ptb_opp_20m",
    name: "PTBs in opp. 20",
  },
  {
    id: "set_handover",
    name: "Set handovers",
  },
  {
    id: "kick_receipt",
    name: "Kick receipts",
  },
  {
    id: "scrum_won",
    name: "Scrums won",
  },
  {
    id: "scrum_lost",
    name: "Scrums lost",
  },
  {
    id: "on_report",
    name: "",
  },
  {
    id: "complete_sets",
    name: "Sets completed",
  },
  {
    id: "completionPercentage",
    name: "Completion rate",
    percent: true,
  },
  {
    id: "incomplete_sets",
    name: "Incomplete sets",
  },
  {
    id: "play_the_ball",
    name: "PTBs",
  },
];
export default function OPTATeamStats({ game }) {
  const { previewGraphic, animateOffGraphic, graphicInPreview } =
    useContext(GraphicsContext);
  const [selectedtStat, setSelectedStat] = useState();

  return (
    <Main>
      OPTA
      <Scroll
        short={
          window.ENV?.REACT_APP_CATALANS === "true" ||
          process.env.REACT_APP_CATALANS === "true"
        }
      >
        <Row>
          <Stat
            game={game}
            category={"Pre-match win predictor"}
            title={""}
            matchStats={[]}
            previewGraphic={previewGraphic}
            animateOffGraphic={animateOffGraphic}
            graphicInPreview={graphicInPreview}
            stat={{
              home:
                (Math.floor(
                  parseFloat(
                    game?.win_predictor?.prematchProbabilities?.home_win
                  ) * 100
                ) || 0) + "%",
              away:
                (Math.floor(
                  parseFloat(
                    game?.win_predictor?.prematchProbabilities?.away_win
                  ) * 100
                ) || 0) + "%",
            }}
            selectedtStat={selectedtStat}
            setSelectedStat={setSelectedStat}
            line_2={""}
          />
        </Row>
        <Row>
          <Stat
            game={game}
            category={"Live win predictor"}
            title={""}
            matchStats={[]}
            previewGraphic={previewGraphic}
            animateOffGraphic={animateOffGraphic}
            graphicInPreview={graphicInPreview}
            stat={{
              home:
                (Math.floor(
                  parseFloat(
                    game?.win_predictor?.currentProbabilities?.home_win
                  ) * 100
                ) || 0) + "%",
              away:
                (Math.floor(
                  parseFloat(
                    game?.win_predictor?.currentProbabilities?.away_win
                  ) * 100
                ) || 0) + "%",
            }}
            selectedtStat={selectedtStat}
            setSelectedStat={setSelectedStat}
            line_2={""}
          />
        </Row>
        {Object.keys({
          ...(game.opta_home_team_stats || {}),
          ...(game.opta_away_team_stats || {}),
        })
          ?.filter(
            (key) =>
              names?.find((s) => s.id === key) &&
              names?.find((s) => s.id === key)?.name !== ""
          )
          ?.sort((a, b) => {
            let a_name = names?.find((s) => s.id === a)?.name;
            let b_name = names?.find((s) => s.id === b)?.name;
            if (a_name === "Sets completed") {
              a_name = "Completion Rate";
            }
            if (b_name === "Sets completed") {
              b_name = "Completion Rate";
            }

            if (a_name < b_name) {
              return -1;
            }
            if (a_name > b_name) {
              return 1;
            }
            return 0;
          })
          ?.map((key) => {
            let home_stat = game?.opta_home_team_stats?.[key] || 0;
            let away_stat = game?.opta_away_team_stats?.[key] || 0;
            if (key === "completionPercentage") {
              home_stat = Math.round(home_stat);
              away_stat = Math.round(away_stat);
            }
            if (key === "tackle_efficiency") {
              home_stat = Math.round(home_stat);
              away_stat = Math.round(away_stat);
            }
            if (key === "complete_sets") {
              home_stat =
                home_stat +
                "/" +
                (parseInt(home_stat) +
                  parseInt(
                    game?.opta_home_team_stats?.["incomplete_sets"] || 0
                  ));
              away_stat =
                away_stat +
                "/" +
                (parseInt(away_stat) +
                  parseInt(
                    game?.opta_away_team_stats?.["incomplete_sets"] || 0
                  ));
            }
            return (
              <Row>
                <Stat
                  game={game}
                  category={names?.find((s) => s.id === key)?.name || key}
                  matchStats={[]}
                  previewGraphic={previewGraphic}
                  animateOffGraphic={animateOffGraphic}
                  graphicInPreview={graphicInPreview}
                  stat={{
                    home:
                      home_stat +
                      (names?.find((s) => s.id === key)?.percent ? "%" : ""),
                    away:
                      away_stat +
                      (names?.find((s) => s.id === key)?.percent ? "%" : ""),
                  }}
                  selectedtStat={selectedtStat}
                  setSelectedStat={setSelectedStat}
                  line_2={names?.find((s) => s.id === key)?.line_2 || ""}
                />
              </Row>
            );
          })}
      </Scroll>
    </Main>
  );
}
