import moment from "moment";

export class OtherTag {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    createPage,
    setPageSummary,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.createPage = createPage;
    this.setPageSummary = setPageSummary;
    this.name = "OtherTag";
    this.scene = "OtherTag";
    this.data = [];
    this.downloadPage = 7007;
    this.page = 1270;
    this.buildPage = 1270;
  }

  async preview({ options }) {
    this.onAir = false;

    if (!options.build) {
      this.page = this.downloadPage;
      await this.buildSingle(options);
      await this.sendPreview("page:read " + this.page);
    } else {
      this.page = this.buildPage;
      await this.buildAll(options);
      this.page = this.buildPage;
      await this.sendPreview("page:read " + this.page);
    }
  }

  async buildSingle(options) {
    let xml = await this.getVizPage({ page: this.downloadPage });

    let goalTime =
      options?.goal?.period === 1
        ? Math.min(40, options?.goal?.game_time_minutes + 1)
        : options?.goal?.period === 2
        ? Math.min(80, options?.goal?.game_time_minutes + 1)
        : options?.goal?.game_time_minutes + 1;

    if (options?.goal?.game_time_minutes === 0) {
      goalTime = options?.goal?.game_time_seconds + " secs";
    } else {
      goalTime += goalTime > 1 ? " mins" : " min";
    }
    let text =
      options.player?.squad?.player?.last_name +
      (options.total > 1 ? " " + this.ordinal_suffix_of(options.count) : "") +
      (options.type === "drop_goal"
        ? " drop goal"
        : options.type === "sin_bin"
        ? " sin bin"
        : options.type === "red_card"
        ? " red card"
        : "");
    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // home team
    }).value = text; // needs to be {goalScorer},'goal',{goalTime},'min/mins')
    xml.payload.field.find((item) => {
      return item.attr.name === "122"; // home team
    }).value = goalTime + (options?.goal?.period >= 3 ? " (GP)" : "");

    if (options.build) {
      await this.updateVizPage({ page: this.page, data: xml }).then(
        async (data) => {
          if (data === "") {
            await this.createPage({
              page: this.page,
              data: xml,
            });
          }
          this.setPageSummary({
            page: this.page,
            summary:
              text +
              " - " +
              (options?.goal?.period < 3
                ? this.ordinal_suffix_of(options?.goal?.period) + " Period "
                : options?.goal?.period === 3
                ? "GP1 "
                : options?.goal?.period === 4
                ? "GP2 "
                : "") +
              options?.goal?.game_time_minutes?.toString()?.padStart(2, "0") +
              ":" +
              options?.goal?.game_time_seconds?.toString()?.padStart(2, "0"),
          });
        }
      );
    } else {
      await this.updateVizPage({ page: this.page, data: xml });
    }
  }

  async buildAll(options) {
    let page_count = 0;
    let scorers = [
      ...options.game.home_scorers
        ?.filter((t) => t.type === "drop_goal")
        ?.map((s) => {
          return {
            ...s,
            time: moment.duration({
              minutes: s.game_time_minutes,
              seconds: s.game_time_seconds,
            }),
          };
        }),
      ...options.game.away_scorers
        ?.filter((t) => t.type === "drop_goal")
        ?.map((s) => {
          return {
            ...s,
            time: moment.duration({
              minutes: s.game_time_minutes,
              seconds: s.game_time_seconds,
            }),
          };
        }),
      ...options.game.lineup?.home
        ?.filter((t) => t.red_card)
        ?.map((s) => {
          return {
            ...s,
            game_time_minutes: s?.red_card_time,
            game_time_seconds: 0,
            player: s?.squad?._id,
            time: moment.duration({
              minutes: s.red_card_time,
              seconds: 0,
            }),
            type: "red_card",
          };
        }),
      ...options.game.lineup?.away
        ?.filter((t) => t.red_card)
        ?.map((s) => {
          return {
            ...s,
            game_time_minutes: s?.red_card_time,
            game_time_seconds: 0,
            player: s?.squad?._id,
            time: moment.duration({
              minutes: s.red_card_time,
              seconds: 0,
            }),
            type: "red_card",
          };
        }),
    ];

    let sinbins = [
      ...options.game.lineup?.home?.filter((t) => t.sinbins?.length > 0),
      ...options.game.lineup?.away?.filter((t) => t.sinbins?.length > 0),
    ];

    for (let x = 0; x < sinbins?.length; x++) {
      for (let y = 0; y < sinbins?.[x]?.sinbins?.length; y++) {
        scorers.push({
          ...sinbins?.[x],
          ...sinbins?.[x]?.sinbins?.[y],
          player: sinbins?.[x]?.squad?._id,
          time: moment.duration({
            minutes: sinbins?.[x]?.sinbins?.[y]?.game_time_minutes,
            seconds: sinbins?.[x]?.sinbins?.[y]?.game_time_seconds,
          }),
          type: "sin_bin",
        });
      }
    }

    scorers = scorers.sort((a, b) => {
      if (a.period < b.period) {
        return -1;
      } else if (a.period > b.period) {
        return 1;
      }
      if (a.game_time_minutes < b.game_time_minutes) {
        return -1;
      } else if (a.game_time_minutes > b.game_time_minutes) {
        return 1;
      }
      if (a.game_time_seconds < b.game_time_seconds) {
        return -1;
      } else if (a.game_time_seconds > b.game_time_seconds) {
        return 1;
      }
      return 1;
    });

    for (let i = 0; i < scorers.length; i++) {
      let goal = scorers[i];
      this.page = this.buildPage + page_count;
      page_count++;

      let player = options.homeLineup.find((p) => p.squad?._id === goal.player);
      if (!player) {
        player = options.awayLineup.find((p) => p.squad?._id === goal.player);
      }
      let amount = scorers.filter((s) => {
        return (
          s?.type === goal?.type &&
          s.player === goal.player &&
          s.period <= goal.period &&
          (s.period < goal.period ||
            s.time?.asMilliseconds() <= goal.time?.asMilliseconds())
        );
      });
      let count = amount?.length || 0;
      await this.buildSingle({
        game: options.game,
        goal: goal,
        player: player,
        build: true,
        count: count,
        total: scorers.filter(
          (s) => s.player === goal.player && s?.type === goal?.type
        ).length,
        type: goal?.type,
      });
    }
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.viz.takeOut(this.page);
  }
}
