import moment from "moment";

export class Score {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Score";
    this.scene = "Score Update";
    this.data = [];
  }

  preview({ options }) {
    let score = this.getScore(options.game);
    this.onAir = false;
    this.options = options;
    this.data = [];
    switch (options.type) {
      case "HT":
        this.scene = "HT Score";

        this.data.push({
          name: "Half Text",
          value: { text: "Half Time" },
        });
        break;
      case "FT":
        this.data.push({
          name: "Half Text",
          value: { text: "Full Time" },
        });
        this.scene = "FT Score";
        break;
      default:
        this.scene = "Score Update";
    }

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit BG Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Score",
      value: {
        text: score.home_score || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: score.away_score || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Secondary Badge",
      value: {
        image: "${user}" + options.game?.competition?.secondary_badge || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.name || "" },
    });

    let scorer;
    let scorers_sorted = [
      ...(options.game?.home_scorers?.map((s) => {
        return { ...s, team: "home" };
      }) || []),
      ...(options.game?.away_scorers?.map((s) => {
        return { ...s, team: "away" };
      }) || []),
    ];

    scorers_sorted = scorers_sorted.sort((a, b) => b.time - a.time);
    if (scorers_sorted.length > 0) {
      scorer = scorers_sorted[0];
    }

    let scorer_player = options.game.lineup?.home?.find(
      (l) => l.squad?._id === scorer?.player
    );
    if (!scorer_player) {
      scorer_player = options.game.lineup?.away?.find(
        (l) => l.squad?._id === scorer?.player
      );
    }
    if (options.no_scorers) {
      scorer_player = null;
      scorer = null;
    }
    this.data.push({
      name: "Last Goal Player First Name Uppercase",
      value: { text: scorer_player?.squad?.player?.first_name_upper || "" },
    });

    this.data.push({
      name: "Last Goal Player Last Name Uppercase",
      value: { text: scorer_player?.squad?.player?.last_name_upper || "" },
    });

    this.data.push({
      name: "Last Goal Team",
      value: { text: scorer?.team || "" },
    });

    this.data.push({
      name: "Last Goal Type",
      value: { text: scorer?.type || "" },
    });
    let time = moment.duration(scorer?.time);
    let minutes = time?.minutes() + time?.hours() * 60;
    let goalTime = "";
    // minutes += 1;
    if (scorer?.period === 2 && minutes > 80) {
      goalTime = "80'";
    } else if (scorer?.period === 1 && minutes > 40) {
      goalTime = "40'";
    } else {
      goalTime = minutes + "'";
    }
    if (options.no_scorers) {
      goalTime = "";
    }
    this.data.push({
      name: "Last Goal Time",
      value: { text: goalTime || "" },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }
}
