export class Clock {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    vizCommand,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.vizCommand = vizCommand;
    this.name = "CLOCK";
    this.scene = "Clock";
    this.data = [];
    this.page = 10000;
    this.BS_SCENE = "BS-Clock";
  }

  async preview({ options }) {
    this.onAir = false;
  }
  animate({ options }) {
    let score = this.getScore(options.game);

    this.data = [];
    this.data.push({
      name: "Period",
      value: { text: options.period.number },
    });
    this.data.push({
      name: "HomeScore",
      value: { text: score?.home_score },
    });
    this.data.push({
      name: "AwayScore",
      value: { text: score?.away_score },
    });
    this.period = options.period.number;
    this.sendUpdate(this.BS_SCENE, this.data);

    let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
    }, 0);

    let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
    }, 0);
    let home_yellows = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev += curr.yellow_card ? 1 : 0);
    }, 0);

    let away_yellows = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev += curr.yellow_card ? 1 : 0);
    }, 0);

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HSentOff|" +
        (this.home_yellows_total > 0
          ? new Array(this.home_yellows_total)
              ?.fill(1)
              ?.map((y) => "y")
              ?.join("")
          : "") +
        (this.home_reds_total > 0
          ? new Array(this.home_reds_total)
              ?.fill(1)
              ?.map((y) => "r")
              ?.join("")
          : ""),
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_ASentOff|" +
        (this.away_yellows_total > 0
          ? new Array(this.away_yellows_total)
              ?.fill(1)
              ?.map((y) => "y")
              ?.join("")
          : "") +
        (this.away_reds_total > 0
          ? new Array(this.away_reds_total)
              ?.fill(1)
              ?.map((y) => "r")
              ?.join("")
          : ""),
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HomeBaseColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.home_team?.variables?.[
          options.game?.home_team_kit + "_kit_bg_viz"
        ] || ""),
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_AwayBaseColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.away_team?.variables?.[
          options.game?.away_team_kit + "_kit_bg_viz"
        ] || ""),
    });

    this.vizCommand({
      viz_command:
        "10 RENDERER*FRONT_LAYER SET_OBJECT SCENE*__SKY_SPORTS/SCENES_LIMA_3/RUGBY_LEAGUE/CLOCK/CLOCK_RGL_V107",
    });
    this.vizSharedMemory({
      shared_memory: `SMMSystem_SetValue|SHMSend_HomeTeamName|${
        options?.game?.home_team?.code || ""
      }`,
    });
    this.vizSharedMemory({
      shared_memory: `SMMSystem_SetValue|SHMSend_AwayTeamName|${
        options?.game?.away_team?.code || ""
      }`,
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HomeTextColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.home_team?.variables?.[
          options.game?.home_team_kit + "_kit_text_viz"
        ] || ""),
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_AwayTextColour|IMAGE*__SKY_SPORTS/TEAM_ELEMENTS/RUGBY_LEAGUE/CLOCK_COLOURS/" +
        (options.game.away_team?.variables?.[
          options.game?.away_team_kit + "_kit_text_viz"
        ] || ""),
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_HomeScore|" + score?.home_score,
    });
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_AwayScore|" + score?.away_score,
    });
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_TackleDistanceActive|0",
    });
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_PTSValue|GP",
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_PTSState|" + (this.period > 2 ? 1 : 0),
    });

    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_StatComponents|REG_Score,#,#",
    });
    if (this.onAir === false) {
      this.update({ options: { action: "ON" } });
    }
    this.onAir = true;
  }

  animateOff({ options }) {
    this.onAir = false;
    this.update({ options: { action: "OFF" } });
  }

  update({ options }) {
    let score = this.getScore(options.game);

    switch (options?.action || options?.type) {
      case "ON":
        this.vizCommand({
          viz_command: "10 FRONT_SCENE*STAGE*DIRECTOR*Default START",
        });
        this.onAir = true;
        break;
      case "OFF":
        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_StaticInsertComponents|REG_SIOut,#,#,#",
        });
        this.vizCommand({
          viz_command: "10 FRONT_SCENE*STAGE*DIRECTOR*Default GOTO $IN $OUT",
        });
        this.onAir = false;
        break;
      case "PHASE":
        this.vizSharedMemory({
          shared_memory: "SMMSystem_SetValue|SHMSend_TCKn| " + options.phase,
        });

        break;
      case "METRES":
        debugger;
        this.vizSharedMemory({
          shared_memory: "SMMSystem_SetValue|SHMSend_TackleDistanceActive|1",
        });

        this.vizSharedMemory({
          shared_memory: "SMMSystem_SetValue|SHMSend_TCKDist|" + options.metres,
        });

        break;
      case "METRES_OFF":
        debugger;
        this.vizSharedMemory({
          shared_memory: "SMMSystem_SetValue|SHMSend_TackleDistanceActive|0",
        });

        break;
      case "STAT":
        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_StatComponents|Number of sets,1,0",
        });

        break;
      case "PENALTY_TEXT":
        if (options.caption === "") {
          this.vizSharedMemory({
            shared_memory:
              "SMMSystem_SetValue|SHMSend_StaticInsertComponents|REG_SIOut,#,#,#",
          });
        } else {
          this.vizSharedMemory({
            shared_memory:
              "SMMSystem_SetValue|SHMSend_StaticInsertComponents|" +
              (options.caption || "") +
              "," +
              (options?.team || "0") +
              ",0,1",
          });
        }

        this.vizSharedMemory({
          shared_memory:
            "SMMSystem_SetValue|SHMSend_TCKDist|" + (options.metres ?? ""),
        });

        break;
      case "PERIOD":
        this.period = options.period.number;
        this.data = [];
        this.data.push({
          name: "Period",
          value: { text: options.period.number },
        });
        this.sendUpdate(this.BS_SCENE, this.data);
        break;
      default:
        let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
          return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
        }, 0);

        let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
          return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
        }, 0);
        let home_yellows = options.game?.lineup?.home?.reduce((prev, curr) => {
          return (prev += curr.yellow_card ? 1 : 0);
        }, 0);

        let away_yellows = options.game?.lineup?.away?.reduce((prev, curr) => {
          return (prev += curr.yellow_card ? 1 : 0);
        }, 0);

        if (options.clockType === "manual") {
          let minutes = document.getElementById("manual_minutes")?.innerText;

          let seconds = document.getElementById("manual_seconds")?.innerText;

          if (this.minutes !== minutes || this.seconds !== seconds) {
            this.minutes = minutes;
            this.seconds = seconds;
            if (seconds !== undefined && minutes !== undefined) {
              this.vizSharedMemory({
                shared_memory:
                  "SMMSystem_SetValue|MASTER_CLOCK_UP_SHM|" +
                  this.minutes +
                  ":" +
                  this.seconds,
              });
            }
          }
        }
        if (this.period !== options.period?.number) {
          this.period = options.period.number;
          this.data = [];
          this.data.push({
            name: "Period",
            value: { text: options.period.number },
          });

          this.sendUpdate(this.BS_SCENE, this.data);
        }
        if (
          this.home_score !== (score.home_score || 0) ||
          this.away_score !== (score.away_score || 0) ||
          this.home_reds_total !== home_reds ||
          this.away_reds_total !== away_reds ||
          this.home_yellows_total !== home_yellows ||
          this.away_yellows_total !== away_yellows
        ) {
          this.home_score = score?.home_score;
          this.away_score = score?.away_score;
          this.home_reds_total = home_reds;
          this.away_reds_total = away_reds;
          this.home_yellows_total = home_yellows;
          this.away_yellows_total = away_yellows;
          this.vizSharedMemory({
            shared_memory:
              "SMMSystem_SetValue|SHMSend_HomeScore|" + score?.home_score,
          });
          this.vizSharedMemory({
            shared_memory:
              "SMMSystem_SetValue|SHMSend_AwayScore|" + score?.away_score,
          });
          this.vizSharedMemory({
            shared_memory:
              "SMMSystem_SetValue|SHMSend_HSentOff|" +
              (this.home_yellows_total > 0
                ? new Array(this.home_yellows_total)
                    ?.fill(1)
                    ?.map((y) => "y")
                    ?.join("")
                : "") +
              (this.home_reds_total > 0
                ? new Array(this.home_reds_total)
                    ?.fill(1)
                    ?.map((y) => "r")
                    ?.join("")
                : ""),
          });

          this.vizSharedMemory({
            shared_memory:
              "SMMSystem_SetValue|SHMSend_ASentOff|" +
              (this.away_yellows_total > 0
                ? new Array(this.away_yellows_total)
                    ?.fill(1)
                    ?.map((y) => "y")
                    ?.join("")
                : "") +
              (this.away_reds_total > 0
                ? new Array(this.away_reds_total)
                    ?.fill(1)
                    ?.map((y) => "r")
                    ?.join("")
                : ""),
          });

          this.data = [];
          this.data.push({
            name: "Period",
            value: { text: options.period.number },
          });
          this.data.push({
            name: "HomeScore",
            value: { text: score?.home_score },
          });
          this.data.push({
            name: "AwayScore",
            value: { text: score?.away_score },
          });
          this.period = options.period.number;
          this.sendUpdate(this.BS_SCENE, this.data);
        }
    }
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let home_tries =
      game?.home_scorers?.filter((g) => g.type === "try").length || 0;
    let home_conv =
      game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
    let home_pen_kick =
      game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let home_pen_try =
      game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let home_drop_goal =
      game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_tries =
      game?.away_scorers?.filter((g) => g.type === "try").length || 0;
    let away_conv =
      game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
    let away_pen_kick =
      game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let away_pen_try =
      game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let away_drop_goal =
      game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    return {
      home_score,
      away_score,
      home_tries,
      home_conv,
      home_pen_kick,
      home_pen_try,
      home_drop_goal,
      away_tries,
      away_conv,
      away_pen_kick,
      away_pen_try,
      away_drop_goal,
    };
  }
}
