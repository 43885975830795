import moment from "moment";

export class FFScore {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFScore";
    this.scene = "FF Score";
    this.data = [];
  }

  preview({ options }) {
    let score = this.getScore(options.game);
    this.onAir = false;
    this.options = options;
    this.data = [];

    this.scene = "FF Score";

    this.data.push({
      name: "Half Text",
      value: { text: "Half Time" },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.home_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.third_badge || "",
      },
    });
    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Score",
      value: {
        text: score.home_score || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: score.away_score || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Secondary Badge",
      value: {
        image: "${user}" + options.game?.competition?.secondary_badge || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.name || "" },
    });

    let home_scorers =
      options.game?.home_scorers?.map((s) => {
        return { ...s, team: "home" };
      }) || [];

    let away_scorers =
      options.game?.away_scorers?.map((s) => {
        return { ...s, team: "away" };
      }) || [];

    let home_totals = [];
    home_scorers
      .filter((s) => s.type === "try")
      .sort((a, b) => a.player - b.player)
      .forEach((player) => {
        let index = home_totals.findIndex((p) => p.player === player.player);
        if (index > -1) {
          home_totals[index].count += 1;
        } else {
          home_totals.push({ player: player.player, count: 1 });
        }
      });
    this.data.push({
      name: "Home Try Scorers",
      value: {
        text: home_totals
          .map((scorer) => {
            let scorer_player = options.game.lineup?.home?.find(
              (l) => l.squad?._id === scorer?.player
            );

            let output =
              scorer_player?.squad?.player?.short_name_upper ||
              scorer_player?.squad?.player?.last_name_upper ||
              "";
            if (output !== "") {
              output += scorer.count > 1 ? " (" + scorer.count + ")" : "";
            }
            return output;
          })
          .join(", "),
      },
    });

    home_totals = [];
    home_scorers
      .filter((s) => s.type === "conversion" || s.type === "drop_goal")
      .sort((a, b) => a.player - b.player)
      .forEach((player) => {
        let index = home_totals.findIndex((p) => p.player === player.player);
        if (player.type === "conversion") {
          if (index > -1) {
            home_totals[index].count += 1;
          } else {
            home_totals.push({ player: player.player, count: 1, dg_count: 0 });
          }
        } else if (player.type === "drop_goal") {
          if (index > -1) {
            home_totals[index].dg_count += 1;
          } else {
            home_totals.push({ player: player.player, dg_count: 1, count: 0 });
          }
        }
      });
    this.data.push({
      name: "Home Conversion Scorers",
      value: {
        text: home_totals
          .map((scorer) => {
            let scorer_player = options.game.lineup?.home?.find(
              (l) => l.squad?._id === scorer?.player
            );

            let output =
              scorer_player?.squad?.player?.short_name_upper ||
              scorer_player?.squad?.player?.last_name_upper ||
              "";
            if (output !== "") {
              if (scorer.dg_count >= 1 && scorer.count > 0) {
                output += " (" + scorer.count + ")";
              } else {
                output += scorer.count > 1 ? " (" + scorer.count + ")" : "";
              }
            }
            if (output !== "") {
              output +=
                scorer.dg_count > 1
                  ? " (DG " + scorer.dg_count + ")"
                  : scorer.dg_count === 1 && scorer.count >= 1
                  ? " (DG)"
                  : scorer.dg_count === 1 && scorer.count === 0
                  ? " (DG)"
                  : "";
            }
            return output;
          })
          .join(", "),
      },
    });

    home_totals = [];
    home_scorers
      .filter((s) => s.type === "penalty_try" || s.type === "penalty_kick")
      .sort((a, b) => a.player - b.player)
      .forEach((player) => {
        let index = home_totals.findIndex((p) => p.player === player.player);
        if (index > -1) {
          home_totals[index].count += 1;
        } else {
          home_totals.push({ player: player.player, count: 1 });
        }
      });
    this.data.push({
      name: "Home Penalty Scorers",
      value: {
        text: home_totals
          .map((scorer) => {
            let scorer_player = options.game.lineup?.home?.find(
              (l) => l.squad?._id === scorer?.player
            );

            let output =
              scorer_player?.squad?.player?.short_name_upper ||
              scorer_player?.squad?.player?.last_name_upper ||
              "";
            if (output !== "") {
              output += scorer.count > 1 ? " (" + scorer.count + ")" : "";
            }
            return output;
          })
          .join(", "),
      },
    });

    let away_totals = [];
    away_scorers
      .filter((s) => s.type === "try")
      .sort((a, b) => a.player - b.player)
      .forEach((player) => {
        let index = away_totals.findIndex((p) => p.player === player.player);
        if (index > -1) {
          away_totals[index].count += 1;
        } else {
          away_totals.push({ player: player.player, count: 1 });
        }
      });
    this.data.push({
      name: "Away Try Scorers",
      value: {
        text: away_totals
          .map((scorer) => {
            let scorer_player = options.game.lineup?.away?.find(
              (l) => l.squad?._id === scorer?.player
            );

            let output =
              scorer_player?.squad?.player?.short_name_upper ||
              scorer_player?.squad?.player?.last_name_upper ||
              "";
            if (output !== "") {
              output += scorer.count > 1 ? " (" + scorer.count + ")" : "";
            }
            return output;
          })
          .join(", "),
      },
    });

    away_totals = [];
    away_scorers
      .filter((s) => s.type === "conversion" || s.type === "drop_goal")
      .sort((a, b) => a.player - b.player)
      .forEach((player) => {
        let index = away_totals.findIndex((p) => p.player === player.player);
        if (player.type === "conversion") {
          if (index > -1) {
            away_totals[index].count += 1;
          } else {
            away_totals.push({ player: player.player, count: 1, dg_count: 0 });
          }
        } else if (player.type === "drop_goal") {
          if (index > -1) {
            away_totals[index].dg_count += 1;
          } else {
            away_totals.push({ player: player.player, dg_count: 1, count: 0 });
          }
        }
      });
    this.data.push({
      name: "Away Conversion Scorers",
      value: {
        text: away_totals
          .map((scorer) => {
            let scorer_player = options.game.lineup?.away?.find(
              (l) => l.squad?._id === scorer?.player
            );

            let output =
              scorer_player?.squad?.player?.short_name_upper ||
              scorer_player?.squad?.player?.last_name_upper ||
              "";
            if (output !== "") {
              if (scorer.dg_count >= 1 && scorer.count > 0) {
                output += " (" + scorer.count + ")";
              } else {
                output += scorer.count > 1 ? " (" + scorer.count + ")" : "";
              }
            }
            if (output !== "") {
              output +=
                scorer.dg_count > 1
                  ? " (DG " + scorer.dg_count + ")"
                  : scorer.dg_count === 1 && scorer.count >= 1
                  ? " (DG)"
                  : scorer.dg_count === 1 && scorer.count === 0
                  ? " (DG)"
                  : "";
            }
            return output;
          })
          .join(", "),
      },
    });

    away_totals = [];
    away_scorers
      .filter((s) => s.type === "penalty_try" || s.type === "penalty_kick")
      .sort((a, b) => a.player - b.player)
      .forEach((player) => {
        let index = away_totals.findIndex((p) => p.player === player.player);
        if (index > -1) {
          away_totals[index].count += 1;
        } else {
          away_totals.push({ player: player.player, count: 1 });
        }
      });
    this.data.push({
      name: "Away Penalty Scorers",
      value: {
        text: away_totals
          .map((scorer) => {
            let scorer_player = options.game.lineup?.away?.find(
              (l) => l.squad?._id === scorer?.player
            );

            let output =
              scorer_player?.squad?.player?.short_name_upper ||
              scorer_player?.squad?.player?.last_name_upper ||
              "";
            if (output !== "") {
              output += scorer.count > 1 ? " (" + scorer.count + ")" : "";
            }
            return output;
          })
          .join(", "),
      },
    });

    let scorer;
    let scorers_sorted = [...home_scorers, ...away_scorers];

    scorers_sorted = scorers_sorted.sort((a, b) => b.time - a.time);
    if (scorers_sorted.length > 0) {
      scorer = scorers_sorted[0];
    }

    let scorer_player = options.game.lineup?.home?.find(
      (l) => l.squad?._id === scorer?.player
    );
    if (!scorer_player) {
      scorer_player = options.game.lineup?.away?.find(
        (l) => l.squad?._id === scorer?.player
      );
    }
    if (options.no_scorers) {
      scorer_player = null;
      scorer = null;
    }
    this.data.push({
      name: "Last Goal Player First Name Uppercase",
      value: { text: scorer_player?.squad?.player?.first_name_upper || "" },
    });

    this.data.push({
      name: "Last Goal Player Last Name Uppercase",
      value: { text: scorer_player?.squad?.player?.last_name_upper || "" },
    });

    this.data.push({
      name: "Last Goal Team",
      value: { text: scorer?.team || "" },
    });

    this.data.push({
      name: "Last Goal Type",
      value: { text: scorer?.type || "" },
    });
    let time = moment.duration(scorer?.time);
    let minutes = time?.minutes() + time?.hours() * 60;
    let goalTime = "";
    // minutes += 1;
    if (scorer?.period === 2 && minutes > 80) {
      goalTime = "80'";
    } else if (scorer?.period === 1 && minutes > 40) {
      goalTime = "40'";
    } else {
      goalTime = minutes + "'";
    }
    if (options.no_scorers) {
      goalTime = "";
    }
    this.data.push({
      name: "Last Goal Time",
      value: { text: goalTime || "" },
    });

    let period = (options.game?.live_data?.periods || [])
      .filter((p) => p.kick_off)
      .sort((a, b) => b.number - a.number)[0];

    this.data.push({
      name: "Period",
      value: { text: period?.number || 1 },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }
}
