export class FormationSOF {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "FormationSOF";
    this.scene = "Formation";
    this.data = [];
    this.download_page = 7105;
    this.home_page = 48;
    this.away_page = 49;
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = [];
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      if (options?.game?.home_team?._id === options?.team?._id) {
        this.page = this.home_page;
      } else if (options?.game?.away_team?._id === options?.team?._id) {
        this.page = this.away_page;
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = options.team?.short_name || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "400";
      }).value =
        options.team?.variables?.alt_badge ||
        options.team?.variables?.badge ||
        "";

      new Array(13).fill(1).forEach((_, index) => {
        let row = index + 1;
        let tab_row = row?.toString().padStart("2", "0");
        let player;
        if (
          row <=
          (options?.game?.competition?.variables?.number_of_players_on_field ??
            13)
        ) {
          player = options.squads?.find((s) => s.order === row);
        }

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "1";
        }).value = player?.squad?.shirt_number || "";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "2";
        }).value =
          player?.squad?.player?.first_name_upper ||
          player?.squad?.player?.first_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "21";
        }).value =
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";
      });

      new Array(5).fill(1).forEach((_, index) => {
        let row = index + 1;
        let tab_row = "16" + row;

        let player = options.squads?.find(
          (s) =>
            s.order ===
            row +
              (options?.game?.competition?.variables
                ?.number_of_players_on_field ?? 13)
        );

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "2";
        }).value = player?.squad?.shirt_number || "";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "1";
        }).value =
          player?.squad?.player?.first_name_upper ||
          player?.squad?.player?.first_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row;
        }).value =
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";
      });
    }

    // new Array(25).fill(1).forEach((_, index) => {
    //   let row = index + 1;

    //   let player = options.squads?.find((s) => s.order === row);

    //   this.data.push({
    //     name: "Last Name Uppercase " + row,
    //     value: {
    //       text: player?.squad?.player?.last_name_upper || "",
    //     },
    //   });

    //   this.data.push({
    //     name: "Short Name Uppercase " + row,
    //     value: {
    //       text:
    //         player?.squad?.player?.short_name_upper ||
    //         player?.squad?.player?.last_name_upper ||
    //         "",
    //     },
    //   });

    //   this.data.push({
    //     name: "Captain " + row,
    //     value: {
    //       visible: player?.captain || false,
    //     },
    //   });
    //   this.data.push({
    //     name: "Position " + row,
    //     value: {
    //       text: player?.squad?.position || "",
    //     },
    //   });
    //   this.data.push({
    //     name: "Shirt Number " + row,
    //     value: {
    //       text:
    //         (rugby_type === "union"
    //           ? player?.order
    //           : player?.squad?.shirt_number) || "",
    //     },
    //   });

    // this.data.push({
    //   name: "X/Y " + row,
    //   value: {
    //     position: {
    //       x: options.formation.positions[index].x,
    //       y: options.formation.positions[index].y,
    //     },
    //   },
    // });
    // });

    // new Array(9).fill(1).forEach((_, index) => {
    //   let row = index + 12;

    //   let player = options.squads?.find((s) => s.order === row);

    //   this.data.push({
    //     name: "Last Name Uppercase " + row,
    //     value: {
    //       text: player?.squad?.player?.last_name_upper || "",
    //     },
    //   });

    //   this.data.push({
    //     name: "Captain " + row,
    //     value: {
    //       visible: player?.captain || false,
    //     },
    //   });
    //   this.data.push({
    //     name: "Position " + row,
    //     value: {
    //       text: player?.squad?.position || "",
    //     },
    //   });
    //   this.data.push({
    //     name: "Shirt Number " + row,
    //     value: {
    //       text: player?.squad?.shirt_number || "",
    //     },
    //   });

    //   // this.data.push({
    //   //   name: "X/Y " + row,
    //   //   value: {
    //   //     position: {
    //   //       x: options.formation.positions[index].x,
    //   //       y: options.formation.positions[index].y,
    //   //     },
    //   //   },
    //   // });
    // });

    await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
      console.log(err);
    });
    await this.sendPreview("page:read " + this.page);
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
