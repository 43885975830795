import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import moment from "moment";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  > tbody > tr {
    background-color: rgb(32, 39, 49);
  }

  th {
    padding: 1em;
  }
  td {
    padding: 0.2em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    width: ${(props) => props.width};
    height: 30px;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: black;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #818283;
  text-align: left;
`;
const TR = styled.tr`
  > td {
    background-color: ${(props) => (props.highlight ? "#db0a41" : "")};
    border-bottom: ${(props) =>
      props.dots ? "1px dashed white" : " 2px solid rgb(24, 29, 37)"};
  }
`;
const ActionPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0.5em;
  margin-left: 0.5em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  margin-bottom: 10px;
`;
const Row = styled.div`
  display: flex;
`;

const GraphicButton = styled(Button)`
  margin: 5px;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : ""};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
`;

const SmallButton = styled(Button)`
  color: #dfe9e8;
`;

const Arrow = styled.div`
  text-align: center;
  min-width: 20px;
  color: ${(props) => (props.red ? "red" : props.green ? "#0adb93" : "")};
`;
const ScoreRow = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  background-color: rgb(32, 39, 49);
  border-top: ${(props) =>
    props.dots ? "1px dashed white" : " 2px solid rgb(24, 29, 37)"};
`;

const ScoreRowTeam = styled.div`
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  display: flex;
  align-items: center;
`;
const ScoreRowScore = styled.div`
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  background-color: #1f222b !important;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #cacaca;
  text-align: center;
  max-width: 40px;
`;
function TableView({ game }) {
  const { previewGraphic } = useContext(GraphicsContext);
  const { getStandings, getStartOfDayStandings, getTeams, getScores } =
    useContext(APIContext);
  const [data, setData] = useState({});
  const [dataSOD, setDataSOD] = useState({});
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState();
  const [opta, setOpta] = useLocalStorage("ruygby-table-opta-view", true);
  const [selectedTeams, setSelectedTeams] = useLocalStorage(
    "selected-teams",
    []
  );
  const [customTeams, setCustomTeams] = useLocalStorage("custom-teams", []);
  const [latestScores, setLatestScores] = useLocalStorage(
    "rugby-latest-scores",
    []
  );

  useEffect(() => {
    getData();
  }, [latestScores]);

  useEffect(() => {
    let interval;
    interval = setInterval(async () => {
      await getData();
    }, 5000);
    getData();
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getData = useCallback(async () => {
    await getTeams({}).then((teams) => {
      setTeams(teams);
      getStandings().then((data) => {
        let array = JSON.parse(JSON.stringify(data?.standings))?.find(
          (s) =>
            s?.opta_comp_ID === game?.competition?.opta_ID &&
            s?.opta_season_ID === game?.season?.opta_ID
        )?.data;
        if (!array) {
          array = {};
        }
        getStartOfDayStandings().then((sod_data) => {
          let sod_array = sod_data?.startOfDayStandings?.filter(
            (s) =>
              s?.opta_comp_ID === game?.competition?.opta_ID &&
              s?.opta_season_ID === game?.season?.opta_ID
          );
          sod_array = sod_array?.filter((item) => {
            return (
              moment(item?.date)?.valueOf() <= moment(game?.date)?.valueOf()
            );
          });

          sod_array = sod_array?.sort((a, b) => {
            return moment(b?.date)?.valueOf() - moment(a?.date)?.valueOf();
          });

          sod_array = {
            date: sod_array?.[0]?.date,
            ...sod_array?.[0]?.data,
            _id: sod_array?.[0]?._id,
          };
          setDataSOD(sod_array);
          if (array?.table?.comp?.group?.team) {
            array.table.comp.group.team = array?.table?.comp?.group?.team?.map(
              (team) => {
                return {
                  ...team,
                  lastRank: sod_array?.table?.comp?.group?.team?.find((t) => {
                    return t?.id === team?.id;
                  })?.rank,
                };
              }
            );
          }

          setData(array);
        });
      });
    });
    await getScores({ date: game?.date?.replace("Z", "") })?.then((data) => {
      let games = data?.filter(
        (g) => g?.competition?._id === game?.competition?._id
      );

      setGames(games);
    });
  }, [game, getStandings, getTeams]);
  return (
    <Main>
      <Row>
        <ActionPanel>
          <Row>Start of day</Row>
          <TableData
            data={dataSOD}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            game={game}
            previewGraphic={previewGraphic}
            sod={true}
            getData={getData}
          />
        </ActionPanel>
        <ActionPanel>
          As it stands (OPTA updated when games have finished)
          <TableData
            data={data}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            game={game}
            previewGraphic={previewGraphic}
            type="as_it_stands"
            customTeams={customTeams}
            setCustomTeams={setCustomTeams}
          />
        </ActionPanel>
        <ActionPanel>
          Custom
          <TableData
            data={data}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            game={game}
            previewGraphic={previewGraphic}
            customTeams={customTeams}
            setCustomTeams={setCustomTeams}
            type="custom"
          />
        </ActionPanel>
      </Row>
      <Row>
        <ActionPanel>
          latest scores
          <Row>
            <GraphicButton
              green={opta === true}
              onClick={() => {
                setOpta(true);
                getData();
              }}
            >
              OPTA
            </GraphicButton>
            <GraphicButton
              green={opta === false}
              onClick={() => {
                setOpta(false);
                getData();
              }}
            >
              Manual
            </GraphicButton>
          </Row>
          {opta &&
            games?.map((opta_game) => {
              return (
                <ScoreRow>
                  <ScoreRowTeam>
                    {opta_game?.home_team?.short_name}
                  </ScoreRowTeam>
                  <ScoreRowScore>
                    {opta_game?.opta_scores?.home?.score}
                  </ScoreRowScore>
                  -
                  <ScoreRowScore>
                    {opta_game?.opta_scores?.away?.score}
                  </ScoreRowScore>
                  <ScoreRowTeam>
                    {opta_game?.away_team?.short_name}
                  </ScoreRowTeam>
                  <ScoreRowTeam>{opta_game?.opta_scores?.status}</ScoreRowTeam>
                </ScoreRow>
              );
            })}
          {!opta &&
            games?.map((game) => {
              return (
                <ScoreRow>
                  <ScoreRowTeam>{game?.home_team?.short_name}</ScoreRowTeam>
                  <ScoreRowScore>
                    <Input
                      value={
                        latestScores?.find((l) => l?._id === game?._id)
                          ?.opta_scores?.home?.score ?? 0
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        let index = latestScores?.findIndex(
                          (l) => l?._id === game?._id
                        );

                        let newLatestScores = [...latestScores];
                        if (index === -1) {
                          newLatestScores.push({
                            ...game,
                            opta_scores: {
                              home: { score: value },
                              away: { score: 0 },
                            },
                          });
                        } else {
                          newLatestScores[index].opta_scores.home.score = value;
                        }
                        setLatestScores(newLatestScores);
                      }}
                    />
                  </ScoreRowScore>
                  -
                  <ScoreRowScore>
                    <Input
                      value={
                        latestScores?.find((l) => l?._id === game?._id)
                          ?.opta_scores?.away?.score ?? 0
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        let index = latestScores?.findIndex(
                          (l) => l?._id === game?._id
                        );
                        let newLatestScores = [...latestScores];
                        if (index === -1) {
                          latestScores.push({
                            ...game,
                            opta_scores: {
                              away: { score: value },
                              home: { score: 0 },
                            },
                          });
                        } else {
                          latestScores[index].opta_scores.away.score = value;
                        }
                        setLatestScores(newLatestScores);
                      }}
                    />
                  </ScoreRowScore>
                  <ScoreRowTeam>{game?.away_team?.short_name}</ScoreRowTeam>
                  <ScoreRowTeam>
                    <Row>
                      <GraphicButton
                        green={
                          latestScores?.find((l) => l?._id === game?._id)
                            ?.opta_scores?.status === "Fixture"
                        }
                        onClick={() => {
                          let index = latestScores?.findIndex(
                            (l) => l?._id === game?._id
                          );
                          let newLatestScores = [...latestScores];
                          if (index === -1) {
                            latestScores.push({
                              ...game,
                              opta_scores: {
                                away: { score: 0 },
                                home: { score: 0 },
                                status: "Fixture",
                              },
                            });
                          } else {
                            latestScores[index].opta_scores.status = "Fixture";
                          }
                          setLatestScores(newLatestScores);
                        }}
                      >
                        Fixture
                      </GraphicButton>
                      <GraphicButton
                        green={
                          latestScores?.find((l) => l?._id === game?._id)
                            ?.opta_scores?.status === "Result"
                        }
                        onClick={() => {
                          let index = latestScores?.findIndex(
                            (l) => l?._id === game?._id
                          );
                          let newLatestScores = [...latestScores];
                          if (index === -1) {
                            latestScores.push({
                              ...game,
                              opta_scores: {
                                away: { score: 0 },
                                home: { score: 0 },
                                status: "Result",
                              },
                            });
                          } else {
                            latestScores[index].opta_scores.status = "Result";
                          }
                          setLatestScores(newLatestScores);
                        }}
                      >
                        Result
                      </GraphicButton>
                    </Row>
                  </ScoreRowTeam>
                </ScoreRow>
              );
            })}
        </ActionPanel>
        <ActionPanel>
          As it stands (start of day + latest scores)
          <ScoresTable
            data={dataSOD}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            game={game}
            previewGraphic={previewGraphic}
            customTeams={customTeams}
            setCustomTeams={setCustomTeams}
            type="as_it_stands"
            games={games?.filter((g) => g?.opta_scores?.status !== "Postponed")}
            opta={opta}
            manual_games={latestScores}
          />
        </ActionPanel>
        <ActionPanel>
          Custom
          <ScoresTable
            data={dataSOD}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            game={game}
            previewGraphic={previewGraphic}
            customTeams={customTeams}
            setCustomTeams={setCustomTeams}
            type="custom"
            games={games?.filter((g) => g?.opta_scores?.status !== "Postponed")}
            opta={opta}
            manual_games={latestScores}
          />
        </ActionPanel>
      </Row>
    </Main>
  );
}

function ScoresTable({
  data,
  teams,
  selectedTeams,
  setSelectedTeams,
  previewGraphic,
  game,
  type,
  customTeams,
  setCustomTeams,
  sod,
  games,
  opta,
  manual_games,
}) {
  const [newData, setNewData] = useState();

  useEffect(() => {
    let new_data = JSON.parse(JSON.stringify(data));
    if (new_data) {
      try {
        new_data.table.comp.group.team = data.table.comp.group.team?.map(
          (row) => {
            row = { ...row };
            let opta_game = games?.find(
              (g) =>
                (g?.home_team?.opta_ID === row?.id ||
                  g?.away_team?.opta_ID === row?.id) &&
                g?.opta_scores?.status !== "Fixture" &&
                g?.opta_scores?.status !== "Postponed"
            );
            if (!opta) {
              let latest_games = manual_games.filter(
                (mg) => games?.findIndex((g) => g?._id === mg?._id) > -1
              );
              opta_game = latest_games?.find(
                (g) =>
                  (g?.home_team?.opta_ID === row?.id ||
                    g?.away_team?.opta_ID === row?.id) &&
                  g?.opta_scores?.status !== "Fixture" &&
                  g?.opta_scores?.status !== "Postponed"
              );
            }
            if (opta_game) {
              row.played = parseInt(row.played) + 1;
              if (opta_game?.home_team?.opta_ID === row?.id) {
                if (
                  parseInt(opta_game?.opta_scores?.home?.score) >
                  parseInt(opta_game?.opta_scores?.away?.score)
                ) {
                  row.points = parseInt(row.points) + 2;
                } else if (
                  parseInt(opta_game?.opta_scores?.home?.score) ===
                  parseInt(opta_game?.opta_scores?.away?.score)
                ) {
                  row.points = parseInt(row.points) + 1;
                }
                row.for =
                  parseInt(row.for) +
                  parseInt(opta_game?.opta_scores?.home?.score);
                row.against =
                  parseInt(row.against) +
                  parseInt(opta_game?.opta_scores?.away?.score);
                let diff = row.for - row.against;
                row.pointsdiff = diff;
              } else if (opta_game?.away_team?.opta_ID === row?.id) {
                if (
                  parseInt(opta_game?.opta_scores?.home?.score) <
                  parseInt(opta_game?.opta_scores?.away?.score)
                ) {
                  row.points = parseInt(row.points) + 2;
                } else if (
                  parseInt(opta_game?.opta_scores?.home?.score) ===
                  parseInt(opta_game?.opta_scores?.away?.score)
                ) {
                  row.points = parseInt(row.points) + 1;
                }
                row.for =
                  parseInt(row.for) +
                  parseInt(opta_game?.opta_scores?.away?.score);
                row.against =
                  parseInt(row.against) +
                  parseInt(opta_game?.opta_scores?.home?.score);
                let diff = row.for - row.against;
                row.pointsdiff = diff;
              }
            }
            return { ...row };
          }
        );
        new_data.table.comp.group.team = new_data.table.comp.group.team?.sort(
          (a, b) => {
            if (parseInt(a?.points) > parseInt(b?.points)) {
              return -1;
            }
            if (parseInt(a?.points) < parseInt(b?.points)) {
              return 1;
            }

            if (parseInt(a?.pointsdiff) < parseInt(b?.pointsdiff)) {
              return 1;
            }
            if (parseInt(a?.pointsdiff) > parseInt(b?.pointsdiff)) {
              return -1;
            }
            let diffPercent_a = (parseInt(a?.for) / parseInt(a?.against)) * 100;
            let diffPercent_b = (parseInt(b?.for) / parseInt(b?.against)) * 100;
            if (diffPercent_a < diffPercent_b) {
              return 1;
            }
            if (diffPercent_a > diffPercent_b) {
              return -1;
            }
          }
        );
        new_data.table.comp.group.team = new_data.table.comp.group.team?.map(
          (team, index) => {
            return { ...team, rank: index + 1, lastRank: team?.rank };
          }
        );
      } catch (err) {
        console.log(err);
      }

      setNewData(new_data);
    }
  }, [data]);

  return (
    <TableData
      data={newData}
      teams={teams}
      selectedTeams={selectedTeams}
      setSelectedTeams={setSelectedTeams}
      game={game}
      previewGraphic={previewGraphic}
      type={type}
      customTeams={customTeams}
      setCustomTeams={setCustomTeams}
    />
  );
}

function TableData({
  data,
  teams,
  selectedTeams,
  setSelectedTeams,
  previewGraphic,
  game,
  type,
  customTeams,
  setCustomTeams,
  sod,
  getData,
}) {
  const [edit, setEdit] = useState(false);
  const [editTableData, setEditTableData] = useState([]);
  const { updateStartOfDayStandings } = useContext(APIContext);
  const { graphics } = useContext(GraphicsContext);
  async function save({ id, data }) {
    data.table.comp.group.team = data.table.comp.group.team?.sort((a, b) => {
      if (parseInt(a?.rank) > parseInt(b?.rank)) {
        return 1;
      }
      if (parseInt(a?.rank) < parseInt(b?.rank)) {
        return -1;
      }

      if (parseInt(a?.points) > parseInt(b?.points)) {
        return -1;
      }
      if (parseInt(a?.points) < parseInt(b?.points)) {
        return 1;
      }

      if (parseInt(a?.pointsdiff) < parseInt(b?.pointsdiff)) {
        return 1;
      }
      if (parseInt(a?.pointsdiff) > parseInt(b?.pointsdiff)) {
        return -1;
      }
      let diffPercent_a = (parseInt(a?.for) / parseInt(a?.against)) * 100;
      let diffPercent_b = (parseInt(b?.for) / parseInt(b?.against)) * 100;
      if (diffPercent_a < diffPercent_b) {
        return 1;
      }
      if (diffPercent_a > diffPercent_b) {
        return -1;
      }
    });
    await updateStartOfDayStandings({ id, data });
    getData();
    setEdit(false);
  }
  return (
    <>
      <Row>
        <GraphicButton
          onClick={() => {
            let table_data = { ...JSON.parse(JSON.stringify(data)) };
            let length = table_data.table.comp.group.team?.length;
            table_data.table.comp.group.team =
              table_data?.table?.comp?.group?.team?.filter(
                (t) =>
                  type !== "custom" ||
                  customTeams?.findIndex((c) => c === t?.id) > -1
              );
            previewGraphic("FFTable", {
              game: game,
              standings: { data: table_data },
              standings_length: length,
              teams,
              selectedTeams,
              sod,
            });
          }}
        >
          FF (50)
        </GraphicButton>
        <GraphicButton
          onClick={() => {
            let table_data = { ...JSON.parse(JSON.stringify(data)) };
            let length = table_data.table.comp.group.team?.length;
            table_data.table.comp.group.team =
              table_data?.table?.comp?.group?.team?.filter(
                (t) =>
                  type !== "custom" ||
                  customTeams?.findIndex((c) => c === t?.id) > -1
              );
            previewGraphic("SOFTable", {
              game: game,
              standings: { data: table_data },
              standings_length: length,
              teams,
              selectedTeams,
              sod,
            });
          }}
        >
          SOF (52)
        </GraphicButton>
        {type !== "custom" && (
          <GraphicButton
            onClick={() => {
              let table_data = { ...JSON.parse(JSON.stringify(data)) };
              let length = table_data.table.comp.group.team?.length;
              table_data.table.comp.group.team =
                table_data?.table?.comp?.group?.team?.filter(
                  (t) =>
                    type !== "custom" ||
                    customTeams?.findIndex((c) => c === t?.id) > -1
                );
              previewGraphic("CBTable", {
                game: game,
                standings: { data: table_data },
                standings_length: length,
                teams,
                top: true,
                selectedTeams,
                sod,
                push_up:
                  graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                  graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
              });
            }}
          >
            CB Top 6 (54)
          </GraphicButton>
        )}
        {type !== "custom" && (
          <GraphicButton
            onClick={() => {
              let table_data = { ...JSON.parse(JSON.stringify(data)) };
              let length = table_data.table.comp.group.team?.length;
              table_data.table.comp.group.team =
                table_data?.table?.comp?.group?.team?.filter(
                  (t) =>
                    type !== "custom" ||
                    customTeams?.findIndex((c) => c === t?.id) > -1
                );
              previewGraphic("CBTable", {
                game: game,
                standings: { data: table_data },
                standings_length: length,
                teams,
                bottom: true,
                selectedTeams,
                sod,
                push_up:
                  graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                  graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
              });
            }}
          >
            CB Bottom 6 (55)
          </GraphicButton>
        )}
        {type === "custom" && (
          <GraphicButton
            onClick={() => {
              let table_data = { ...JSON.parse(JSON.stringify(data)) };
              let length = table_data.table.comp.group.team?.length;
              table_data.table.comp.group.team =
                table_data?.table?.comp?.group?.team?.filter(
                  (t) =>
                    type !== "custom" ||
                    customTeams?.findIndex((c) => c === t?.id) > -1
                );
              previewGraphic("CBTable", {
                game: game,
                standings: { data: table_data },
                standings_length: length,
                teams,
                custom: true,
                selectedTeams,
                sod,
                push_up:
                  graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                  graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
              });
            }}
          >
            CB (54)
          </GraphicButton>
        )}
        {sod && (
          <GraphicButton
            style={{ marginLeft: "auto" }}
            green={edit}
            onClick={() => {
              setEditTableData({ ...JSON.parse(JSON.stringify(data)) });
              setEdit(!edit);
            }}
          >
            Edit
          </GraphicButton>
        )}
      </Row>

      <Row>
        {edit === false && (
          <Table>
            <thead>
              <tr>
                <TH></TH>
                <TH>#</TH>
                <TH></TH>
                <TH>Team</TH>
                <TH>Played</TH>
                <TH>Points</TH>
                <TH>Diff</TH>
                <TH>Diff %</TH>
                <TH>
                  {type === "custom" && (
                    <SmallButton
                      style={{ width: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setCustomTeams([]);
                      }}
                    >
                      {"Clr"}
                    </SmallButton>
                  )}
                </TH>
              </tr>
            </thead>
            <tbody>
              {data?.table?.comp?.group?.team
                ?.filter(
                  (t) =>
                    type !== "custom" ||
                    customTeams?.findIndex((c) => c === t?.id) > -1
                )
                ?.map((team, index) => {
                  let team_obj = teams?.find((te) => te?.opta_ID === team?.id);
                  console.log(
                    "relegation",
                    parseInt(
                      game?.competition?.variables?.number_of_relegation_places
                    )
                  );
                  return (
                    <TR
                      dots={
                        parseInt(team?.rank) ===
                          parseInt(
                            game?.competition?.variables
                              ?.number_of_playoff_places
                          ) ||
                        parseInt(team?.rank) ===
                          data?.table?.comp?.group?.team?.length -
                            parseInt(
                              game?.competition?.variables
                                ?.number_of_relegation_places
                            )
                      }
                      highlight={
                        selectedTeams?.findIndex((t) => t === team?.id) > -1
                      }
                      onClick={() => {
                        let new_teams = [...selectedTeams];
                        let index = new_teams?.findIndex((t) => t === team?.id);
                        if (index > -1) {
                          new_teams = new_teams?.filter((t) => t !== team?.id);
                        } else {
                          new_teams.push(team?.id);
                        }

                        setSelectedTeams(new_teams);
                      }}
                    >
                      <td>
                        {/* {index === 0 && (
                      <Button style={{ width: "10px" }}>C</Button>
                    )}
                    {index === 11 && (
                      <Button style={{ width: "10px" }}>R</Button>
                    )} */}
                      </td>

                      <td>{team?.rank}</td>
                      <td>
                        <Arrow
                          green={team.lastRank > team.rank}
                          red={team.lastRank < team.rank}
                        >
                          {team.lastRank > team.rank
                            ? "↑"
                            : team.lastRank < team.rank
                            ? "↓"
                            : ""}
                        </Arrow>
                      </td>
                      <td>{team_obj?.name}</td>
                      <td>{team?.played}</td>
                      <td>{team?.points}</td>
                      <td>
                        {team?.pointsdiff > 0
                          ? "+" + team?.pointsdiff
                          : team?.pointsdiff}
                      </td>
                      <td>
                        {Math.floor(
                          (parseInt(team?.for) / parseInt(team?.against)) * 100
                        )}
                      </td>
                      <td>
                        {type === "as_it_stands" && (
                          <SmallButton
                            style={{ width: "15px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              let new_teams = [...customTeams];
                              let index = new_teams?.findIndex(
                                (t) => t === team?.id
                              );
                              if (index > -1) {
                              } else {
                                new_teams.push(team?.id);
                              }

                              setCustomTeams(new_teams);
                            }}
                          >
                            {"->"}
                          </SmallButton>
                        )}
                        {type === "custom" && (
                          <SmallButton
                            style={{ width: "15px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              let new_teams = [...customTeams];
                              let index = new_teams?.findIndex(
                                (t) => t === team?.id
                              );
                              if (index > -1) {
                                new_teams = new_teams?.filter(
                                  (t) => t !== team?.id
                                );
                              }

                              setCustomTeams(new_teams);
                            }}
                          >
                            {"Del"}
                          </SmallButton>
                        )}
                      </td>
                    </TR>
                  );
                })}
            </tbody>
          </Table>
        )}
        {edit && (
          <Table>
            <thead>
              <tr>
                <TH>#</TH>
                <TH></TH>
                <TH>Team</TH>
                <TH>Played</TH>
                <TH>Points</TH>
                <TH>Diff</TH>
                <TH>For</TH>
                <TH>Against</TH>
              </tr>
            </thead>
            <tbody>
              {editTableData?.table?.comp?.group?.team
                ?.filter(
                  (t) =>
                    type !== "custom" ||
                    customTeams?.findIndex((c) => c === t?.id) > -1
                )
                ?.map((team, index) => {
                  let team_obj = teams?.find((te) => te?.opta_ID === team?.id);
                  return (
                    <TR
                      dots={
                        index ===
                          parseInt(
                            game?.competition?.variables
                              ?.number_of_playoff_places
                          ) ||
                        index ===
                          data?.table?.comp?.group?.team?.length -
                            parseInt(
                              game?.competition?.variables
                                ?.number_of_relegation_places
                            )
                      }
                    >
                      <td>
                        <Input
                          value={team?.rank}
                          onChange={(e) => {
                            let newTable = { ...editTableData };

                            newTable.table.comp.group.team.find(
                              (t) => t?.id === team?.id
                            ).rank = e.target.value;
                            setEditTableData(newTable);
                          }}
                        />
                      </td>
                      <td></td>
                      <td>{team_obj?.name}</td>
                      <td>
                        <Input
                          value={team?.played}
                          onChange={(e) => {
                            let newTable = { ...editTableData };

                            newTable.table.comp.group.team.find(
                              (t) => t?.id === team?.id
                            ).played = e.target.value;
                            setEditTableData(newTable);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={team?.points}
                          onChange={(e) => {
                            let newTable = { ...editTableData };

                            newTable.table.comp.group.team.find(
                              (t) => t?.id === team?.id
                            ).points = e.target.value;
                            setEditTableData(newTable);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={team?.pointsdiff}
                          onChange={(e) => {
                            let newTable = { ...editTableData };

                            newTable.table.comp.group.team.find(
                              (t) => t?.id === team?.id
                            ).pointsdiff = e.target.value;
                            setEditTableData(newTable);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={team?.for}
                          onChange={(e) => {
                            let newTable = { ...editTableData };

                            newTable.table.comp.group.team.find(
                              (t) => t?.id === team?.id
                            ).for = e.target.value;
                            setEditTableData(newTable);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={team?.against}
                          onChange={(e) => {
                            let newTable = { ...editTableData };

                            newTable.table.comp.group.team.find(
                              (t) => t?.id === team?.id
                            ).against = e.target.value;
                            setEditTableData(newTable);
                          }}
                        />
                      </td>
                    </TR>
                  );
                })}
            </tbody>
          </Table>
        )}
      </Row>
      {edit && (
        <Row>
          <GraphicButton
            onClick={() => {
              save({
                id: editTableData?._id,
                data: { table: editTableData?.table },
              });
            }}
          >
            Save
          </GraphicButton>
          <GraphicButton
            onClick={() => {
              setEdit(false);
            }}
          >
            Cancel
          </GraphicButton>
        </Row>
      )}
    </>
  );
}

export default TableView;
