import moment from "moment";

export class LTPlayerStat {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "PlayerStat";
    this.scene = "PlayerStat";
    this.data = [];
    this.page = 7000;
    this.lt_page = 7000;
    this.bug_page = 7010;
  }

  async preview({ options }) {
    this.onAir = false;
    this.page = options.type === "BUG" ? this.bug_page : this.lt_page;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options?.type === "LT") {
        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 1;

        xml.payload.field.find((item) => {
          return item.attr.name === "100";
        }).value = options.team?.variables?.badge || "";

        xml.payload.field.find((item) => {
          return item.attr.name === "101";
        }).value = options.player?.shirt_number || "";

        xml.payload.field.find((item) => {
          return item.attr.name === "102";
        }).value = options.player.first_name_upper || "";

        xml.payload.field.find((item) => {
          return item.attr.name === "103";
        }).value = options.player.last_name_upper || "";

        xml.payload.field.find((item) => {
          return item.attr.name === "104";
        }).value =
          "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
          (options.player?.nationality?.code?.toLowerCase() ||
            options.player?.nationality?.opta_code?.toLowerCase() ||
            "");

        xml.payload.field.find((item) => {
          return item.attr.name === "200";
        }).value =
          options.category?.replace("goals", "Kick success") +
          ": " +
          options?.value;
      } else if (options?.type === "BUG") {
        xml.payload.field.find((item) => {
          return item.attr.name === "101";
        }).value =
          options.player.first_name_upper +
          " " +
          options.player.last_name_upper;

        xml.payload.field.find((item) => {
          return item.attr.name === "102";
        }).value = options.category + ": " + options?.value;

        xml.payload.field.find((item) => {
          return item.attr.name === "110";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          options?.player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          options?.player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
