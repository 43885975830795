export class FormationForwards {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FormationForwards";
    this.scene = "Formation Forwards";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    let rugby_type = "";

    this.data.push({
      name: "Team Name",
      value: { text: options.team?.name || "" },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: options.team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Secondary Badge",
      value: {
        image: "${user}" + options.game?.competition?.secondary_badge || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.opta_name || "" },
    });

    let manager = options.manager;

    this.data.push({
      name: "Manager First Name Uppercase",
      value: {
        text: manager?.player?.first_name_upper || "",
      },
    });

    this.data.push({
      name: "Manager Last Name Uppercase",
      value: {
        text: manager?.player?.last_name_upper || "",
      },
    });

    this.data.push({
      name: "Manager Full Name",
      value: {
        text:
          (manager?.player?.first_name_upper || "") +
          " " +
          (manager?.player?.last_name_upper || ""),
      },
    });

    this.data.push({
      name: "Manager Title",
      value: {
        text: manager?.player?.title || "",
      },
    });

    new Array(13).fill(1).forEach((_, index) => {
      let row = index + 1;

      let player = options.squads?.find((s) => s.order === row);

      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });

      this.data.push({
        name: "Full Name Uppercase " + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper || "") +
            " " +
            (player?.squad?.player?.last_name_upper || ""),
        },
      });
      this.data.push({
        name: "Short Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.short_name_upper ||
            player?.squad?.player?.last_name_upper ||
            "",
        },
      });
      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      debugger;
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || player?.oval_position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text:
            (rugby_type === "union"
              ? player?.order
              : player?.squad?.shirt_number) || "",
        },
      });

      this.data.push({
        name: "Image " + row,
        value: {
          image: "${user}" + player?.squad?.player?.image || "",
        },
      });

      // this.data.push({
      //   name: "X/Y " + row,
      //   value: {
      //     position: {
      //       x: options.formation.positions[index].x,
      //       y: options.formation.positions[index].y,
      //     },
      //   },
      // });
    });

    new Array(10).fill(1).forEach((_, index) => {
      let row = index + 12;

      let player = options.squads?.find((s) => s.order === row);

      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });

      this.data.push({
        name: "Full Name Uppercase " + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper || "") +
            " " +
            (player?.squad?.player?.last_name_upper || ""),
        },
      });

      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text:
            (rugby_type === "union"
              ? player?.order
              : player?.squad?.shirt_number) || "",
        },
      });

      // this.data.push({
      //   name: "X/Y " + row,
      //   value: {
      //     position: {
      //       x: options.formation.positions[index].x,
      //       y: options.formation.positions[index].y,
      //     },
      //   },
      // });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
