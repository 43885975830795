export class PlayerKeyStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "PlayerKeyStats";
    this.scene = "PlayerKeyStats";
    this.data = [];
    this.page = 7013;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "102";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "101";
      }).value = options.show_number ? options.player?.shirt_number || "" : "";

      xml.payload.field.find((item) => {
        return item.attr.name === "300";
      }).value = options.line_2 || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value = options.team?.variables?.badge || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "104";
      }).value =
        "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
        (options.player?.nationality?.code?.toLowerCase() ||
          options.player?.nationality?.opta_code?.toLowerCase() ||
          "");

      let stats = [];
      if (options?.game?.home_team?._id === options.team?._id) {
        stats = options?.game?.opta_home_player_stats?.find(
          (p) => p?.player === options?.player.squad?.player?.opta_ID
        )?.stats;
      }
      if (options?.game?.away_team?._id === options.team?._id) {
        stats = options?.game?.opta_away_player_stats?.find(
          (p) => p?.player === options?.player.squad?.player?.opta_ID
        )?.stats;
      }
      let show_stats = [];
      if (options.type === "attack") {
        let cats = [
          { name: "Tries", variable: "tries" },
          { name: "Try assists", variable: "try_assists" },
          { name: "Metres", variable: "metres" },
          { name: "Post-contact metres", variable: "post_contact_metres" },
          { name: "Tackle busts", variable: "tackle_busts" },
          { name: "Linebreaks", variable: "clean_break" },
          { name: "Offloads", variable: "offload" },
        ];

        for (let i = 0; i < cats.length; i++) {
          if (cats[i]?.variable !== "metres" || parseInt(stats?.metres) > 90) {
            if (parseInt(stats[cats[i]?.variable]) > 0) {
              show_stats.push({
                name: cats[i]?.name,
                value: parseInt(stats[cats[i]?.variable]),
              });
            }
          }
        }
      } else if (options.type === "defence") {
        let cats = [
          { name: "Tackles", variable: "tackles" },
          { name: "Missed tackles", variable: "missed_tackles" },
          { name: "Tackle success", variable: "tackle_efficiency" },
        ];

        for (let i = 0; i < cats.length; i++) {
          if (
            parseInt(stats[cats[i]?.variable]) > 0 ||
            cats[i]?.variable === "Missed tackles"
          ) {
            show_stats.push({
              name: cats[i]?.name,
              value:
                parseInt(stats[cats[i]?.variable]) +
                (cats[i]?.variable === "tackle_efficiency" ? "%" : ""),
            });
          }
        }
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.Columns";
      }).value = show_stats?.length;

      for (let i = 0; i < show_stats.length; i++) {
        xml.payload.field.find((item) => {
          return item.attr.name === "20" + (i + 1);
        }).value = show_stats[i]?.name;
        xml.payload.field.find((item) => {
          return item.attr.name === "30" + (i + 1);
        }).value = show_stats[i]?.value;
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
