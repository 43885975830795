export class CrawlFormation {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "CrawlFormation";
    this.scene = "LT - Formation";
    this.data = [];
    this.page = 0;
  }

  preview({ options }) {
    this.options = options;
    this.onAir = false;

    this.data = [
      {
        name: "TITLE_TEXT",
        value: {
          text: options.game[options.team + "_team"].variables[
            "name_uppercase"
          ],
        },
      },
    ];

    let rows =
      options.game.live_data[options.team + "_formation"].rows.split("");
    let start = 0;
    let end = parseInt(rows[this.page]);
    for (let i = 0; i < this.page; i++) {
      start += parseInt(rows[i]);
    }

    this.data.push({
      name: "CTRL_NUM_PLAYERS",
      value: {
        text: end,
      },
    });

    options.game.live_data[options.team + "_team_lineup"]
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order > start && p.order <= start + end)
      .forEach((player, index) => {
        this.data.push({
          name: "PLAYER_" + (index + 1) + "_NUMBER",
          value: {
            text: player.shirt_number,
          },
        });
        this.data.push({
          name: "PLAYER_" + (index + 1) + "_NAME",
          value: {
            text: player.last_name_upper,
          },
        });
        this.data.push({
          name: "PLAYER_" + (index + 1),
          value: {
            visible: true,
          },
        });
        this.data.push({
          name: "PLAYER_" + (index + 1) + "_HEAD",
          value: {
            visible: false,
          },
        });
      });

    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
    this.page++;
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.page = 0;
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
