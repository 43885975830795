import React, { useContext } from "react";
import styled from "styled-components";
import { ClockContext } from "../../contexts/ClockContext";
import { ControlContext } from "../../contexts/ControlContext";
import { VizContext } from "../../contexts/VizContext";

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  padding: 0.5em;
  color: #474e5f;
  align-items: center;
`;

const Connected = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  background-color: ${(props) => (props.connected ? "#0adb93" : "red")};
  border-radius: 50%;
  margin: 1em;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

function ServerStatus() {
  const { hyperClock } = useContext(ControlContext);
  const {
    wsStatus,
    hyperLink,
    mse,
    trio,
    trioStatus,
    trioShow,
    vizProfile,
    showID,
    vizEngine,
    vizEngineStatus,
    vizEngineSharedMemory,
    vizEngineSharedMemoryStatus,
  } = useContext(VizContext);
  const { wsStatus: clockStatus, auto_clock } = useContext(ClockContext);

  return (
    <ActionPanel>
      <Column>
        <Connected connected={wsStatus === 1} />
        Hyper Link Server: {hyperLink}
      </Column>
      <Column>MSE: {mse}</Column>
      {trio && (
        <Column>
          <Connected connected={trioStatus?.status === "connected"} />
          Trio IP: {trio}
        </Column>
      )}
      {vizEngine && (
        <Column>
          <Connected connected={vizEngineStatus?.status === "connected"} /> Viz
          IP: {vizEngine}{" "}
        </Column>
      )}
      {vizEngineSharedMemory && (
        <Column>
          <Connected
            connected={vizEngineSharedMemoryStatus?.status === "connected"}
          />{" "}
          Viz SHM IP: {vizEngineSharedMemory}{" "}
        </Column>
      )}
      <Column>
        {" "}
        <Connected connected={showID} />
        Trio Show: {trioShow}
      </Column>
      <Column>Viz Profile: {vizProfile}</Column>
      <Column>
        <Connected connected={clockStatus === 1} />
        Stadium Clock: {hyperClock}{" "}
        {clockStatus === 1 && (
          <div style={{ marginLeft: "10px" }}>
            {auto_clock?.split(":")[0]}:{auto_clock?.split(":")[1]}
          </div>
        )}
      </Column>
    </ActionPanel>
  );
}

export default ServerStatus;
