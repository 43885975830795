export class OnBench {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "OnBench";
    this.scene = "OnBench";
    this.data = [];
    this.page = 7052;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "00101";
      }).value =
        options.team?.variables?.alt_badge || options.team?.variables?.badge;

      options?.subs?.map((player, index) => {
        let row = index + 1;

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + row + "1";
        }).value = player?.squad?.shirt_number || "";

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + row + "3";
        }).value =
          (player?.squad?.player?.first_name || "") +
          " " +
          (player?.squad?.player?.last_name || "");

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + row + "5";
        }).value = player?.stats?.[options?.stat_key];
      });

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
