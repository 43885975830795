import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
import { APIContext } from "../../../contexts/APIContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TeamName = styled.div`
  margin: 10px;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const IPadButton = styled(Button)`
  padding: 17px;
  margin: 10px;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => (props.selected ? "#db0a41" : "")};
  :hover {
    background-color: ${(props) => (!props.selected ? "#4c5264" : "")};
  }
`;
const BlankButton = styled.div`
  min-width: 150px;
`;
const CancelButton = styled(Button)`
  padding: 0px;
  margin: 10px;
  margin-top: 0px;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
  background-color: #22252c;
  :hover {
    background-color: ${(props) => (!props.selected ? "#4c5264" : "")};
  }
`;

const Title = styled.div``;
const Symbol = styled.div`
  font-size: 40px;
`;
const MinusSymbol = styled.div`
  font-size: 20px;
`;
const Value = styled.div`
  font-size: 40px;
`;
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        stories
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            opta_code
            opta_name
            name
            code
          }
          image
          stories
          oval_ID
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      hia
      subbed
      captain
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        stats
        stories
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          short_name
          short_name_upper
          nationality {
            _id
            opta_code
            opta_name
            name
            code
          }
          image
          stories
          oval_ID
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      hia
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      attendance
      stadium {
        opta_name
        name
      }
      competition {
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
    }
  }
`;

const CATEGORIES = [
  "Tackles",
  "Missed Tackles",
  "Breaks",
  "Handling errors",
  "Offloads",
  "Carries",
  "Conversion Attempts",
  "Metres",
];

function PlayerStats() {
  let { gameID, team_type } = useParams();
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [selectedPlayerStats, setSelectedPlayerStats] = useState();
  const [game, setGame] = useState();
  const { updateGame } = useContext(APIContext);
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;

    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  return (
    <Main>
      <TeamName>{game?.[team_type + "_team"]?.name}</TeamName>
      <Row>
        <Row style={{ width: "40%" }}>
          {game?.lineup?.[team_type]?.map((player) => {
            return (
              <IPadButton
                selected={player?.squad?._id === selectedPlayer}
                onClick={() => {
                  setSelectedPlayer(player?.squad?._id);
                  setSelectedPlayerStats(
                    game?.[team_type + "_player_stats"]?.[player?.squad?._id]
                  );
                }}
              >
                {player?.squad?.shirt_number} -{" "}
                {player?.squad?.player?.first_name}{" "}
                {player?.squad?.player?.last_name}
              </IPadButton>
            );
          })}
        </Row>
        <Row style={{ width: "60%" }}>
          {selectedPlayer &&
            CATEGORIES.map((c) => {
              return (
                <StatGroup>
                  <IPadButton
                    onClick={() => {
                      let newPlayerStats = { ...selectedPlayerStats };
                      newPlayerStats[c] = (selectedPlayerStats?.[c] || 0) + 1;
                      setSelectedPlayerStats(newPlayerStats);
                      updateGame({
                        id: game._id,
                        [team_type + "_player_stats"]: {
                          ...game?.[team_type + "_player_stats"],
                          [selectedPlayer]: {
                            ...game?.[team_type + "_player_stats"]?.[
                              selectedPlayer
                            ],
                            [c]: newPlayerStats[c],
                          },
                        },
                      });
                    }}
                  >
                    <Title>{c}</Title>
                    <Value>{selectedPlayerStats?.[c] || 0}</Value>
                    <Symbol>+</Symbol>
                  </IPadButton>
                  <CancelButton
                    onClick={() => {
                      let newPlayerStats = { ...selectedPlayerStats };
                      newPlayerStats[c] = (selectedPlayerStats?.[c] || 0) - 1;
                      setSelectedPlayerStats(newPlayerStats);

                      updateGame({
                        id: game._id,
                        [team_type + "_player_stats"]: {
                          ...game?.[team_type + "_player_stats"],
                          [selectedPlayer]: {
                            ...game?.[team_type + "_player_stats"]?.[
                              selectedPlayer
                            ],
                            [c]: newPlayerStats[c],
                          },
                        },
                      });
                    }}
                  >
                    <MinusSymbol>-</MinusSymbol>
                  </CancelButton>
                </StatGroup>
              );
            })}
          <StatGroup>
            <BlankButton />
          </StatGroup>
          <StatGroup>
            <IPadButton
              onClick={() => {
                let newPlayerStats = { ...selectedPlayerStats };
                newPlayerStats["Metres"] =
                  (selectedPlayerStats?.["Metres"] || 0) + 5;
                setSelectedPlayerStats(newPlayerStats);
                updateGame({
                  id: game._id,
                  [team_type + "_player_stats"]: {
                    ...game?.[team_type + "_player_stats"],
                    [selectedPlayer]: {
                      ...game?.[team_type + "_player_stats"]?.[selectedPlayer],
                      Metres: newPlayerStats["Metres"],
                    },
                  },
                });
              }}
            >
              <Title>+5 metres</Title>
            </IPadButton>
            <CancelButton
              onClick={() => {
                let c = "Metres";
                let newPlayerStats = { ...selectedPlayerStats };
                newPlayerStats[c] = (selectedPlayerStats?.[c] || 0) - 5;
                setSelectedPlayerStats(newPlayerStats);

                updateGame({
                  id: game._id,
                  [team_type + "_player_stats"]: {
                    ...game?.[team_type + "_player_stats"],
                    [selectedPlayer]: {
                      ...game?.[team_type + "_player_stats"]?.[selectedPlayer],
                      [c]: newPlayerStats[c],
                    },
                  },
                });
              }}
            >
              <MinusSymbol>-</MinusSymbol>
            </CancelButton>
          </StatGroup>
          <StatGroup>
            <IPadButton
              onClick={() => {
                let newPlayerStats = { ...selectedPlayerStats };
                newPlayerStats["Metres"] =
                  (selectedPlayerStats?.["Metres"] || 0) + 10;
                setSelectedPlayerStats(newPlayerStats);
                updateGame({
                  id: game._id,
                  [team_type + "_player_stats"]: {
                    ...game?.[team_type + "_player_stats"],
                    [selectedPlayer]: {
                      ...game?.[team_type + "_player_stats"]?.[selectedPlayer],
                      Metres: newPlayerStats["Metres"],
                    },
                  },
                });
              }}
            >
              <Title>+10 metres</Title>
            </IPadButton>
            <CancelButton
              onClick={() => {
                let c = "Metres";
                let newPlayerStats = { ...selectedPlayerStats };
                newPlayerStats[c] = (selectedPlayerStats?.[c] || 0) - 10;
                setSelectedPlayerStats(newPlayerStats);

                updateGame({
                  id: game._id,
                  [team_type + "_player_stats"]: {
                    ...game?.[team_type + "_player_stats"],
                    [selectedPlayer]: {
                      ...game?.[team_type + "_player_stats"]?.[selectedPlayer],
                      [c]: newPlayerStats[c],
                    },
                  },
                });
              }}
            >
              <MinusSymbol>-</MinusSymbol>
            </CancelButton>
          </StatGroup>
          <StatGroup>
            <IPadButton
              onClick={() => {
                let newPlayerStats = { ...selectedPlayerStats };
                newPlayerStats["Metres"] =
                  (selectedPlayerStats?.["Metres"] || 0) + 15;
                setSelectedPlayerStats(newPlayerStats);
                updateGame({
                  id: game._id,
                  [team_type + "_player_stats"]: {
                    ...game?.[team_type + "_player_stats"],
                    [selectedPlayer]: {
                      ...game?.[team_type + "_player_stats"]?.[selectedPlayer],
                      Metres: newPlayerStats["Metres"],
                    },
                  },
                });
              }}
            >
              <Title>+15 metres</Title>
            </IPadButton>
            <CancelButton
              onClick={() => {
                let c = "Metres";
                let newPlayerStats = { ...selectedPlayerStats };
                newPlayerStats[c] = (selectedPlayerStats?.[c] || 0) - 15;
                setSelectedPlayerStats(newPlayerStats);

                updateGame({
                  id: game._id,
                  [team_type + "_player_stats"]: {
                    ...game?.[team_type + "_player_stats"],
                    [selectedPlayer]: {
                      ...game?.[team_type + "_player_stats"]?.[selectedPlayer],
                      [c]: newPlayerStats[c],
                    },
                  },
                });
              }}
            >
              <MinusSymbol>-</MinusSymbol>
            </CancelButton>
          </StatGroup>
        </Row>
      </Row>
    </Main>
  );
}

export default PlayerStats;
