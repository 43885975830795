export class ClockTeamStat {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TeamStat";
    this.scene = "Clock";
    this.data = [];
    this.page = 10000;
  }

  async preview({ options }) {
    let string =
      "SMMSystem_SetValue|SHMSend_StatComponents|" +
      options?.stat?.category?.replace(options?.stat?.line_2, "") +
      "," +
      (options?.stat?.home === undefined
        ? ""
        : options?.stat?.home?.toString()?.replace("%", "")?.replace("m", "")) +
      "," +
      (options?.stat?.away === undefined
        ? ""
        : options?.stat?.away?.toString()?.replace("%", "")?.replace("m", "")) +
      (options?.stat?.home?.toString()?.indexOf("%") > -1
        ? ",%"
        : options?.stat?.home?.toString()?.indexOf("m") > -1
        ? ",m"
        : "");
    if (options?.stat?.line_2) {
      string += ",," + options?.stat?.line_2;
    }

    //BELOW IS FOR CRAWL
    // this.vizSharedMemory({
    //   shared_memory:
    //     '10 VIZ_COMMUNICATION*MAP SET_STRING_ELEMENT "SHMStat_MF_Possession" 0%|100%',
    // });
    // this.vizSharedMemory({
    //   shared_memory:
    //     '10 VIZ_COMMUNICATION*MAP SET_STRING_ELEMENT "SHMSend_ClockTicker" 0|True||Match Facts|<CONTAINER "$MatchFacts">',
    // });
    // string =
    //   '10 VIZ_COMMUNICATION*MAP SET_STRING_ELEMENT "SHMSend_StatComponents" Tries,1,2,,PenaltyTimeline';
    this.vizSharedMemory({
      shared_memory: string,
    });
  }
  animate({ options }) {}

  animateOff({ options }) {
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_StatComponents|REG_Score,#,#",
    });
  }

  update({ options }) {}
}
