import React, { useContext, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { DataProvider } from "./contexts/DataContext";
import Hyper from "./Theme/Hyper";
import Main from "./components/Views/Main";
import { ControlContext, ControlProvider } from "./contexts/ControlContext";
import { GraphicsContext, GraphicsProvider } from "./contexts/GraphicsContext";
import { UIContext, UIProvider } from "./contexts/UIContext";
import Config from "./components/Views/Config";
import Live from "./components/Live/Live";
import WindowTitleBar from "./components/WindowTitleBar/WindowTitleBar";
import Management from "./components/Management/Management";
import { APIProvider } from "./contexts/APIContext";
import NavBar from "./components/TopBar/NavBar";
import { ClockProvider } from "./contexts/ClockContext";
import { VizControlProvider } from "./contexts/VizContext";

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  window.hyperLink = window.localStorage.getItem("hyperLink") || "127.0.0.1";
  window.MSEAddress = window.localStorage.getItem("MSEAddress") || "127.0.0.1";
  window.showName =
    window.localStorage.getItem("showName") || "SkySports_Football";
  window.vizProfile = window.localStorage.getItem("vizProfile") || "default";
  window.dataUrl =
    window.localStorage.getItem("dataUrl") ||
    "ws://skyfootballdata.hyperstudios.live";
  window.dataUrl = "ws://opta.hyperstudios.live:8082";
  window.dataUrl = "ws://109.228.39.127:8082";
  window.dataUrl = "ws://localhost:8082";
  window.command_server =
    getParameterByName("command_server") ||
    window.ENV?.REACT_APP_COMMAND_SERVER ||
    process.env.REACT_APP_COMMAND_SERVER;

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={Hyper}>
        <VizControlProvider>
          <ControlProvider>
            <ClockProvider>
              <GraphicsProvider>
                <DataProvider>
                  <APIProvider>
                    <UIProvider>
                      <ShowMain>
                        <Switch>
                          <Route path="/Config">
                            <Config />
                          </Route>
                          <Route path="/">
                            <Main />
                          </Route>
                        </Switch>
                      </ShowMain>
                    </UIProvider>
                  </APIProvider>
                </DataProvider>
              </GraphicsProvider>
            </ClockProvider>
          </ControlProvider>
        </VizControlProvider>
      </ThemeProvider>
    </Router>
  );
}
function ShowMain({ children }) {
  const { uiView, setUIView } = useContext(UIContext);
  const { clearPVW, graphicInPreview } = useContext(GraphicsContext);
  const { clear } = useContext(ControlContext);
  useEffect(() => {
    function handleKeyDown(e) {
      console.log(e.keyCode);

      if (e.keyCode === 112) {
        setUIView(0);
      }
      if (e.keyCode === 113) {
        setUIView(1);
      }
      if (e.keyCode === 114) {
        setUIView(2);
      }
      if (e.keyCode === 81 && e.ctrlKey && !e.shiftKey) {
        if (graphicInPreview && graphicInPreview.name) {
          clearPVW(graphicInPreview.name);
        }
      }

      if (e.keyCode === 81 && e.ctrlKey && e.shiftKey) {
        if (graphicInPreview && graphicInPreview.name) {
          clearPVW(graphicInPreview.name);
        }
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [graphicInPreview]);
  return (
    <div
      style={{ display: uiView === 1 || !window.process ? "block" : "none" }}
    >
      {children}
    </div>
  );
}
export default App;
