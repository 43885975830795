import { useContext } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
function Tables({ game, previewGraphic }) {
  const { getStandings, getTeams, getFixtures } = useContext(APIContext);
  const { graphics } = useContext(GraphicsContext);
  return (
    <Main>
      <GraphicButton
        onClick={async () => {
          let standings = (await getStandings({}))?.standings || [];
          let teams = (await getTeams({})) || [];

          standings = standings?.find(
            (g) =>
              g?.opta_comp_ID === game?.competition?.opta_ID &&
              g?.opta_season_ID === game?.season?.opta_ID
          );

          previewGraphic("FFTable", {
            game: game,
            standings,
            standings_length: standings?.length,
            teams,
          });
        }}
      >
        FF Table (50)
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          let standings = (await getStandings({}))?.standings || [];
          let teams = (await getTeams({})) || [];

          standings = standings?.find(
            (g) =>
              g?.opta_comp_ID === game?.competition?.opta_ID &&
              g?.opta_season_ID === game?.season?.opta_ID
          );

          previewGraphic("SOFTable", {
            game: game,
            standings,
            standings_length: standings?.length,
            teams,
          });
        }}
      >
        SOF Table (52)
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          let standings = (await getStandings({}))?.standings || [];
          let teams = (await getTeams({})) || [];

          standings = standings?.find(
            (g) =>
              g?.opta_comp_ID === game?.competition?.opta_ID &&
              g?.opta_season_ID === game?.season?.opta_ID
          );

          previewGraphic("CBTable", {
            game: game,
            standings,
            standings_length: standings?.length,
            teams,
            top: true,
            push_up:
              graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
              graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
          });
        }}
      >
        CB Top 6 Table (54)
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          let standings = (await getStandings({}))?.standings || [];
          let teams = (await getTeams({})) || [];

          standings = standings?.find(
            (g) =>
              g?.opta_comp_ID === game?.competition?.opta_ID &&
              g?.opta_season_ID === game?.season?.opta_ID
          );

          previewGraphic("CBTable", {
            game: game,
            standings,
            standings_length: standings?.length,
            teams,
            bottom: true,
            push_up:
              graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
              graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
          });
        }}
      >
        CB Bottom 6 Table (55)
      </GraphicButton>
    </Main>
  );
}

export default Tables;
