import React, { useState, useEffect, useContext, useCallback } from "react";
import _ from "lodash";
import Mock from "../MockData/MatchEvents.json";
import { GraphicsContext } from "./GraphicsContext";
import { processStats } from "../Opta/Stats";
import moment from "moment";
import Formation352 from "../components/Formation/Formations/SKY/Formation352";

const DataContext = React.createContext([{}, () => {}]);

const DataProvider = (props) => {
  const { updateGraphic } = useContext(GraphicsContext);

  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [squads, setSquads] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [matchEvents, setMatchEvents] = useState([]);
  const [matchStats, setMatchStats] = useState([]);
  const [seasonStats, setSeasonStats] = useState([]);
  const [standings, setStandings] = useState();
  const [sky, setSky] = useState([]);
  const [kits, setKits] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [selectedGameID, setSelectedGameID] = useState();
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (selectedGame) {
      // if (!selectedGame.live_data || !selectedGame.live_data.homeScore) {
      //   selectedGame.live_data.homeScore = 0;
      // }
      // if (!selectedGame.live_data || !selectedGame.live_data.awayScore) {
      //   selectedGame.live_data.awayScore = 0;
      // }
      // if (
      //   !selectedGame.live_data ||
      //   !selectedGame.live_data.homeScore_matchSelect
      // ) {
      //   selectedGame.live_data.homeScore_matchSelect = 0;
      // }
      // if (
      //   !selectedGame.live_data ||
      //   !selectedGame.live_data.awayScore_matchSelect
      // ) {
      //   selectedGame.live_data.awayScore_matchSelect = 0;
      // }

      // if (!selectedGame.live_data.home_formation) {
      //   selectedGame.live_data.home_formation = new Formation352();
      // }

      // if (!selectedGame.live_data.away_formation) {
      //   selectedGame.live_data.away_formation = new Formation352();
      // }

      // if (!selectedGame.live_data || !selectedGame.live_data.periods) {
      //   selectedGame.live_data.periods = [{ number: 1, kick_off: null }];
      // }

      // if (updateGraphic) {
      //   updateGraphic("CLOCK", {
      //     teams: teams,
      //     game: selectedGame,
      //     sky: sky,
      //   });
      // }

      setSelectedGameID(selectedGame._id);

      // ws.send(
      //   JSON.stringify({
      //     type: "subscribe-game",
      //     id: selectedGame.uuid,
      //     optaID: selectedGame.opta_ID,
      //   })
      // );
    }
  }, [selectedGame, updateGraphic]);

  useEffect(() => {
    if (selectedGameID) {
      setMatchEvents([]);
      setMatchStats([]);
      // subscribeToGame();
    }
  }, [selectedGameID]);

  const subscribeToGame = useCallback(() => {
    if (selectedGameID && ws.readyState) {
      ws.send(
        JSON.stringify({
          type: "subscribe-game",
          id: selectedGameID,
        })
      );
    }
  }, [selectedGameID, ws]);

  function connect() {
    console.log("DATA connecting to server");
    let server = "ws://skyfootballdata.hyperstudios.live";

    // window.location.hostname.indexOf("localhost") > -1
    //   ? "ws://" + window.location.hostname + ":6090"
    //   : "ws://skyfootballdata.hyperstudios.live";
    //setWS(new WebSocket(window.dataUrl.replace("8082", "9292")));
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;
      ws.onopen = () => {
        setWSStatus(1);
        console.log("DATA on open");
        //subscribeToGame();
        clearInterval(interval);
        interval = setInterval(() => {
          ws.send(
            JSON.stringify({
              type: "ping",
              time: Date.now(),
            })
          );
        }, 10000);
      };
      ws.onmessage = (data) => {
        //console.log("DATA on message");
        //console.debug(data);
        try {
          let obj = JSON.parse(data.data);

          if (obj) {
            switch (obj.type) {
              case "game":
                if (obj.data) {
                  setSelectedGame(obj.data);
                }
                break;
              case "kits":
                setKits(obj.data);
                break;
              case "teams":
                setTeams(obj.data);
                break;
              case "players":
                setPlayers(obj.data);
                break;
              case "fixtures":
                let fixtures = obj.data.filter(
                  (g) => g.season.name === "2020/2021"
                );

                setFixtures(fixtures);
                break;
              case "squads":
                setSquads(obj.data);
                break;
              case "competitions":
                setCompetitions(obj.data);
                break;
              case "countries":
                setCountries(obj.data);
                break;
              case "standings":
                setStandings(obj.data);
                break;
              case "update-game":
                setSelectedGame(obj.data);
                break;
              case "season-stats":
                setSeasonStats((s) => s.concat([obj.data]));
                break;
              case "matchEvents":
                setMatchEvents(obj.data.events);
                break;
              case "matchEvents-update":
                let newMatchEvents = [...matchEvents];

                obj.data.events.forEach((s) => {
                  if (s.typeId === 32) {
                    updateGame({
                      uuid: selectedGameID,
                      data: { actual_kick_off: moment().valueOf() },
                    });
                  }

                  let index = newMatchEvents.findIndex((m) => m.id === s.id);
                  if (index > -1) {
                    newMatchEvents[index] = {
                      ...newMatchEvents[index],
                      ...s,
                    };
                  } else {
                    newMatchEvents.push(s);
                  }
                });

                setMatchEvents(newMatchEvents);
                break;
              case "matchStats":
                let newGame = { ...selectedGame };

                newGame.teamStats = processStats({
                  newStats: obj.data,
                  oldStats: newGame.teamStats,
                  home_team: newGame.home_team,
                  away_team: newGame.away_team,
                });

                setSelectedGame(newGame);
                //setMatchStats(obj.data.stats);
                break;
              case "stadiums":
                setStadiums(obj.data);
                break;
              case "sky":
                setSky(
                  obj.data.map((s) => {
                    return {
                      ...s,
                      badge: s.badge.replace("Brighton-&-hove", "Brighton"),
                    };
                  })
                );
                break;
              default:
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      ws.onerror = (err) => {
        console.log("DATA on message");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        console.log("DATA on close");
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
      return () => {
        clearTimeout(timeout);
        //clearInterval(interval);
      };
    }
  }, [ws, selectedGame, matchEvents]);

  // useEffect(() => {
  //   fetch("http://localhost:8082/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTeams(data.teams);
  //       setPlayers(data.players);
  //       setFixtures(data.fixtures);
  //     });
  //   return () => {};
  // }, []);

  function addFixture(fixture) {
    let id = fixture.uuid;
    delete fixture.uuid;
  }

  function updateGame(obj) {
    setSelectedGame(obj);
  }
  function updatePlayer(obj) {
    let newPlayers = [...players];
    let playerIndex = newPlayers.findIndex((p) => p.uuid === obj.uuid);

    if (playerIndex > -1) {
      newPlayers[playerIndex].stats = obj.stats;
    }
    setPlayers(newPlayers);
    ws.send(
      JSON.stringify({
        type: "update-player",
        player: newPlayers.find((p) => p.uuid === obj.uuid),
      })
    );
  }

  function getGames(competitionId) {
    ws.send(
      JSON.stringify({
        type: "get-fixtures",
        competition: competitionId,
      })
    );
  }

  function update(obj) {
    ws.send(JSON.stringify(obj));
  }

  function refreshData() {
    ws.send(JSON.stringify({ type: "refresh", id: selectedGameID }));
  }

  useEffect(() => {
    if (selectedGame) {
      let newGameData = { ...selectedGame };
      setGameData(newGameData);
    }
  }, [selectedGame, teams, players]);

  function buildLineup(lineup) {
    lineup = lineup.map((row) => {
      return { ...row, player: players.find((p) => p._id === row.player) };
    });
    return lineup;
  }

  return (
    <DataContext.Provider
      value={{
        teams,
        players,
        fixtures,
        squads,
        competitions,
        matchEvents,
        addFixture,
        updateGame,
        selectedGame,
        setSelectedGame,
        updatePlayer,
        stadiums,
        sky,
        matchStats,
        seasonStats,
        countries,
        standings,
        kits,
        getGames,
        setSelectedGameID,
        update,
        gameData,
        refreshData,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
export { DataContext, DataProvider };
