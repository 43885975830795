export class Player {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Player";
    this.scene = "L3_BadgeNumName";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [
      {
        name: "HOME_BADGE",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" + options.team.variables["badge_home"]
            ),
        },
      },
      {
        name: "FirstName",
        value: {
          text: options.player.first_name_upper,
        },
      },
      {
        name: "LastName",
        value: {
          text: options.player.last_name_upper,
        },
      },
      {
        name: "NUMBER",
        value: {
          text: options.player.shirt_number,
        },
      },
    ];
    this.sendPreview(this.scene, 50, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
