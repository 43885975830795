import React, { useState, useEffect, useCallback } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";
import parser from "fast-xml-parser";

var options = {
  attributeNamePrefix: "",
  attrNodeName: "attr", //default is 'false'
  textNodeName: "#text",
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseNodeValue: false,
  parseAttributeValue: false,
  trimValues: false,
  cdataTagName: "__cdata", //default is 'false'
  cdataPositionChar: "\\c",
  parseTrueNumberOnly: false,
  arrayMode: false, //"strict"
  stopNodes: ["parse-me-as-string"],
};
var XMLParser = parser.j2xParser;
const toXML = new XMLParser(options);

const VizContext = React.createContext([{}, () => {}]);
window.scenesOnAirViz = [];

window.control_viz_ws = null;

let commands = [];
let show_ID = "";

const VizControlProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);

  const [hyperLink, setHyperLink] = useLocalStorage(
    "hyper_link",
    (window.location.protocol.indexOf("https") > -1 ? "wss" : "ws") +
      "://" +
      window.location.hostname +
      ":6002"
  );
  const [mse, setMSE] = useLocalStorage("mse", "127.0.0.1");
  const [trio, setTrio] = useLocalStorage("trio", "127.0.0.1");
  const [vizEngine, setVizEngine] = useLocalStorage("viz_engine", "127.0.0.1");
  const [vizEngineStatus, setVizEngineStatus] = useState();
  const [trioStatus, setTrioStatus] = useState();
  const [vizEngineSharedMemoryStatus, setVizEngineSharedMemoryStatus] =
    useState();
  const [trioShow, setTrioShow] = useLocalStorage("trio_show", "NETBALL 2022");
  const [vizProfile, setVizProfile] = useLocalStorage("viz_profile", "default");
  const [vizEngineSharedMemory, setVizEngineSharedMemory] = useLocalStorage(
    "viz_engine_shared_memory",
    "127.0.0.1:13000"
  );

  const [showID, setShowID] = useState();
  function setShow_ID(value) {
    show_ID = value;
    setShowID(value);
  }

  useEffect(() => {
    window.id = "rugby";
    connect();
  }, []);

  function connect() {
    if (
      window.ENV?.REACT_APP_SKY === "true" ||
      process.env.REACT_APP_SKY === "true"
    ) {
      console.log("Rugby Viz Control connecting to server");
      try {
        window.control_viz_ws = new WebSocket(hyperLink);

        setWS(window.control_viz_ws);
      } catch (err) {}
    }
  }

  useEffect(() => {
    if (window.control_viz_ws) {
      let timeout;
      window.control_viz_ws.onopen = () => {
        getShow();

        setWSStatus(1);
        console.log("Rugby Viz Control on open");
        clearInterval(window.keep_alive);
        previewTrio("");
        vizSharedMemory({ shared_memory: "" });
        vizCommand({ viz_command: "" });
        window.keep_alive = setInterval(() => {
          window.control_viz_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
        sendData({
          group: window.id,
        });
      };
      window.control_viz_ws.onmessage = (data) => {
        console.log("RugbyViz  Control on message");
        try {
          let obj = JSON.parse(data.data);

          if (obj) {
            if (obj.command_id && !obj.from_control) {
              let command = commands.find(
                (command) => command.id === obj.command_id
              );

              let xml = parser.parse(obj?.response, options);
              clearTimeout(command.timeout);

              command?.resolve(xml);
              commands = commands.filter(
                (command) => command.id !== obj.command_id
              );
            }
            if (obj.type === "viz-list") {
              setVizEngineStatus(
                obj.data?.find((v) => {
                  return v.ip + ":" + v.port === vizEngine;
                })
              );
              setVizEngineSharedMemoryStatus(
                obj.data?.find((v) => {
                  return v.ip + ":" + v.port === vizEngineSharedMemory;
                })
              );
              setTrioStatus(
                obj.data?.find((v) => {
                  return v.ip + ":" + v.port === trio;
                })
              );
            }
            if (obj.type === "viz-update") {
              if (obj.data?.ip + ":" + obj.data.port === vizEngine) {
                setVizEngineStatus(obj.data);
              } else if (
                obj.data?.ip + ":" + obj.data.port ===
                vizEngineSharedMemory
              ) {
                setVizEngineSharedMemoryStatus(obj.data);
              } else if (obj.data?.ip + ":" + obj.data.port === trio) {
                setTrioStatus(obj.data);
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      window.control_viz_ws.onerror = (err) => {
        console.log("Rugby Viz Control on message", err);
        ws.close();
      };
      window.control_viz_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Rugby Viz Control on close");
        clearTimeout(timeout);
        clearInterval(window.keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data) {
    try {
      if (window.control_viz_ws) {
        data.user = {
          id: window.id,
        };

        data.group = localStorage.getItem("user-id");

        data.app = "rugby";
        data.engine =
          window.engines?.find((e) => e.key === window.selectedEngine)?.key ||
          window.engines?.[0]?.key;

        window.control_viz_ws.send(
          JSON.stringify({ ...data, from_control: true })
        );
      }
    } catch (err) {}
  }

  function previewTrio(command) {
    sendData({
      action: "preview",
      trio: trio?.split(":")?.[0],
      port: trio?.split(":")?.[1],
      command,
      group: window.id,
      engine: window.engines?.[0]?.key,
    });
  }

  function vizSharedMemory({ shared_memory }) {
    sendData({
      trio: vizEngineSharedMemory?.split(":")?.[0],
      port: vizEngineSharedMemory?.split(":")?.[1],
      shared_memory,
      group: window.id,
      engine: window.engines?.[0]?.key,
    });
  }

  function vizCommand({ viz_command }) {
    sendData({
      trio: vizEngine?.split(":")?.[0],
      port: vizEngine?.split(":")?.[1],
      viz_command,
      group: window.id,
      engine: window.engines?.[0]?.key,
    });
  }

  async function MSECommand({ url, options }) {
    let promise = new Promise((resolve, reject) => {
      let id = Math.random(Number.max);
      commands.push({
        id,
        resolve,
        reject,
        timeout: setTimeout(() => {
          reject("timeout");
        }, 5000),
      });

      sendData({
        command_id: id,
        mse: url,
        options,
        engine: window.engines?.[0]?.key,
        group: window.id,
      });
    })?.catch((err) => {
      console.log(err);
    });

    return promise;
  }

  const getShow = useCallback(async () => {
    return MSECommand({
      url: "http://" + mse + ":8580/directory/shows/",
    }).then((data) => {
      let show_obj = data?.feed?.entry?.find((show) => show.title === trioShow);
      let show_id = show_obj?.link?.[0]?.attr?.href?.split("/")?.slice(-2)?.[0];

      if (show_id) {
        console.log("got show id", show_id);
        setShow_ID(show_id);
      } else {
        setShow_ID();
      }
    });
  }, [mse]);

  const getVizPage = useCallback(async ({ page }) => {
    if (!show_ID) {
      getShow();
    }
    let show_id = show_ID;
    return MSECommand({
      url:
        "http://" +
        mse +
        ":8580/element_collection/storage/shows/" +
        show_id +
        "/elements/" +
        page +
        "/",
    });
  }, []);

  const updateVizPage = useCallback(async ({ page, data, escape = true }) => {
    if (!show_ID) {
      getShow();
    }
    let show_id = show_ID;
    let xml = toXML.parse(data);
    if (escape) {
      xml = xml.replace(/&/g, "&amp;");
    }
    return MSECommand({
      url:
        "http://" +
        mse +
        ":8580/element_collection/storage/shows/" +
        show_id +
        "/elements/" +
        page +
        "/",
      options: {
        method: "PUT",
        headers: {
          "content-type": "application/vnd.vizrt.payload+xml;type=element",
        },
        body: xml,
      },
    });
  }, []);

  const takeVizPage = useCallback(async ({ page }) => {
    if (!show_ID) {
      getShow();
    }
    let show_id = show_ID;
    return MSECommand({
      url: "http://" + mse + ":8580/profiles/" + vizProfile + "/take",
      options: {
        method: "POST",
        headers: {
          "content-type": "text/plain",
        },
        body:
          "http://" +
          mse +
          ":8580/element/storage/shows/" +
          show_id +
          "/elements/" +
          page,
      },
    });
  }, []);

  const takeOutVizPage = useCallback(async ({ page }) => {
    if (!show_ID) {
      getShow();
    }
    let show_id = show_ID;
    return MSECommand({
      url: "http://" + mse + ":8580/profiles/" + vizProfile + "/out",
      options: {
        method: "POST",
        headers: {
          "content-type": "text/plain",
        },
        body:
          "http://" +
          mse +
          ":8580/element/storage/shows/" +
          show_id +
          "/elements/" +
          page,
      },
    });
  }, []);

  const createPage = useCallback(
    async ({ page, data, summary, escape = false }) => {
      if (!show_ID) {
        getShow();
      }
      let show_id = show_ID;
      let xml = toXML.parse(data);
      if (escape) {
        xml = xml.replace(/&/g, "&amp;");
      }
      return MSECommand({
        url:
          "http://" + mse + ":8580/element_collection/storage/shows/" + show_id,
        options: {
          method: "POST",
          headers: {
            "content-type": "application/vnd.vizrt.payload+xml;type=element",
            slug: page,
          },
          body: xml,
        },
      });
    },
    []
  );

  const setPageSummary = useCallback(
    async ({ page, summary, escape = false }) => {
      if (!show_ID) {
        getShow();
      }
      let show_id = show_ID;

      return MSECommand({
        url:
          "http://" +
          mse +
          ":8580/element_collection/storage/shows/" +
          show_id +
          "/elements/" +
          page,
        options: {
          method: "GET",
        },
      }).then((data) => {
        data.entry.summary = summary;
        let xml = toXML.parse(data);
        if (escape) {
          xml = xml.replace(/&/g, "&amp;");
        }

        console.log("setPageSummary:", page, summary);

        return MSECommand({
          url:
            "http://" +
            mse +
            ":8580/element_collection/storage/shows/" +
            show_id +
            "/elements/" +
            page,
          options: {
            method: "PUT",
            headers: {
              "content-type": "application/atom+xml",
            },
            body: xml,
          },
        });
      });
    },
    []
  );

  function vizClearPreview() {
    previewTrio("page:read 9999");
  }

  return (
    <VizContext.Provider
      value={{
        sendData,
        wsStatus,
        previewTrio,
        MSECommand,
        getVizPage,
        updateVizPage,
        takeVizPage,
        takeOutVizPage,
        vizSharedMemory,
        showID,
        hyperLink,
        setHyperLink,
        mse,
        setMSE,
        trio,
        setTrio,
        setVizEngine,
        vizEngine,
        trioShow,
        setTrioShow,
        vizProfile,
        setVizProfile,
        vizClearPreview,
        vizCommand,
        vizEngineSharedMemory,
        setVizEngineSharedMemory,
        createPage,
        setPageSummary,
        vizEngineStatus,
        vizEngineSharedMemoryStatus,
        trioStatus,
      }}
    >
      {props.children}
    </VizContext.Provider>
  );
};

export { VizContext, VizControlProvider };
