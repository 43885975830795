import React, { useContext, useEffect, useState } from "react";
import Game from "../Fruit/Game";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../Fruit/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import { APIContext } from "../../contexts/APIContext";
import OPTATeamStats from "../TeamStats/OPTATeamStats";
import OPTATeamStatsFruit from "../TeamStats/OPTATeamStatsFruit";
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
          image
          stories
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id
        shirt_number
        opta_shirt_number
        position
        player {
          _id
          first_name
          last_name
          first_name_upper
          last_name_upper
          nationality {
            _id
            opta_code
            opta_name
          }
          image
          stories
          opta_ID
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      stadium {
        opta_name
        name
      }
      competition {
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        name
        code
        variables
      }
      away_team {
        _id
        name

        code
        variables
      }
      live_data
      home_phase
      away_phase
      metres_gained
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_phase
      away_phase
      metres_gained
      home_player_stats
      away_player_stats
      home_team_stats
      away_team_stats
      opta_home_player_stats
      opta_away_player_stats
      opta_home_team_stats
      opta_away_team_stats
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  margin-top: 10px;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #202731;
  background-color: #1c222b;
  height: 100%;
  font-size: ${(props) => (props?.small ? "17px" : "19px")};
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Half = styled.div`
  width: 50%;
  display: flex;
  justify-content: ${(props) => (props?.right ? "flex-start" : "flex-end")};
`;
const Score = styled.div`
  margin-left: 40px;
  margin-right: 40px;
`;

const Name = styled.div`
  display: flex;
`;
const Stat = styled.div`
  display: flex;
  margin-left: 20px;
`;
export default function SkyFruit({ setSelectedPlayer, selectedPlayer }) {
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID);
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;

    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  if (!game) return <div>Loading</div>;
  let score = getScore(game);
  let home_totals = [];

  [...(game?.home_scorers || [])]
    .sort((a, b) => a.player - b.player)
    .forEach((player) => {
      let index = home_totals.findIndex((p) => p.player === player.player);
      let time =
        player.period === 1
          ? Math.min(40, player?.game_time_minutes + 1)
          : player.period === 2
          ? Math.min(80, player?.game_time_minutes + 1)
          : player?.game_time_minutes + 1;
      if (index > -1) {
        home_totals[index].count += 1;
        if (player?.type === "try" || player?.type === "penalty_try") {
          home_totals[index].tries.push({
            time: time,
            period: player?.period,
            penalty: player?.type === "penalty_try",
          });
        }
        if (player?.type === "conversion" || player?.type === "penalty_kick") {
          home_totals[index].goals.push({
            time: time,
            period: player?.period,
            penalty: player?.type === "penalty_try",
          });
        }
        if (player?.type === "drop_goal") {
          home_totals[index].drop_goals.push({
            time: time,
            period: player?.period,
            penalty: player?.type === "penalty_try",
          });
        }
      } else {
        if (player?.type === "try" || player?.type === "penalty_try") {
          home_totals.push({
            player: player.player,
            count: 1,
            goals: [],
            drop_goals: [],
            tries: [
              {
                time: time,
                period: player?.period,
                penalty: player?.type === "penalty_try",
              },
            ],
          });
        }
        if (player?.type === "conversion" || player?.type === "penalty_kick") {
          home_totals.push({
            player: player.player,
            count: 1,
            tries: [],
            drop_goals: [],
            goals: [
              {
                time: time,
                period: player?.period,
                penalty: player?.type === "penalty_try",
              },
            ],
          });
        }
        if (player?.type === "drop_goal") {
          home_totals.push({
            player: player.player,
            count: 1,
            tries: [],
            drop_goals: [],
            goals: [
              {
                time: time,
                period: player?.period,
                penalty: player?.type === "penalty_try",
              },
            ],
          });
        }
      }
    });

  let away_totals = [];

  [...(game?.away_scorers || [])]
    .sort((a, b) => a.player - b.player)
    .forEach((player) => {
      let index = away_totals.findIndex((p) => p.player === player.player);
      let time =
        player.period === 1
          ? Math.min(40, player?.game_time_minutes + 1)
          : player.period === 2
          ? Math.min(80, player?.game_time_minutes + 1)
          : player?.game_time_minutes + 1;
      if (index > -1) {
        away_totals[index].count += 1;
        if (player?.type === "try" || player?.type === "penalty_try") {
          away_totals[index].tries.push({
            time: time,
            period: player?.period,
            penalty: player?.type === "penalty_try",
          });
        }
        if (player?.type === "conversion" || player?.type === "penalty_kick") {
          away_totals[index].goals.push({
            time: time,
            period: player?.period,
            penalty: player?.type === "penalty_try",
          });
        }
        if (player?.type === "drop_goal") {
          away_totals[index].drop_goals.push({
            time: time,
            period: player?.period,
            penalty: player?.type === "penalty_try",
          });
        }
      } else {
        if (player?.type === "try" || player?.type === "penalty_try") {
          away_totals.push({
            player: player.player,
            count: 1,
            goals: [],
            drop_goals: [],
            tries: [
              {
                time: time,
                period: player?.period,
                penalty: player?.type === "penalty_try",
              },
            ],
          });
        }
        if (player?.type === "conversion" || player?.type === "penalty_kick") {
          away_totals.push({
            player: player.player,
            count: 1,
            tries: [],
            drop_goals: [],
            goals: [
              {
                time: time,
                period: player?.period,
                penalty: player?.type === "penalty_try",
              },
            ],
          });
          if (player?.type === "drop_goals") {
            away_totals.push({
              player: player.player,
              count: 1,
              tries: [],
              drop_goals: [],
              goals: [
                {
                  time: time,
                  period: player?.period,
                  penalty: player?.type === "penalty_try",
                },
              ],
            });
          }
        }
      }
    });

  return (
    game && (
      <Main>
        <Content>
          <Route path="/:gameID">
            <Row>
              <OPTATeamStatsFruit game={game} />
              <Column>
                <Row>
                  <ActionPanel>
                    <Half>
                      {game?.home_team?.name}
                      <Score>{score?.home_score}</Score>
                    </Half>
                    -
                    <Half right>
                      <Score>{score?.away_score}</Score>
                      {game?.away_team?.name}
                    </Half>
                  </ActionPanel>
                </Row>

                <ActionPanel>
                  <Half>
                    <Column
                      style={{
                        alignItems: "center",
                      }}
                    >
                      {home_totals.map((scorer, index) => {
                        let scorer_player = game.lineup?.home?.find(
                          (l) => l.squad?._id === scorer?.player
                        );

                        let output =
                          scorer_player?.squad?.player?.short_name ||
                          scorer_player?.squad?.player?.last_name ||
                          "";
                        output +=
                          scorer?.tries?.length > 0
                            ? " (" + scorer?.tries?.length + "T)"
                            : "";
                        output +=
                          scorer?.goals?.length > 0
                            ? " (" + scorer?.goals?.length + "G)"
                            : "";
                        output +=
                          scorer?.drop_goals?.length > 0
                            ? " (" + scorer?.drop_goals?.length + "DG)"
                            : "";
                        return <div>{output}</div>;
                      })}
                    </Column>
                  </Half>
                  <Half>
                    <Column
                      style={{
                        alignItems: "center",
                      }}
                    >
                      {away_totals.map((scorer, index) => {
                        let scorer_player = game.lineup?.away?.find(
                          (l) => l.squad?._id === scorer?.player
                        );

                        let output =
                          scorer_player?.squad?.player?.short_name ||
                          scorer_player?.squad?.player?.last_name ||
                          "";
                        output +=
                          scorer?.tries?.length > 0
                            ? " (" + scorer?.tries?.length + "T)"
                            : "";
                        output +=
                          scorer?.goals?.length > 0
                            ? " (" + scorer?.goals?.length + "G)"
                            : "";
                        output +=
                          scorer?.drop_goals?.length > 0
                            ? " (" + scorer?.drop_goals?.length + "DG)"
                            : "";
                        return <div>{output}</div>;
                      })}
                    </Column>
                  </Half>
                </ActionPanel>

                <StatGroup name="Tackles" variable="tackles" game={game} />
                <StatGroup name="Metres" variable="metres" game={game} />
                <StatGroup name="Offloads" variable="offload" game={game} />
                <StatGroup name="Carries" variable="carries" game={game} />
              </Column>
            </Row>
          </Route>
        </Content>
      </Main>
    )
  );
}

function StatGroup({ name, variable, game }) {
  return (
    <ActionPanel small>
      <Half>
        <Column
          style={{
            alignItems: "center",
          }}
        >
          {[...(game?.opta_home_player_stats || [])]
            ?.sort((a, b) => {
              return b?.stats?.[variable] - a?.stats?.[variable];
            })
            ?.slice(0, 5)
            ?.map((stat) => {
              let player = game?.lineup?.home?.find(
                (p) => p?.squad?.player?.opta_ID === stat?.player
              );

              return (
                <Row>
                  <Name>{player?.squad?.player?.last_name}</Name>
                  <Stat>{stat?.stats?.[variable]}</Stat>
                </Row>
              );
            })}
        </Column>
      </Half>
      {name}
      <Half>
        <Column
          style={{
            alignItems: "center",
          }}
        >
          {[...(game?.opta_away_player_stats || [])]
            ?.sort((a, b) => {
              return b?.stats?.[variable] - a?.stats?.[variable];
            })
            ?.slice(0, 5)
            ?.map((stat) => {
              let player = game?.lineup?.away?.find(
                (p) => p?.squad?.player?.opta_ID === stat?.player
              );

              return (
                <Row>
                  <Name>{player?.squad?.player?.last_name}</Name>
                  <Stat>{stat?.stats?.[variable]}</Stat>
                </Row>
              );
            })}
        </Column>
      </Half>
    </ActionPanel>
  );
}

function getScore(game) {
  let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
  let conversion_points =
    game?.competition?.competition_type === "union" ? 2 : 2;
  let penalty_kick_points =
    game?.competition?.competition_type === "union" ? 3 : 2;
  let penalty_try_points =
    game?.competition?.competition_type === "union" ? 7 : 4;
  let drop_goal_points =
    game?.competition?.competition_type === "union" ? 3 : 1;

  let home_score =
    (game?.home_scorers?.filter((g) => g.type === "try").length * try_points ||
      0) +
    (game?.home_scorers?.filter((g) => g.type === "conversion").length *
      conversion_points || 0) +
    (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
      penalty_kick_points || 0) +
    (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
      penalty_try_points || 0) +
    (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
      drop_goal_points || 0);
  let home_tries =
    game?.home_scorers?.filter((g) => g.type === "try").length || 0;
  let home_conv =
    game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
  let home_pen_kick =
    game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
  let home_pen_try =
    game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
  let home_drop_goal =
    game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
  let away_score =
    (game?.away_scorers?.filter((g) => g.type === "try").length * try_points ||
      0) +
    (game?.away_scorers?.filter((g) => g.type === "conversion").length *
      conversion_points || 0) +
    (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
      penalty_kick_points || 0) +
    (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
      penalty_try_points || 0) +
    (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
      drop_goal_points || 0);
  let away_tries =
    game?.away_scorers?.filter((g) => g.type === "try").length || 0;
  let away_conv =
    game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
  let away_pen_kick =
    game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
  let away_pen_try =
    game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
  let away_drop_goal =
    game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
  return {
    home_score,
    away_score,
    home_tries,
    home_conv,
    home_pen_kick,
    home_pen_try,
    home_drop_goal,
    away_tries,
    away_conv,
    away_pen_kick,
    away_pen_try,
    away_drop_goal,
  };
}
