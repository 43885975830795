export class FFStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "FFStats";
    this.scene = "Name";
    this.data = [];
    this.downloadPage = 7151;
    this.page = 501;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.downloadPage }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "000101";
      }).value = options.game?.home_team?.short_name?.toUpperCase() || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "000102";
      }).value =
        options.game?.home_team?.variables?.alt_badge ||
        options.game?.home_team?.variables?.badge ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "000201";
      }).value = options.game?.away_team?.short_name?.toUpperCase() || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "000202";
      }).value =
        options.game?.away_team?.variables?.alt_badge ||
        options.game?.away_team?.variables?.badge ||
        "";

      let score = this.getScore(options.game);

      xml.payload.field.find((item) => {
        return item.attr.name === "000301";
      }).value = score?.home_score || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "000302";
      }).value = score?.away_score || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0120";
      }).value = "Possession";

      xml.payload.field.find((item) => {
        return item.attr.name === "0121";
      }).value = options?.game?.opta_home_team_stats?.possession || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "01210";
      }).value = "%";

      xml.payload.field.find((item) => {
        return item.attr.name === "0122";
      }).value = options?.game?.opta_away_team_stats?.possession || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "01220";
      }).value = "%";

      xml.payload.field.find((item) => {
        return item.attr.name === "0220";
      }).value = "Completed sets";

      let home_total =
        (parseInt(options?.game?.opta_home_team_stats?.complete_sets) || 0) +
        (parseInt(options?.game?.opta_home_team_stats?.incomplete_sets) || 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "02210";
      }).value =
        "(" +
        Math.floor(
          ((options?.game?.opta_home_team_stats?.complete_sets || 0) /
            home_total) *
            100
        ) +
        "%" +
        ")";

      xml.payload.field.find((item) => {
        return item.attr.name === "0221";
      }).value =
        (options?.game?.opta_home_team_stats?.complete_sets || "0") +
        "/" +
        home_total;

      let away_total =
        (parseInt(options?.game?.opta_away_team_stats?.complete_sets) || 0) +
        (parseInt(options?.game?.opta_away_team_stats?.incomplete_sets) || 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "02220";
      }).value =
        "(" +
        Math.floor(
          ((options?.game?.opta_away_team_stats?.complete_sets || 0) /
            away_total) *
            100
        ) +
        "%" +
        ")";

      xml.payload.field.find((item) => {
        return item.attr.name === "0222";
      }).value =
        (options?.game?.opta_away_team_stats?.complete_sets || "0") +
        "/" +
        away_total;

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0321";
      // }).value = options?.game?.opta_home_team_stats?.carries || 0;

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0322";
      // }).value = options?.game?.opta_away_team_stats?.carries || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0320";
      }).value = "Metres";

      xml.payload.field.find((item) => {
        return item.attr.name === "0321";
      }).value = options?.game?.opta_home_team_stats?.metres || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0322";
      }).value = options?.game?.opta_away_team_stats?.metres || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0420";
      }).value = "Tackles";

      xml.payload.field.find((item) => {
        return item.attr.name === "0421";
      }).value = options?.game?.opta_home_team_stats?.tackles || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0422";
      }).value = options?.game?.opta_away_team_stats?.tackles || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0520";
      }).value = "Missed tackles";

      xml.payload.field.find((item) => {
        return item.attr.name === "0521";
      }).value = options?.game?.opta_home_team_stats?.missed_tackles || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0522";
      }).value = options?.game?.opta_away_team_stats?.missed_tackles || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0620";
      }).value = "Errors";

      xml.payload.field.find((item) => {
        return item.attr.name === "0621";
      }).value = options?.game?.opta_home_team_stats?.errors || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0622";
      }).value = options?.game?.opta_away_team_stats?.errors || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0720";
      }).value = "Penalties conceded";

      xml.payload.field.find((item) => {
        return item.attr.name === "0721";
      }).value = options?.game?.opta_home_team_stats?.penalties || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0722";
      }).value = options?.game?.opta_away_team_stats?.penalties || 0;

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0421";
      // }).value =
      //   Math.floor(
      //     ((options?.game?.opta_home_team_stats?.complete_sets || 0) /
      //       home_total) *
      //       100
      //   ) + "%";

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0422";
      // }).value =
      //   Math.floor(
      //     ((options?.game?.opta_away_team_stats?.complete_sets || 0) /
      //       away_total) *
      //       100
      //   ) + "%";

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }
}
