/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  GiAmericanFootballBall,
  GiRugbyConversion,
  GiSoccerBall,
} from "react-icons/gi";
import { IoIosGitCompare, IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import moment from "moment";
import { ScrollBar } from "../../Theme/Hyper";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";
const Main = styled(ScrollBar)`
  display: flex;
  width: 40%;
  flex-direction: column;
  overflow-y: auto;
  height: 83vh;
`;
const Team = styled.div`
  /* height: 90%; */
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const ShirtNumber = styled.div`
  display: flex;
  justify-content: center;
  min-width: 40px;
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;

const Name = styled.div`
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  width: 100%;
`;
const FirstName = styled.div`
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;
const LastName = styled.div`
  font-weight: 500;
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: ${(props) => (props.padLeft ? "0.5em" : "")};
`;
const Position = styled.div`
  display: flex;
  min-width: 150px;
  padding-left: 0.5em;
`;
const Sub = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Captain = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
`;
const YellowCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #fffe00;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  justify-content: center;
  display: flex;
  font-weight: 400;
`;
const RedCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #ff0034;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  font-weight: 400;
  display: flex;
  justify-content: center;
  color: #fff;
`;

const Cards = styled.div`
  /* margin-left: auto; */
  display: flex;
`;

const Card = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: ${(props) => (props.selected ? "1" : "0.1")};
  :hover {
    opacity: 1;
  }
`;

const Goals = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 10px;
`;
const Goal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;

  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  color: ${(props) => (props.red ? "red" : "white")};
  :hover {
    background-color: #d11e42;
  }
`;
const SubIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.on ? "#0adb93" : props.off ? "#db0a41" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  opacity: ${(props) => (props.on ? "1" : props.off ? "1" : " 0.2")};
  min-width: 30px;
  justify-content: flex-end;
  :hover {
    /* background-color: #d11e42; */

    opacity: 1;
  }
`;
const FootballIcon = styled(GiSoccerBall)`
  margin-right: 2px;
`;
const ConversionIcon = styled(GiRugbyConversion)`
  margin-right: 2px;
`;
const BeenSubbed = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20px;
`;
const Off = styled(IoIosArrowDown)`
  color: #db0a41;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #202731;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0.5em;
  margin-bottom: 1em;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  padding: 0.5em;
  :hover {
    background-color: #db0a41;
  }
`;
const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

export default function Squad({
  team,
  lineup,
  selectPlayer,
  selectedPlayer,
  game,
}) {
  const { getSquad, getCountry, countries } = useContext(APIContext);
  const [squad, setSquad] = useState();

  useEffect(() => {
    getSquad({ teamId: team?._id, seasonId: game.season?._id }).then((data) => {
      setSquad(data);
    });
  }, [team]);

  return (
    <Main>
      <Team>
        {squad &&
          squad
            ?.filter((s) => {
              return (
                lineup?.findIndex((l) => l.squad?._id == s._id) == -1 &&
                s?.type !== "manager" &&
                s?.type !== "coach"
              );
            })
            ?.sort((a, b) => {
              if (a.player?.last_name < b.player?.last_name) {
                return -1;
              }
              if (a.player?.last_name > b.player?.last_name) {
                return 1;
              }
              return 0;
            })
            .map((item, index) => {
              let player = {
                _id: item?._id,
                ...item?.player,
                shirt_number: item?.shirt_number || item?.opta_shirt_number,
                squad: item,
                stories: item?.player?.stories,
              };

              return (
                <>
                  <Row
                    selected={
                      player &&
                      selectedPlayer &&
                      selectedPlayer._id === player._id
                    }
                  >
                    <ShirtNumber>{player && player.shirt_number}</ShirtNumber>

                    <Name
                      onClick={() => {
                        if (
                          !selectedPlayer ||
                          selectedPlayer._id !== player?._id
                        ) {
                          selectPlayer(player);
                        } else {
                          selectPlayer(null);
                        }
                      }}
                    >
                      <FirstName>{player?.first_name}</FirstName>
                      <LastName padLeft={player?.first_name}>
                        {player?.last_name}{" "}
                        <span
                          style={{
                            fontSize: ".7em",
                            fontWeight: "100",
                            alignSelf: "flex-end",
                            marginLeft: ".5em",
                          }}
                        >
                          {player?.nationality?.code ||
                            player?.nationality?.opta_code}
                        </span>
                      </LastName>
                    </Name>
                  </Row>
                </>
              );
            })}
        <Row fade noHover>
          <ShirtNumber></ShirtNumber>
          <Name>
            <FirstName></FirstName>
          </Name>
        </Row>

        {squad &&
          [...(team.variables?.staff || [])]
            ?.sort((a, b) => {
              if (a?.last_name < b?.last_name) {
                return -1;
              }
              if (a?.last_name > b?.last_name) {
                return 1;
              }
              return 0;
            })
            ?.map((player) => {
              player = { ...player, type: "staff", team: team };
              return (
                <Row
                  selected={
                    player &&
                    selectedPlayer &&
                    selectedPlayer.title == player.title &&
                    selectedPlayer.first_name == player.first_name &&
                    selectedPlayer.last_name == player.last_name
                  }
                >
                  <Name
                    onClick={() => {
                      if (
                        !selectedPlayer ||
                        (selectedPlayer.title !== player.title &&
                          selectedPlayer.first_name !== player.first_name &&
                          selectedPlayer.last_name !== player.last_name)
                      ) {
                        selectPlayer(player);
                      } else {
                        selectPlayer(null);
                      }
                    }}
                  >
                    <FirstName>{player && player.title}</FirstName>
                    <FirstName>{player && player.first_name}</FirstName>
                    <LastName padLeft={player && player.first_name}>
                      {player && player.last_name}{" "}
                      {player && player.captain && "(C)"}
                      <span
                        style={{
                          fontSize: ".7em",
                          fontWeight: "100",
                          alignSelf: "flex-end",
                          marginLeft: ".5em",
                        }}
                      >
                        {player &&
                          player.nationality &&
                          player.nationality.code}
                      </span>
                    </LastName>
                  </Name>
                </Row>
              );
            })}
      </Team>
    </Main>
  );
}
