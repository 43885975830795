import moment from "moment";

export class LatestScoresCB {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    createPage,
    setPageSummary,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.createPage = createPage;
    this.setPageSummary = setPageSummary;
    this.name = "LatestScoresCB";
    this.scene = "LatestScoresCB";
    this.data = [];
    this.downloadPage = 7060;
    this.page = 85;
  }

  async preview({ options }) {
    this.onAir = false;

    await this.build(options);

    await this.sendPreview("page:read " + this.page);
  }

  async build(options) {
    let xml = await this.getVizPage({ page: this.downloadPage });

    if (options.push_up) {
      xml.payload.field.find((item) => {
        return item.attr.name === "0000";
      }).value = "1";
      xml.payload.field.find((item) => {
        return item.attr.name === "0001.position";
      }).value = "-312 0 0";
    } else {
      xml.payload.field.find((item) => {
        return item.attr.name === "0000";
      }).value = "0";
      xml.payload.field.find((item) => {
        return item.attr.name === "0001.position";
      }).value = "-343 0 0";
    }

    xml.payload.field.find((item) => {
      return item.attr.name === "0004"; // home team
    }).value = options?.games?.length;

    let caption =
      options?.games?.filter(
        (g) =>
          g?.opta_scores?.status !== "First half" &&
          g?.opta_scores?.status !== "Second half" &&
          g?.opta_scores?.status !== "Result"
      )?.length > 0
        ? "OTHER GAMES"
        : options?.games?.filter((g) => g?.opta_scores?.status !== "Halftime")
            ?.length === 0
        ? "HALF TIME SCORES"
        : options?.games?.filter((g) => g?.opta_scores?.status !== "Result")
            ?.length === 0
        ? "RESULTS"
        : "LATEST SCORES";

    xml.payload.field.find((item) => {
      return item.attr.name === "003"; // home team
    }).value = caption;

    for (let i = 0; i < options?.games?.length; i++) {
      let game = options?.games?.[i];
      let tabrow = (i + 1).toString()?.padStart(2, "0");

      xml.payload.field.find((item) => {
        return item.attr.name === tabrow + "2"; // home team
      }).value =
        game?.home_team?.short_name?.replace("Huddersfield", "Hudd'field") ||
        game?.home_team?.name;

      xml.payload.field.find((item) => {
        return item.attr.name === tabrow + "3"; // home team
      }).value =
        game?.opta_scores?.status === "Fixture"
          ? "v"
          : game?.opta_scores?.status === "Postponed"
          ? "P - P"
          : game?.opta_scores?.status === "Abandoned"
          ? "A - A"
          : game?.opta_scores?.home?.score +
            " - " +
            game?.opta_scores?.away?.score;

      xml.payload.field.find((item) => {
        return item.attr.name === tabrow + "4"; // home team
      }).value =
        game?.away_team?.short_name?.replace("Huddersfield", "Hudd'field") ||
        game?.away_team?.name;
      if (caption === "OTHER GAMES") {
        xml.payload.field.find((item) => {
          return item.attr.name === tabrow + "6"; // home team
        }).value =
          game?.opta_scores?.status === "Halftime"
            ? "HT"
            : game?.opta_scores?.status === "Result"
            ? "FT"
            : game?.opta_scores?.status !== "First half" ||
              game?.opta_scores?.status !== "Second half"
            ? "L"
            : game?.opta_scores?.status !== "First Half Extra" ||
              game?.opta_scores?.status !== "Second Half Extra"
            ? "GP"
            : "";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === tabrow + "6"; // home team
        }).value = "";
      }
    }
    await this.updateVizPage({ page: this.page, data: xml });
  }

  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
