import moment from "moment";

export class GameScoresBug {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    createPage,
    setPageSummary,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.createPage = createPage;
    this.setPageSummary = setPageSummary;
    this.name = "GameScoresBug";
    this.scene = "GameScoresBug";
    this.data = [];
    this.downloadPage = 7009;
    this.page = 150;
    this.buildPage = 150;
  }

  async preview({ options }) {
    this.onAir = false;

    if (!options.build) {
      this.page = options.page;
      options.build = true;
      await this.buildSingle(options);
      await this.sendPreview("page:read " + this.page);
    } else {
      this.page = this.buildPage;
      await this.buildAll(options);
      this.page = this.buildPage;
      await this.sendPreview("page:read " + this.page);
    }
  }

  async buildSingle(options) {
    let xml = await this.getVizPage({ page: this.page });
    if (xml === "") {
      xml = await this.getVizPage({ page: this.downloadPage });
    }

    let text =
      (options?.game?.home_team?.short_name?.replace(
        "Huddersfield",
        "Hudd'field"
      ) ||
        options?.game?.home_team?.name ||
        "") +
      " " +
      options?.game?.opta_scores?.home?.score +
      "-" +
      options?.game?.opta_scores?.away?.score +
      " " +
      (options?.game?.away_team?.short_name?.replace(
        "Huddersfield",
        "Hudd'field"
      ) ||
        options?.game?.away_team?.name ||
        "") +
      " - " +
      (options?.game?.opta_scores?.status === "Halftime"
        ? "Half Time"
        : options?.game?.opta_scores?.status === "First half" ||
          options?.game?.opta_scores?.status === "Second half"
        ? "Latest"
        : options?.game?.opta_scores?.status === "Result"
        ? "Full Time"
        : "");
    xml.payload.field.find((item) => {
      return item.attr.name === "201"; // home team
    }).value =
      options?.game?.home_team?.short_name?.replace(
        "Huddersfield",
        "Hudd'field"
      ) ||
      options?.game?.home_team?.name ||
      "";

    xml.payload.field.find((item) => {
      return item.attr.name === "204"; // home team
    }).value =
      options?.game?.opta_scores?.status === "Postponed"
        ? "P"
        : options?.game?.opta_scores?.status === "Abandoned"
        ? "A"
        : options?.game?.opta_scores?.home?.score;

    xml.payload.field.find((item) => {
      return item.attr.name === "205"; // home team
    }).value =
      options?.game?.opta_scores?.status === "Postponed"
        ? "P"
        : options?.game?.opta_scores?.status === "Abandoned"
        ? "A"
        : options?.game?.opta_scores?.away?.score;

    xml.payload.field.find((item) => {
      return item.attr.name === "206"; // home team
    }).value =
      options?.game?.away_team?.short_name?.replace(
        "Huddersfield",
        "Hudd'field"
      ) ||
      options?.game?.away_team?.name ||
      "";

    xml.payload.field.find((item) => {
      return item.attr.name === "301"; // home team
    }).value =
      options?.game?.opta_scores?.status === "Halftime"
        ? "Half Time"
        : options?.game?.opta_scores?.status === "First half" ||
          options?.game?.opta_scores?.status === "Second half"
        ? "Latest"
        : options?.game?.opta_scores?.status === "Result"
        ? "Full Time"
        : "";
    if (options?.game?.opta_scores?.status === "Result") {
      xml.payload.field.find((item) => {
        return item.attr.name === "0003"; // home team
      }).value = "0";
    } else {
      xml.payload.field.find((item) => {
        return item.attr.name === "0003"; // home team
      }).value = "1";
    }

    if (options.build) {
      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).then(async (data) => {
        if (data === "") {
          await this.createPage({
            page: this.page,
            data: xml,
          });
        }
        this.setPageSummary({
          page: this.page,
          summary: text,
        });
      });
    } else {
      await this.updateVizPage({ page: this.page, data: xml, escape: false });
    }
  }

  async buildAll(options) {
    for (let i = 0; i < options?.games?.length; i++) {
      this.page = this.buildPage + i;
      let game = options?.games?.[i];
      await this.buildSingle({ game, build: true });
    }
  }

  animate({ options }) {
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
