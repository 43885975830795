export class DoubleLineupSubs {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Double Lineup Subs";
    this.scene = "Double Lineup Subs";
    this.data = [];
    this.page = 1;
  }

  preview({ options }) {
    if (options.start) {
      this.page = 1;
    }
    this.onAir = false;
    this.data = [];
    this.options = options;
    this.data.push({
      name: "Title",
      value: {
        text: "STARTING",
      },
    });
    this.buildTeam({
      type: "Home",
      rugby_type: "",
      team: options?.home_team,
      squad: options?.home_squad,
      manager: options?.home_manager,
    });
    this.buildTeam({
      type: "Away",
      rugby_type: "",
      team: options?.away_team,
      squad: options?.away_squad,
      manager: options?.away_manager,
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  buildTeam({ type = "Home", manager, squad, rugby_type, team }) {
    this.data.push({
      name: type + " Manager First Name Uppercase",
      value: {
        text: manager?.player?.first_name_upper || "",
      },
    });

    this.data.push({
      name: type + " Manager Last Name Uppercase",
      value: {
        text: manager?.player?.last_name_upper || "",
      },
    });

    this.data.push({
      name: type + " Manager Full Name",
      value: {
        text:
          (manager?.player?.first_name_upper || "") +
          " " +
          (manager?.player?.last_name_upper || ""),
      },
    });

    this.data.push({
      name: type + " Manager Title",
      value: {
        text: manager?.player?.title || "",
      },
    });

    new Array(23).fill(1).forEach((_, index) => {
      let row = index + 1;

      let player = squad?.find((s) => s.order === row);

      this.data.push({
        name: type + " First Name " + row,
        value: {
          text: player?.squad?.player?.first_name || "",
        },
      });
      this.data.push({
        name: type + " Last Name " + row,
        value: {
          text: player?.squad?.player?.last_name || "",
        },
      });
      this.data.push({
        name: type + " First Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.first_name_upper || "",
        },
      });
      this.data.push({
        name: type + " Last Name Uppercase " + row,
        value: {
          text: player?.squad?.player?.last_name_upper || "",
        },
      });
      this.data.push({
        name: type + " Full Name " + row,
        value: {
          text:
            (player?.squad?.player?.first_name || "") +
            " " +
            (player?.squad?.player?.last_name || ""),
        },
      });
      this.data.push({
        name: type + " Full Name Uppercase " + row,
        value: {
          text:
            (player?.squad?.player?.first_name_upper || "") +
            " " +
            (player?.squad?.player?.last_name_upper || ""),
        },
      });
      this.data.push({
        name: type + " Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: type + " Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: type + " Shirt Number " + row,
        value: {
          text:
            (rugby_type === "union"
              ? player?.order
              : player?.squad?.shirt_number) || "",
        },
      });
    });

    this.data.push({
      name: type + " Team Name",
      value: {
        text: team?.name,
      },
    });

    this.data.push({
      name: type + " Team Badge",
      value: {
        image: "${user}" + team?.variables?.badge,
      },
    });

    this.data.push({
      name: type + " Team Secondary Badge",
      value: {
        image: "${user}" + team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: type + " Team Video Badge",
      value: {
        video: team?.variables?.video_badge,
      },
    });
  }
  animate({ options }) {
    this.onAir = true;
    this.onAir_scene = this.scene;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.onAir_scene, []);
  }
}
