import moment from "moment";

export class Officials {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Officials";
    this.scene = "Officials";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Secondary Badge",
      value: {
        image: "${user}" + options.game?.competition?.secondary_badge || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.name || "" },
    });
    this.data.push({
      name: "Kick-Off",
      value: {
        text:
          moment(options.game?.time, [moment.ISO_8601, "HH:mm"]).format(
            "HH:mm"
          ) || "",
      },
    });
    this.data.push({
      name: "Referee Official First Name",
      value: { text: options.referee?.first_name || "" },
    });
    this.data.push({
      name: "Referee Official Last Name",
      value: { text: options.referee?.last_name || "" },
    });
    this.data.push({
      name: "Referee Official Full Name",
      value: {
        text:
          (options.referee?.first_name + " " || "") +
          (options.referee?.last_name || ""),
      },
    });
    this.data.push({
      name: "Assistant 1 Official First Name",
      value: { text: options.assistant_1?.first_name || "" },
    });
    this.data.push({
      name: "Assistant 1 Official Last Name",
      value: { text: options.assistant_1?.last_name || "" },
    });
    this.data.push({
      name: "Assistant 1 Official Full Name",
      value: {
        text:
          (options.assistant_1?.first_name + " " || "") +
          (options.assistant_1?.last_name || ""),
      },
    });
    this.data.push({
      name: "Assistant 2 Official First Name",
      value: { text: options.assistant_2?.first_name || "" },
    });
    this.data.push({
      name: "Assistant 2 Official Last Name",
      value: { text: options.assistant_2?.last_name || "" },
    });
    this.data.push({
      name: "Assistant 2 Official Full Name",
      value: {
        text:
          (options.assistant_2?.first_name + " " || "") +
          (options.assistant_2?.last_name || ""),
      },
    });

    this.data.push({
      name: "TMO Official First Name",
      value: { text: options.tmo?.first_name || "" },
    });
    this.data.push({
      name: "TMO Official Last Name",
      value: { text: options.tmo?.last_name || "" },
    });
    this.data.push({
      name: "TMO Official Full Name",
      value: {
        text:
          (options.tmo?.first_name + " " || "") +
          (options.tmo?.last_name || ""),
      },
    });
    this.sendPreview(this.scene, 100, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
