import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { DataContext } from "../../contexts/DataContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import Input from "../UI/Form/Input";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  height: 100px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#202731"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 1em;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const PopoutButton = styled(Button)`
  padding: 1em;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#202731"};
    color: ${(props) => (props.green ? "#1d1d1d" : "")};
  }
`;
const Row = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export default function ClockEdit({ game }) {
  const { updateGame } = useContext(APIContext);
  const { updateGame: updateGameData, update } = useContext(DataContext);
  const { updateGraphic } = useContext(GraphicsContext);
  const history = useHistory();
  const { popout, setPopout } = useContext(UIContext);
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  return (
    <Main>
      <ActionPanel>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [{ kick_off: null, number: 1 }];
            updateGraphic("CLOCK", {
              game: game,
              period: { kick_off: null, number: 1 },
              action: "PERIOD",
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock 00:00
        </Button>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 2, kick_off: null },
              action: "PERIOD",
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock 40:00
        </Button>

        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
              { number: 3, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 3, kick_off: null },
              action: "PERIOD",
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock GP1
        </Button>

        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
              { number: 3, kick_off: null },
              { number: 4, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 4, kick_off: null },
              action: "PERIOD",
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock GP2
        </Button>

        {period?.kick_off && (
          <>
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );
                let latestPause =
                  newData.live_data.periods[index].pause &&
                  newData.live_data.periods[index].pause[
                    newData.live_data.periods[index].pause.length - 1
                  ];
                if (latestPause && latestPause.end) {
                  latestPause = null;
                }

                if (latestPause) {
                  latestPause.start -= 60000;
                  updateGraphic("CLOCK", {
                    type: "PAUSE",
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                } else {
                  newData.live_data.periods[index].kick_off += 60000;
                  updateGraphic("CLOCK", {
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                }
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              -1 Min
            </Button>

            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );
                let latestPause =
                  newData.live_data.periods[index].pause &&
                  newData.live_data.periods[index].pause[
                    newData.live_data.periods[index].pause.length - 1
                  ];
                if (latestPause && latestPause.end) {
                  latestPause = null;
                }

                if (latestPause) {
                  latestPause.start += 60000;
                  updateGraphic("CLOCK", {
                    type: "PAUSE",
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                } else {
                  newData.live_data.periods[index].kick_off -= 60000;
                  updateGraphic("CLOCK", {
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                }
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              +1 Min
            </Button>

            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );

                let latestPause =
                  newData.live_data.periods[index].pause &&
                  newData.live_data.periods[index].pause[
                    newData.live_data.periods[index].pause.length - 1
                  ];
                if (latestPause && latestPause.end) {
                  latestPause = null;
                }

                if (latestPause) {
                  latestPause.start -= 1000;
                  updateGraphic("CLOCK", {
                    type: "PAUSE",
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                } else {
                  newData.live_data.periods[index].kick_off += 1000;
                  updateGraphic("CLOCK", {
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                }

                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              -1 Sec
            </Button>
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let index = newData.live_data.periods.findIndex(
                  (p) => p.number === period.number
                );

                let latestPause =
                  newData.live_data.periods[index].pause &&
                  newData.live_data.periods[index].pause[
                    newData.live_data.periods[index].pause.length - 1
                  ];
                if (latestPause && latestPause.end) {
                  latestPause = null;
                }

                if (latestPause) {
                  latestPause.start += 1000;
                  updateGraphic("CLOCK", {
                    type: "PAUSE",
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                } else {
                  newData.live_data.periods[index].kick_off -= 1000;
                  updateGraphic("CLOCK", {
                    game: newData,
                    period: newData.live_data.periods[index],
                  });
                }

                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              +1 Sec
            </Button>
          </>
        )}
      </ActionPanel>
      <ActionPanel>
        <Button
          onClick={() => {
            updateGame({
              id: game._id,
              live_data: { periods: [{ kick_off: null, number: 1 }] },
              lineup: { home: [], away: [] },
              home_scorers: [],
              away_scorers: [],
            });
          }}
        >
          RESET GAME
        </Button>
        <Button
          onClick={() => {
            updateGame({
              id: game._id,
              lineup: {
                home: game?.lineup?.home?.map((player) => {
                  return {
                    ...player,
                    sinbins: [],
                    yellow_card: false,
                    red_card: false,
                  };
                }),
                away: game?.lineup?.away?.map((player) => {
                  return {
                    ...player,
                    sinbins: [],
                    yellow_card: false,
                    red_card: false,
                  };
                }),
              },
            });
          }}
        >
          RESET CARDS
        </Button>
        <Button
          onClick={() => {
            updateGame({
              id: game._id,
              home_scorers: [],
              away_scorers: [],
            });
          }}
        >
          RESET POINTS
        </Button>
      </ActionPanel>
      <ActionPanel style={{ padding: "1em", boxSizing: "content-box" }}>
        <Column>
          Clock popout
          <Row>
            <Input
              style={{
                backgroundColor: "#0e1219",
                width: "400px",
                color: "#fff",
              }}
              placeholder={"text value"}
              value={popout?.find((p) => p?.game_id === game?._id)?.text}
              onChange={(e) => {
                let new_popout = {
                  ...popout?.find((p) => p?.game_id === game?._id),
                } ?? { game_id: game?._id };
                new_popout.game_id = game?._id;
                new_popout.text = e.currentTarget.value;
                let new_popouts = [
                  ...popout.filter((p) => p?.game_id !== game?._id),
                ];
                new_popouts.push(new_popout);
                setPopout(new_popouts);
              }}
            />
            <PopoutButton
              green={
                popout?.find((p) => p?.game_id === game?._id)?.type === "home"
              }
              onClick={() => {
                let new_popout = {
                  ...popout?.find((p) => p?.game_id === game?._id),
                } ?? { game_id: game?._id };
                new_popout.game_id = game?._id;
                new_popout.type = "home";
                let new_popouts = [
                  ...popout.filter((p) => p?.game_id !== game?._id),
                ];
                new_popouts.push(new_popout);
                setPopout(new_popouts);
              }}
            >
              Home
            </PopoutButton>
            <PopoutButton
              green={
                popout?.find((p) => p?.game_id === game?._id)?.type === "away"
              }
              onClick={() => {
                let new_popout = {
                  ...popout?.find((p) => p?.game_id === game?._id),
                } ?? { game_id: game?._id };
                new_popout.game_id = game?._id;
                new_popout.type = "away";
                let new_popouts = [
                  ...popout.filter((p) => p?.game_id !== game?._id),
                ];
                new_popouts.push(new_popout);
                setPopout(new_popouts);
              }}
            >
              Away
            </PopoutButton>
            <PopoutButton
              green={
                popout?.find((p) => p?.game_id === game?._id)?.type ===
                "generic"
              }
              onClick={() => {
                let new_popout = {
                  ...popout?.find((p) => p?.game_id === game?._id),
                } ?? { game_id: game?._id };
                new_popout.game_id = game?._id;
                new_popout.type = "generic";
                let new_popouts = [
                  ...popout.filter((p) => p?.game_id !== game?._id),
                ];
                new_popouts.push(new_popout);
                setPopout(new_popouts);
              }}
            >
              Generic
            </PopoutButton>
            <PopoutButton
              green={
                popout?.find((p) => p?.game_id === game?._id)?.type ===
                  "none" || !popout?.find((p) => p?.game_id === game?._id)
              }
              onClick={() => {
                let new_popout = {
                  ...popout?.find((p) => p?.game_id === game?._id),
                } ?? { game_id: game?._id };
                new_popout.game_id = game?._id;
                new_popout.type = "none";
                let new_popouts = [
                  ...popout.filter((p) => p?.game_id !== game?._id),
                ];
                new_popouts.push(new_popout);
                setPopout(new_popouts);
              }}
            >
              None
            </PopoutButton>
          </Row>
        </Column>
      </ActionPanel>
    </Main>
  );
}
