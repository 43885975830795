import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
const Main = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.3em;
  margin-top: 0.5em;
  padding: 5px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
  font-size: 0.73em;
`;

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
function CornerBoxes({ game }) {
  const [view, setView] = useState("game");
  const [cornerBoxes, setCornerBoxes] = useState([]);
  const { getCornerBoxes } = useContext(APIContext);
  const { previewGraphic, graphics } = useContext(GraphicsContext);
  useEffect(() => {
    getCornerBoxes().then((data) => {
      setCornerBoxes(data);
    });
  }, [game]);

  return (
    <Main>
      <Buttons>
        <NavButton
          selected={view === "game"}
          onClick={() => {
            setView("game");
          }}
        >
          GAME
        </NavButton>
        <NavButton
          selected={view === "shared"}
          onClick={() => {
            setView("shared");
          }}
        >
          SHARED
        </NavButton>
      </Buttons>
      {JSON.parse(JSON.stringify(cornerBoxes ?? []))
        ?.sort((a, b) => {
          return a?.number - b?.number;
        })
        ?.filter((cb) => {
          if (
            view === "game" &&
            cb?.type === "game" &&
            cb.game?._id === game?._id
          ) {
            return true;
          }
          if (view === "shared" && cb?.type === "shared") {
            return true;
          }
          return false;
        })
        ?.map((cb) => {
          return (
            <GraphicButton
              onClick={() => {
                previewGraphic("CBMANUAL", {
                  box: cb,
                  push_up:
                    graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                    graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                });
              }}
            >
              {cb?.name}
            </GraphicButton>
          );
        })}
    </Main>
  );
}

export default CornerBoxes;
