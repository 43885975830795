import moment from "moment";

export class CBSeasonStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CBSEASONSTATS";
    this.scene = "CBSEASONSTATS";
    this.data = [];
    this.page = 7058;
  }

  async preview({ options }) {
    this.onAir = false;

    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }
      debugger;
      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = options?.players?.length;

      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value = options?.category;

      for (let i = 0; i < options?.players?.length; i++) {
        let player = options?.players[i];
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (i + 1) + "10";
        }).value = player.team?.variables?.badge || "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (i + 1) + "21";
        }).value =
          player?.squad?.player?.first_name +
          " " +
          player?.squad?.player?.last_name;
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (i + 1) + "22";
        }).value = player.value;
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
