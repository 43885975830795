import moment from "moment";

export class CBSinBin {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CBSINBIN";
    this.scene = "CBSINBIN";
    this.data = [];
    this.page = 7051;
  }

  async preview({ options }) {
    this.onAir = false;

    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      let game_mins =
        options?.clockType === "manual"
          ? parseInt(document.getElementById("manual_minutes").innerText)
          : parseInt(document.getElementById("stadium_minutes").innerText);

      let game_secs =
        options?.clockType === "manual"
          ? parseInt(document.getElementById("manual_seconds").innerText)
          : parseInt(document.getElementById("stadium_seconds").innerText);

      let active_players = options?.game?.lineup?.home
        ?.filter((p) => p.yellow_card)
        ?.map((p) => {
          return { ...p, team: options?.game?.home_team };
        })
        ?.concat(
          options?.game?.lineup?.away
            ?.filter((p) => p.yellow_card)
            ?.map((p) => {
              return { ...p, team: options?.game?.away_team };
            })
        )
        ?.map((p) => {
          let duration = moment.duration({
            minutes: game_mins,
            seconds: game_secs,
          });

          let duration_2 = moment.duration({
            minutes: p?.sinbins?.at(-1)?.game_time_minutes,
            seconds: p?.sinbins?.at(-1)?.game_time_seconds,
          });

          let total_sinbin = moment.duration({ minutes: 10 });

          let diff = total_sinbin.subtract(duration.subtract(duration_2));

          let future = moment().add(diff);

          return { ...p, time_remaining: future };
        })
        ?.sort((a, b) => {
          return a?.time_remaining?.valueOf() - b?.time_remaining?.valueOf();
        });

      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = active_players?.length || "";

      for (let i = 0; i < active_players?.length; i++) {
        let player = active_players?.[i];
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (i + 1) + "2";
        }).value = player.team?.variables?.badge || "";
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (i + 1) + "3";
        }).value =
          player.squad?.player?.first_name +
          " " +
          player.squad?.player?.last_name;
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (i + 1) + "5";
        }).value = player?.time_remaining?.format("HH|mm|ss|DD|MM|YYYY");
      }
      debugger;
      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
