export class TwoSubstitution {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TwoSubstitution";
    this.scene = "TwoSubstitution";
    this.data = [];
    this.page = 10000;
  }

  async preview({ options }) {}
  animate({ options }) {
    let subs = options.subs
      ?.map(
        (s) =>
          s?.players?.[0]?.squad?.shirt_number +
          "," +
          s?.players?.[0]?.squad?.player?.first_name?.[0] +
          ". " +
          s?.players?.[0]?.squad?.player?.last_name +
          "," +
          s?.players?.[1]?.squad?.shirt_number +
          "," +
          s?.players?.[1]?.squad?.player?.first_name?.[0] +
          ". " +
          s?.players?.[1]?.squad?.player?.last_name
      )
      ?.join(",");

    let team = options?.game?.home_team?._id === options?.team?._id ? "1" : "2";

    let string =
      "SMMSystem_SetValue|SHMSend_InterChange|" +
      team +
      ",2," +
      options.remaining +
      "," +
      options.total +
      "," +
      subs;

    this.vizSharedMemory({
      shared_memory: string,
    });
  }

  animateOff({ options }) {
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_InterChange|REG_InterChangeOut",
    });
  }

  update({ options }) {}
}
