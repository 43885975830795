import moment from "moment";

export class FFScore {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "FFScore";
    this.scene = "FFScore";
    this.data = [];
    this.download_page = 7150;
    this.page = 500;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      let score = this.getScore(options.game);
      let ht_score = this.getHTScore(options.game);
      xml.payload.field.find((item) => {
        return item.attr.name === "00015";
      }).value =
        options.half === 1
          ? "Half Time"
          : options.half === 2
          ? "Full Time"
          : "Full Time (after Golden Point Extra Time)";

      xml.payload.field.find((item) => {
        return item.attr.name === "1210";
      }).value = options.game?.attendance || "";

      if (options.half === 2) {
        xml.payload.field.find((item) => {
          return item.attr.name === "1200";
        }).value = ht_score?.home_score + "-" + ht_score?.away_score;
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "1200";
        }).value = "";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "0100";
      }).value = options?.game?.home_team?.name?.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "0110";
      }).value = score.home_score || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "01101";
      }).value = options?.game?.home_team?.variables?.badge || "";

      let home_scorers =
        options.game?.home_scorers?.map((s) => {
          return { ...s, team: "home" };
        }) || [];

      let away_scorers =
        options.game?.away_scorers?.map((s) => {
          return { ...s, team: "away" };
        }) || [];

      let home_totals = [];

      home_scorers
        .filter((s) => s.type === "try" || s.type === "penalty_try")
        .sort((a, b) => a.player - b.player)
        .forEach((player) => {
          let index = home_totals.findIndex((p) => p.player === player.player);
          let time =
            player.period === 1
              ? Math.min(40, player?.game_time_minutes + 1)
              : player.period === 2
              ? Math.min(80, player?.game_time_minutes + 1)
              : player?.game_time_minutes + 1;
          if (index > -1) {
            home_totals[index].count += 1;
            home_totals[index].tries.push({
              time: time,
              period: player?.period,
              penalty: player?.type === "penalty_try",
            });
          } else {
            home_totals.push({
              player: player.player,
              count: 1,
              tries: [
                {
                  time: time,
                  period: player?.period,
                  penalty: player?.type === "penalty_try",
                },
              ],
            });
          }
        });

      xml.payload.field.find((item) => {
        return item.attr.name === "020.active";
      }).value = home_totals?.length > 0 ? 1 : 0;

      let home_try_scorers = home_totals.map((scorer) => {
        let scorer_player = options.game.lineup?.home?.find(
          (l) => l.squad?._id === scorer?.player
        );
        let output = "";
        if (
          options.home_squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                scorer_player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.home_squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  scorer_player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  scorer_player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            output = scorer_player?.squad?.player?.first_name + " ";
          } else {
            output = scorer_player?.squad?.player?.first_name?.[0] + ". ";
          }
        }
        output +=
          scorer_player?.squad?.player?.short_name ||
          scorer_player?.squad?.player?.last_name ||
          "";

        if (scorer?.tries?.filter((t) => t.penalty)?.length > 0) {
          output = "Penalty try";
        }
        if (output !== "") {
          output += " ";
          output += scorer?.tries
            ?.map((t) => {
              return t?.time + "'" + (t.period > 2 ? " GP" : "");
            })
            ?.join(", ");
        }
        return output;
      });

      let output_1 = [];
      let total_width = 55;
      let length = total_width;

      for (let i = 0; i < home_try_scorers?.length; i++) {
        let try_output = home_try_scorers[i];
        if (length > try_output?.length) {
          output_1.push(try_output);
          length -= try_output?.length;
        } else {
          break;
        }
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "0200";
      }).value = output_1?.join(", ") || "";

      let output_2 = [];
      length = total_width;
      for (let i = output_1?.length; i < home_try_scorers?.length; i++) {
        let try_output = home_try_scorers[i];
        if (length > try_output?.length) {
          output_2.push(try_output);
          length -= try_output?.length;
        }
      }
      xml.payload.field.find((item) => {
        return item.attr.name === "0300";
      }).value = output_2?.join(", ") || "";

      home_totals = [];

      home_scorers
        .filter(
          (s) =>
            s.type === "missed_conversion" ||
            s.type === "conversion" ||
            s.type === "penalty_kick" ||
            s.type === "missed_penalty_kick" ||
            s.type === "drop_goal"
        )
        .sort((a, b) => a.player - b.player)
        .forEach((player) => {
          let index = home_totals.findIndex((p) => p.player === player.player);

          let time =
            player.period === 1
              ? Math.min(40, player?.game_time_minutes + 1)
              : player.period === 2
              ? Math.min(80, player?.game_time_minutes + 1)
              : player?.game_time_minutes + 1;

          if (player.type === "conversion" || player.type === "penalty_kick") {
            if (index > -1) {
              home_totals[index].count += 1;
              home_totals[index].total += 1;
            } else {
              home_totals.push({
                player: player.player,
                count: 1,
                total: 1,
                dg_count: 0,
                dg: [],
              });
            }
          } else if (
            player.type === "missed_conversion" ||
            player.type === "missed_penalty_kick"
          ) {
            if (index > -1) {
              home_totals[index].total += 1;
            } else {
              home_totals.push({
                player: player.player,
                count: 0,
                total: 1,
                dg_count: 0,
                dg: [],
              });
            }
          } else if (player.type === "drop_goal") {
            if (index > -1) {
              home_totals[index].dg_count += 1;
              home_totals[index].dg.push({
                time: time,
                period: player.period,
              });
            } else {
              home_totals.push({
                player: player.player,
                dg_count: 1,
                dg: [{ time: time, period: player.period }],
                total: 0,
                count: 0,
              });
            }
          }
        });

      xml.payload.field.find((item) => {
        return item.attr.name === "040.active";
      }).value =
        home_totals?.length > 0 &&
        options.game.lineup?.home?.filter(
          (p) => p.sinbins?.length > 0 || p.red_card
        )?.length > 0
          ? 1
          : 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "050.active";
      }).value =
        home_totals?.length > 0 ||
        options.game.lineup?.home?.filter(
          (p) => p.sinbins?.length > 0 || p.red_card
        )?.length > 0
          ? 1
          : 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0500";
      }).value = home_totals
        .map((scorer) => {
          let scorer_player = options.game.lineup?.home?.find(
            (l) => l.squad?._id === scorer?.player
          );

          let output = "";
          if (
            options.home_squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  scorer_player?.squad?.player?.last_name_upper &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            if (
              options.home_squad?.filter(
                (s) =>
                  s?.player?.last_name_upper ===
                    scorer_player?.squad?.player?.last_name_upper &&
                  s?.player?.first_name_upper?.[0] ===
                    scorer_player?.squad?.player?.first_name_upper?.[0] &&
                  s?.type === "player" &&
                  s?.active
              )?.length > 1
            ) {
              output = scorer_player?.squad?.player?.first_name + " ";
            } else {
              output = scorer_player?.squad?.player?.first_name?.[0] + ". ";
            }
          }
          output +=
            scorer_player?.squad?.player?.short_name ||
            scorer_player?.squad?.player?.last_name ||
            "";
          if (output !== "") {
            output +=
              scorer?.total > 0 ? " " + scorer.count + "/" + scorer.total : "";
            output += scorer?.dg?.length > 0 && scorer.total > 0 ? " +" : "";
            output += scorer?.dg?.length > 0 ? " DG " : "";
            output += scorer?.dg
              ?.map((dg) => {
                return dg.time + "'" + (dg.period > 2 ? " GP" : "");
              })
              ?.join(", ");
          }
          return output;
        })
        .join(", ");

      xml.payload.field.find((item) => {
        return item.attr.name === "0120";
      }).value =
        options.game.lineup?.home?.filter(
          (p) => p.sinbins?.length > 0 || p.red_card
        )?.length > 0
          ? 1
          : 0;
      let home_card_text = JSON.parse(JSON.stringify(options.game.lineup?.home))
        ?.filter((p) => p.sinbins?.length > 0 || p.red_card)
        ?.sort((a, b) => {
          let time_a = Math.min(
            a?.red_card_time === null ? 999 : a?.red_card_time,
            a.sinbins?.sort((a, b) => {
              let time_a =
                a.period === 1
                  ? Math.min(40, a?.game_time_minutes + 1)
                  : a.period === 2
                  ? Math.min(80, a?.game_time_minutes + 1)
                  : a?.game_time_minutes + 1;

              let time_b =
                b.period === 1
                  ? Math.min(40, b?.game_time_minutes + 1)
                  : b.period === 2
                  ? Math.min(80, b?.game_time_minutes + 1)
                  : b?.game_time_minutes + 1;

              return time_a - time_b;
            })?.[0]?.game_time_minutes || 999
          );
          let time_b = Math.min(
            b?.red_card_time === null ? 999 : b?.red_card_time,
            b.sinbins?.sort((a, b) => {
              let time_a =
                a.period === 1
                  ? Math.min(40, a?.game_time_minutes + 1)
                  : a.period === 2
                  ? Math.min(80, a?.game_time_minutes + 1)
                  : a?.game_time_minutes + 1;

              let time_b =
                b.period === 1
                  ? Math.min(40, b?.game_time_minutes + 1)
                  : b.period === 2
                  ? Math.min(80, b?.game_time_minutes + 1)
                  : b?.game_time_minutes + 1;

              return time_a - time_b;
            })?.[0]?.game_time_minutes || 999
          );
          return time_a - time_b;
        })
        ?.map((scorer) => {
          let output = "";

          if (
            options.home_squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  scorer?.squad?.player?.last_name_upper &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            if (
              options.home_squad?.filter(
                (s) =>
                  s?.player?.last_name_upper ===
                    scorer?.squad?.player?.last_name_upper &&
                  s?.player?.first_name_upper?.[0] ===
                    scorer?.squad?.player?.first_name_upper?.[0] &&
                  s?.type === "player" &&
                  s?.active
              )?.length > 1
            ) {
              output = scorer?.squad?.player?.first_name + " ";
            } else {
              output = scorer?.squad?.player?.first_name_upper?.[0] + ". ";
            }
          }
          output +=
            scorer?.squad?.player?.short_name ||
            scorer?.squad?.player?.last_name ||
            "";

          output += scorer?.sinbins?.length > 0 ? " ^y " : "";

          output += scorer?.sinbins
            ?.sort((a, b) => {
              let time_a =
                a.period === 1
                  ? Math.min(40, a?.game_time_minutes + 1)
                  : a.period === 2
                  ? Math.min(80, a?.game_time_minutes + 1)
                  : a?.game_time_minutes + 1;

              let time_b =
                b.period === 1
                  ? Math.min(40, b?.game_time_minutes + 1)
                  : b.period === 2
                  ? Math.min(80, b?.game_time_minutes + 1)
                  : b?.game_time_minutes + 1;

              return time_a - time_b;
            })
            ?.map((sin) => {
              let time =
                sin.period === 1
                  ? Math.min(40, sin?.game_time_minutes + 1)
                  : sin.period === 2
                  ? Math.min(80, sin?.game_time_minutes + 1)
                  : sin?.game_time_minutes + 1;

              return time + "'";
            })
            ?.join(", ");

          if (scorer?.red_card) {
            let time =
              scorer.red_card_period === 1
                ? Math.min(40, scorer?.red_card_time + 1)
                : scorer.red_card_period === 2
                ? Math.min(80, scorer?.red_card_time + 1)
                : scorer?.red_card_time + 1;

            output += " ^r " + time + "'";
          }

          return output;
        })
        .join(", ");
      xml.payload.field.find((item) => {
        return item.attr.name === "0121";
      }).value = home_card_text !== "" ? "Cards:  " + home_card_text : "";

      xml.payload.field.find((item) => {
        return item.attr.name === "0700";
      }).value = options?.game?.away_team?.name?.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "0710";
      }).value = score.away_score || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "06101";
      }).value = options?.game?.away_team?.variables?.badge || "";

      let away_totals = [];
      away_scorers
        .filter((s) => s.type === "try" || s.type === "penalty_try")
        .sort((a, b) => a.player - b.player)
        .forEach((player) => {
          let index = away_totals.findIndex((p) => p.player === player.player);
          let time =
            player.period === 1
              ? Math.min(40, player?.game_time_minutes + 1)
              : player.period === 2
              ? Math.min(80, player?.game_time_minutes + 1)
              : player?.game_time_minutes + 1;
          if (index > -1) {
            away_totals[index].count += 1;
            away_totals[index].tries.push({
              time: time,
              period: player?.period,
              penalty: player?.type === "penalty_try",
            });
          } else {
            away_totals.push({
              player: player.player,
              count: 1,
              tries: [
                {
                  time: time,
                  period: player?.period,
                  penalty: player?.type === "penalty_try",
                },
              ],
            });
          }
        });

      xml.payload.field.find((item) => {
        return item.attr.name === "080.active";
      }).value = away_totals?.length > 0 ? 1 : 0;

      let away_try_scorers = away_totals.map((scorer) => {
        let scorer_player = options.game.lineup?.away?.find(
          (l) => l.squad?._id === scorer?.player
        );

        let output = "";
        if (
          options.away_squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                scorer_player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.away_squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  scorer_player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  scorer_player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            output = scorer_player?.squad?.player?.first_name + " ";
          } else {
            output = scorer_player?.squad?.player?.first_name?.[0] + ". ";
          }
        }
        output +=
          scorer_player?.squad?.player?.short_name ||
          scorer_player?.squad?.player?.last_name ||
          "";

        if (scorer?.tries?.filter((t) => t.penalty)?.length > 0) {
          output = "Penalty try";
        }
        if (output !== "") {
          output += " ";
          output += scorer?.tries
            ?.map((t) => {
              return t?.time + "'" + (t.period > 2 ? " GP" : "");
            })
            ?.join(", ");
        }
        return output;
      });

      output_1 = [];
      length = total_width;

      for (let i = 0; i < away_try_scorers?.length; i++) {
        let try_output = away_try_scorers[i];
        if (length > try_output?.length) {
          output_1.push(try_output);
          length -= try_output?.length;
        } else {
          break;
        }
      }
      xml.payload.field.find((item) => {
        return item.attr.name === "0800";
      }).value = output_1?.join(", ") || "";

      output_2 = [];
      length = total_width;
      for (let i = output_1?.length; i < away_try_scorers?.length; i++) {
        let try_output = away_try_scorers[i];
        if (length > try_output?.length) {
          output_2.push(try_output);
          length -= try_output?.length;
        }
      }
      xml.payload.field.find((item) => {
        return item.attr.name === "0900";
      }).value = output_2?.join(", ") || "";

      away_totals = [];
      away_scorers
        .filter(
          (s) =>
            s.type === "missed_conversion" ||
            s.type === "conversion" ||
            s.type === "penalty_kick" ||
            s.type === "missed_penalty_kick" ||
            s.type === "drop_goal"
        )
        .sort((a, b) => a.player - b.player)
        .forEach((player) => {
          let index = away_totals.findIndex((p) => p.player === player.player);
          let time =
            player.period === 1
              ? Math.min(40, player?.game_time_minutes + 1)
              : player.period === 2
              ? Math.min(80, player?.game_time_minutes + 1)
              : player?.game_time_minutes + 1;

          if (player.type === "conversion" || player.type === "penalty_kick") {
            if (index > -1) {
              away_totals[index].count += 1;
              away_totals[index].total += 1;
            } else {
              away_totals.push({
                player: player.player,
                count: 1,
                total: 1,
                dg_count: 0,
                dg: [],
              });
            }
          } else if (
            player.type === "missed_conversion" ||
            player.type === "missed_penalty_kick"
          ) {
            if (index > -1) {
              away_totals[index].total += 1;
            } else {
              away_totals.push({
                player: player.player,
                count: 0,
                total: 1,
                dg_count: 0,
                dg: [],
              });
            }
          } else if (player.type === "drop_goal") {
            if (index > -1) {
              away_totals[index].dg_count += 1;
              away_totals[index].dg.push({
                time: time,
                period: player.period,
              });
            } else {
              away_totals.push({
                player: player.player,
                dg_count: 1,
                dg: [{ time: time, period: player.period }],
                total: 0,
                count: 0,
              });
            }
          }
        });

      xml.payload.field.find((item) => {
        return item.attr.name === "100.active";
      }).value =
        away_totals?.length > 0 &&
        options.game.lineup?.away?.filter(
          (p) => p.sinbins?.length > 0 || p.red_card
        )?.length > 0
          ? 1
          : 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "110.active";
      }).value =
        away_totals?.length > 0 ||
        options.game.lineup?.away?.filter(
          (p) => p.sinbins?.length > 0 || p.red_card
        )?.length > 0
          ? 1
          : 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "1100";
      }).value = away_totals
        .map((scorer) => {
          let scorer_player = options.game.lineup?.away?.find(
            (l) => l.squad?._id === scorer?.player
          );

          let output = "";
          if (
            options.away_squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  scorer_player?.squad?.player?.last_name_upper &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            if (
              options.away_squad?.filter(
                (s) =>
                  s?.player?.last_name_upper ===
                    scorer_player?.squad?.player?.last_name_upper &&
                  s?.player?.first_name_upper?.[0] ===
                    scorer_player?.squad?.player?.first_name_upper?.[0] &&
                  s?.type === "player" &&
                  s?.active
              )?.length > 1
            ) {
              output = scorer_player?.squad?.player?.first_name + " ";
            } else {
              output = scorer_player?.squad?.player?.first_name?.[0] + ". ";
            }
          }
          output +=
            scorer_player?.squad?.player?.short_name ||
            scorer_player?.squad?.player?.last_name ||
            "";

          if (output !== "") {
            output +=
              scorer?.total > 0 ? " " + scorer.count + "/" + scorer.total : "";
            output += scorer?.dg?.length > 0 && scorer.total > 0 ? " +" : "";
            output += scorer?.dg?.length > 0 ? " DG " : "";

            output += scorer?.dg
              ?.map((dg) => {
                return dg.time + "'" + (dg.period > 2 ? " GP" : "");
              })
              ?.join(", ");
          }
          return output;
        })
        .join(", ");

      xml.payload.field.find((item) => {
        return item.attr.name === "0720";
      }).value =
        options.game.lineup?.away?.filter(
          (p) => p.sinbins?.length > 0 || p.red_card
        )?.length > 0
          ? 1
          : 0;

      let away_card_text = JSON.parse(JSON.stringify(options.game.lineup?.away))
        ?.filter((p) => p.sinbins?.length > 0 || p.red_card)
        ?.sort((a, b) => {
          let time_a = Math.min(
            a?.red_card_time === null ? 999 : a?.red_card_time,
            a.sinbins?.sort((a, b) => {
              let time_a =
                a.period === 1
                  ? Math.min(40, a?.game_time_minutes + 1)
                  : a.period === 2
                  ? Math.min(80, a?.game_time_minutes + 1)
                  : a?.game_time_minutes + 1;

              let time_b =
                b.period === 1
                  ? Math.min(40, b?.game_time_minutes + 1)
                  : b.period === 2
                  ? Math.min(80, b?.game_time_minutes + 1)
                  : b?.game_time_minutes + 1;

              return time_a - time_b;
            })?.[0]?.game_time_minutes || 999
          );
          let time_b = Math.min(
            b?.red_card_time === null ? 999 : b?.red_card_time,
            b.sinbins?.sort((a, b) => {
              let time_a =
                a.period === 1
                  ? Math.min(40, a?.game_time_minutes + 1)
                  : a.period === 2
                  ? Math.min(80, a?.game_time_minutes + 1)
                  : a?.game_time_minutes + 1;

              let time_b =
                b.period === 1
                  ? Math.min(40, b?.game_time_minutes + 1)
                  : b.period === 2
                  ? Math.min(80, b?.game_time_minutes + 1)
                  : b?.game_time_minutes + 1;

              return time_a - time_b;
            })?.[0]?.game_time_minutes || 999
          );
          return time_a - time_b;
        })
        ?.map((scorer) => {
          let output = "";
          if (
            options.away_squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  scorer?.squad?.player?.last_name_upper &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            if (
              options.away_squad?.filter(
                (s) =>
                  s?.player?.last_name_upper ===
                    scorer?.squad?.player?.last_name_upper &&
                  s?.player?.first_name_upper?.[0] ===
                    scorer?.squad?.player?.first_name_upper?.[0] &&
                  s?.type === "player" &&
                  s?.active
              )?.length > 1
            ) {
              output = scorer?.squad?.player?.first_name + " ";
            } else {
              output = scorer?.squad?.player?.first_name_upper?.[0] + ". ";
            }
          }
          output +=
            scorer?.squad?.player?.short_name ||
            scorer?.squad?.player?.last_name ||
            "";
          output += scorer?.sinbins?.length > 0 ? " ^y " : "";
          output += scorer?.sinbins
            ?.sort((a, b) => {
              let time_a =
                a.period === 1
                  ? Math.min(40, a?.game_time_minutes + 1)
                  : a.period === 2
                  ? Math.min(80, a?.game_time_minutes + 1)
                  : a?.game_time_minutes + 1;

              let time_b =
                b.period === 1
                  ? Math.min(40, b?.game_time_minutes + 1)
                  : b.period === 2
                  ? Math.min(80, b?.game_time_minutes + 1)
                  : b?.game_time_minutes + 1;

              return time_a - time_b;
            })
            ?.map((sin) => {
              let time =
                sin.period === 1
                  ? Math.min(40, sin?.game_time_minutes + 1)
                  : sin.period === 2
                  ? Math.min(80, sin?.game_time_minutes + 1)
                  : sin?.game_time_minutes + 1;
              return time + "'";
            })
            ?.join(", ");

          if (scorer?.red_card) {
            let time =
              scorer.red_card_period === 1
                ? Math.min(40, scorer?.red_card_time + 1)
                : scorer.red_card_period === 2
                ? Math.min(80, scorer?.red_card_time + 1)
                : scorer?.red_card_time + 1;

            output += " ^r " + time + "'";
          }

          return output;
        })
        .join(", ");
      xml.payload.field.find((item) => {
        return item.attr.name === "0721";
      }).value = away_card_text != "" ? "Cards:  " + away_card_text : "";

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }
  getHTScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try" && g?.period === 1)
        .length * try_points || 0) +
      (game?.home_scorers?.filter(
        (g) => g.type === "conversion" && g?.period === 1
      ).length * conversion_points || 0) +
      (game?.home_scorers?.filter(
        (g) => g.type === "penalty_kick" && g?.period === 1
      ).length * penalty_kick_points || 0) +
      (game?.home_scorers?.filter(
        (g) => g.type === "penalty_try" && g?.period === 1
      ).length * penalty_try_points || 0) +
      (game?.home_scorers?.filter(
        (g) => g.type === "drop_goal" && g?.period === 1
      ).length * drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try" && g?.period === 1)
        .length * try_points || 0) +
      (game?.away_scorers?.filter(
        (g) => g.type === "conversion" && g?.period === 1
      ).length * conversion_points || 0) +
      (game?.away_scorers?.filter(
        (g) => g.type === "penalty_kick" && g?.period === 1
      ).length * penalty_kick_points || 0) +
      (game?.away_scorers?.filter(
        (g) => g.type === "penalty_try" && g?.period === 1
      ).length * penalty_try_points || 0) +
      (game?.away_scorers?.filter(
        (g) => g.type === "drop_goal" && g?.period === 1
      ).length * drop_goal_points || 0);

    return { home_score, away_score };
  }
}
