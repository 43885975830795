import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  padding: 7px;
  text-align: center;
  box-sizing: content-box;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const Row = styled.div`
  display: flex;
`;
function WinPredictor({ previewGraphic, game }) {
  const [latestWinPrediction, setLatestWinPrediction] = useState({});
  const [winPrediction, setWinPrediction] = useState({});
  useEffect(() => {
    let latest = game?.win_predictor?.events?.at(-1);
    setWinPrediction(game?.win_predictor);
    setLatestWinPrediction(latest);
  }, [game]);
  let period =
    game.live_data &&
    game.live_data.periods &&
    game.live_data.periods.sort((a, b) => b.number - a.number)[0];

  if (period?.kick_off === null && period?.number === 1) {
    return (
      <GraphicButton
        onClick={() => {
          previewGraphic("WinPrediction", {
            game: game,
            stat: {
              home:
                Math.round(winPrediction?.preMatchHomeProbability * 100) || 0,
              draw:
                Math.round(
                  (1 -
                    winPrediction?.preMatchHomeProbability -
                    winPrediction?.preMatchAwayProbability) *
                    100
                ) || 0,
              away:
                Math.round(winPrediction?.preMatchAwayProbability * 100) || 0,
            },
          });
        }}
      >
        <Row>Pre Game - Win Prediction</Row>
        <Row>
          Home: {Math.round(winPrediction?.preMatchHomeProbability * 100) || 0}%
          Draw:{" "}
          {Math.round(
            (1 -
              winPrediction?.preMatchHomeProbability -
              winPrediction?.preMatchAwayProbability) *
              100
          ) || 0}
          % Away:{" "}
          {Math.round(winPrediction?.preMatchAwayProbability * 100) || 0}%
        </Row>
      </GraphicButton>
    );
  }

  if (period?.kick_off != null || period?.number !== 1) {
    return (
      <GraphicButton
        onClick={() => {
          previewGraphic("WinPrediction", {
            game: game,
            stat: {
              home: Math.round(latestWinPrediction?.homeProbability * 100) || 0,
              draw:
                Math.round(
                  (1 -
                    latestWinPrediction?.homeProbability -
                    latestWinPrediction?.awayProbability) *
                    100
                ) || 0,
              away: Math.round(latestWinPrediction?.awayProbability * 100) || 0,
            },
          });
        }}
      >
        <Row>
          {latestWinPrediction?.minute}:{latestWinPrediction?.second} - Win
          Prediction
        </Row>
        <Row>
          Home: {Math.round(latestWinPrediction?.homeProbability * 100) || 0}%
          Draw:{" "}
          {Math.round(
            (1 -
              latestWinPrediction?.homeProbability -
              latestWinPrediction?.awayProbability) *
              100
          ) || 0}
          % Away: {Math.round(latestWinPrediction?.awayProbability * 100) || 0}%
        </Row>
      </GraphicButton>
    );
  }
}

export default WinPredictor;
