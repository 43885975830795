import React from "react";
import styled from "styled-components";
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  > tbody > tr {
    background-color: rgb(32, 39, 49);
  }

  th {
    padding: 1em;
  }
  td {
    padding: 0.2em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    width: ${(props) => props.width};
    height: 30px;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: black;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #818283;
  text-align: left;
`;
const TR = styled.tr`
  > td {
    background-color: ${(props) => (props.highlight ? "#db0a41" : "")};
    border-top: ${(props) =>
      props.dots ? "1px dashed white" : " 2px solid rgb(24, 29, 37)"};
  }
`;

const ActionPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0.5em;
  margin-left: 0.5em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  margin-bottom: 10px;
`;
function Overall({ game }) {
  let teams = game?.kick_predictor?.teams?.team?.[1];
  let kicks = [];
  try {
    if (Array.isArray(game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent)) {
      kicks = kicks.concat(
        game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent
      );
    } else if (game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent) {
      kicks.push(game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent);
    }

    if (Array.isArray(game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent)) {
      kicks = kicks.concat(
        game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent
      );
    } else if (game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent) {
      kicks.push(game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent);
    }
    kicks = kicks?.sort((a, b) => {
      if (a?.period === "First Half" && b?.period === "Second Half") {
        return 1;
      }
      if (a?.period === "Second Half" && b?.period === "First Half") {
        return -1;
      }
      if (parseInt(a?.time) < parseInt(b?.time)) {
        return 1;
      }
      if (parseInt(a?.time) > parseInt(b?.time)) {
        return -1;
      }
      if (parseInt(a?.secs) < parseInt(b?.secs)) {
        return 1;
      }
      if (parseInt(a?.secs) > parseInt(b?.secs)) {
        return -1;
      }
    });
  } catch (err) {
    console.log(err);
  }

  return (
    <ActionPanel>
      <Table>
        <thead>
          <tr>
            <TH>Period</TH>
            <TH>Time</TH>
            <TH>Player</TH>
            <TH>Type</TH>
            <TH>Distance</TH>
          </tr>
        </thead>
        <tbody>
          {kicks?.map((kick) => {
            return (
              <TR>
                <td>{kick?.period}</td>
                <td>
                  {kick?.time?.padStart(2, "0")}:{kick?.secs?.padStart(2, "0")}
                </td>
                <td>{kick?.player_name}</td>
                <td>{kick?.statname}</td>
                <td>{kick?.distance}</td>
              </TR>
            );
          })}
        </tbody>
      </Table>
    </ActionPanel>
  );
}

export default Overall;
