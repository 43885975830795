export class EdgeMatchStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "EdgeMatchStats";
    this.scene = "EdgeMatchStats";
    this.data = [];
    this.page = 7056;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "006";
      }).value = "";

      xml.payload.field.find((item) => {
        return item.attr.name === "007";
      }).value = "";

      xml.payload.field.find((item) => {
        return item.attr.name === "00101";
      }).value =
        options?.team?.variables?.alt_badge ||
        options?.team?.variables?.badge ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "0003";
      }).value = 1;

      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = "Match stats";

      let lineup =
        options?.team?._id === options?.game?.home_team?._id
          ? options?.game?.lineup?.home
          : options?.team?._id === options?.game?.away_team?._id
          ? options?.game?.lineup?.away
          : [];

      if (options.position === "left_edge") {
        let props = lineup?.filter((p) => {
          return (
            p.order === 4 || p.order === 5 || p.order === 7 || p.order === 12
          );
        });

        let stats =
          options?.team?._id === options?.game?.home_team?._id
            ? options?.game?.opta_home_player_stats?.filter((p) => {
                return (
                  props?.findIndex(
                    (o) => o?.squad?.player?.opta_ID === p?.player
                  ) > -1
                );
              })
            : options?.team?._id === options?.game?.away_team?._id
            ? options?.game?.opta_away_player_stats?.filter((p) => {
                return (
                  props?.findIndex(
                    (o) => o?.squad?.player?.opta_ID === p?.player
                  ) > -1
                );
              })
            : [];

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "LEFT EDGE";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Avg. Gain";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value =
          stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["av_gain"]) || 0);
          }, 0) /
            4 +
          "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Tackles";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);
      } else if (options.position === "right_edge") {
        let props = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return (
              p.order === 2 || p.order === 3 || p.order === 6 || p.order === 11
            );
          });
        let stats =
          options?.team?._id === options?.game?.home_team?._id
            ? options?.game?.opta_home_player_stats?.filter((p) => {
                return (
                  props?.findIndex(
                    (o) => o?.squad?.player?.opta_ID === p?.player
                  ) > -1
                );
              })
            : options?.team?._id === options?.game?.away_team?._id
            ? options?.game?.opta_away_player_stats?.filter((p) => {
                return (
                  props?.findIndex(
                    (o) => o?.squad?.player?.opta_ID === p?.player
                  ) > -1
                );
              })
            : [];

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "RIGHT EDGE";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Avg. Gain";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value =
          stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["av_gain"]) || 0);
          }, 0) /
            4 +
          "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Tackles";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);
      } else if (options.position === "middles") {
        let props = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return (
              p.order === 8 || p.order === 9 || p.order === 10 || p.order === 13
            );
          });

        let stats =
          options?.team?._id === options?.game?.home_team?._id
            ? options?.game?.opta_home_player_stats?.filter((p) => {
                return (
                  props?.findIndex(
                    (o) => o?.squad?.player?.opta_ID === p?.player
                  ) > -1
                );
              })
            : options?.team?._id === options?.game?.away_team?._id
            ? options?.game?.opta_away_player_stats?.filter((p) => {
                return (
                  props?.findIndex(
                    (o) => o?.squad?.player?.opta_ID === p?.player
                  ) > -1
                );
              })
            : [];

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "MIDDLES";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Avg. Gain";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value =
          stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["av_gain"]) || 0);
          }, 0) /
            4 +
          "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Tackles";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
