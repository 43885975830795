export class FFStatsLoggers {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "FFStatsLoggers";
    this.scene = "Name";
    this.data = [];
    this.downloadPage = 7151;
    this.page = 501;
  }

  getTotalPosssession({ sequences, game }) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total_home = 0;
    let total_away = 0;
    sequences
      .filter((s) => s.team === game?.home_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_home += end_time - start_time;
      });

    sequences
      .filter((s) => s.team === game?.away_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_away += end_time - start_time;
      });
    let home_percent = parseInt((total_home / (total_home + total_away)) * 100);

    return {
      home: home_percent || 0,
      away: 100 - home_percent || 0,
    };
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.downloadPage }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      let poss = this.getTotalPosssession({
        sequences: [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        game: options.game,
      });

      let home_stats = options.game?.home_player_stats || [];
      let away_stats = options.game?.away_player_stats || [];

      let home_sets = this.getSetTotals({
        sequences: [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        team: options.game?.home_team?._id,
      });

      let away_sets = this.getSetTotals({
        sequences: [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        team: options.game?.away_team?._id,
      });

      let home_metres = this.getTotalMetres({
        sequences: [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        team: options.game?.home_team?._id,
      });

      let away_metres = this.getTotalMetres({
        sequences: [
          ...(options.game?.home_sets || []),
          ...(options.game?.away_sets || []),
        ],
        team: options.game?.away_team?._id,
      });

      xml.payload.field.find((item) => {
        return item.attr.name === "000101";
      }).value = options.game?.home_team?.short_name?.toUpperCase() || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "000102";
      }).value =
        options.game?.home_team?.variables?.alt_badge ||
        options.game?.home_team?.variables?.badge ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "000201";
      }).value = options.game?.away_team?.short_name?.toUpperCase() || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "000202";
      }).value =
        options.game?.away_team?.variables?.alt_badge ||
        options.game?.away_team?.variables?.badge ||
        "";

      let score = this.getScore(options.game);

      xml.payload.field.find((item) => {
        return item.attr.name === "000301";
      }).value = score?.home_score || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "000302";
      }).value = score?.away_score || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "0120";
      }).value = "Possession";

      xml.payload.field.find((item) => {
        return item.attr.name === "0121";
      }).value = poss?.home || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "01210";
      }).value = "%";

      xml.payload.field.find((item) => {
        return item.attr.name === "0122";
      }).value = poss?.away || "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "01220";
      }).value = "%";

      xml.payload.field.find((item) => {
        return item.attr.name === "0220";
      }).value = "Completed sets";

      xml.payload.field.find((item) => {
        return item.attr.name === "02210";
      }).value = "(" + +home_sets?.percent + "%)";

      xml.payload.field.find((item) => {
        return item.attr.name === "0221";
      }).value = home_sets?.complete + "/" + home_sets?.total;

      xml.payload.field.find((item) => {
        return item.attr.name === "02220";
      }).value = "(" + away_sets?.percent + "%)";

      xml.payload.field.find((item) => {
        return item.attr.name === "0222";
      }).value = away_sets?.complete + "/" + away_sets?.total;

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0321";
      // }).value = options?.game?.opta_home_team_stats?.carries || 0;

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0322";
      // }).value = options?.game?.opta_away_team_stats?.carries || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0320";
      }).value = "Metres";

      xml.payload.field.find((item) => {
        return item.attr.name === "0321";
      }).value = home_metres || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0322";
      }).value = away_metres || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0420";
      }).value = "Tackles";

      xml.payload.field.find((item) => {
        return item.attr.name === "0421";
      }).value = Object.keys(home_stats).reduce(function (previous, key) {
        return previous + (home_stats[key]?.Tackles || 0);
      }, 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "0422";
      }).value = Object.keys(away_stats).reduce(function (previous, key) {
        return previous + (away_stats[key]?.Tackles || 0);
      }, 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "0520";
      }).value = "Missed tackles";

      xml.payload.field.find((item) => {
        return item.attr.name === "0521";
      }).value = Object.keys(home_stats).reduce(function (previous, key) {
        return previous + (home_stats[key]?.["Missed Tackles"] || 0);
      }, 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "0522";
      }).value = Object.keys(away_stats).reduce(function (previous, key) {
        return previous + (away_stats[key]?.["Missed Tackles"] || 0);
      }, 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "0620";
      }).value = "Errors";

      xml.payload.field.find((item) => {
        return item.attr.name === "0621";
      }).value = Object.keys(home_stats).reduce(function (previous, key) {
        return previous + (home_stats[key]?.["Handling errors"] || 0);
      }, 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "0622";
      }).value = Object.keys(away_stats).reduce(function (previous, key) {
        return previous + (away_stats[key]?.["Handling errors"] || 0);
      }, 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "0720";
      }).value = "Penalties conceded";

      xml.payload.field.find((item) => {
        return item.attr.name === "0721";
      }).value = options?.game?.home_team_stats?.Penalties || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "0722";
      }).value = options?.game?.away_team_stats?.Penalties || 0;

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0421";
      // }).value =
      //   Math.floor(
      //     ((options?.game?.opta_home_team_stats?.complete_sets || 0) /
      //       home_total) *
      //       100
      //   ) + "%";

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "0422";
      // }).value =
      //   Math.floor(
      //     ((options?.game?.opta_away_team_stats?.complete_sets || 0) /
      //       away_total) *
      //       100
      //   ) + "%";

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);

    return { home_score, away_score };
  }

  getSetTotals({ sequences, team }) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total = 0;
    let complete = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total += 1;
        complete += s?.status === "complete" ? 1 : 0;
      });
    return {
      total,
      complete,
      percent: parseInt((complete / total) * 100) || 0,
    };
  }

  getTotalMetres({ sequences, team }) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total +=
          Math.abs(
            Math.round(
              (Math.max(82, Math.min(933, s?.tackles?.at(-1)?.x)) -
                Math.max(82, Math.min(933, s?.tackles?.[0]?.x))) /
                7
            )
          ) || 0;
      });
    return total;
  }
}
