import moment from "moment";

export class GameScoresTag {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    createPage,
    setPageSummary,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.createPage = createPage;
    this.setPageSummary = setPageSummary;
    this.name = "GameScoresTag";
    this.scene = "GameScoresTag";
    this.data = [];
    this.downloadPage = 7007;
    this.page = 160;
    this.buildPage = 160;
  }

  async preview({ options }) {
    this.onAir = false;

    if (!options.build) {
      this.page = this.downloadPage;
      await this.buildSingle(options);
      await this.sendPreview("page:read " + this.page);
    } else {
      this.page = this.buildPage;
      await this.buildAll(options);
      this.page = this.buildPage;
      await this.sendPreview("page:read " + this.page);
    }
  }

  async buildSingle(options) {
    let xml = await this.getVizPage({ page: this.downloadPage });
    let text =
      (options?.game?.home_team?.short_name || options?.game?.home_team?.name) +
      " " +
      options?.game?.opta_scores?.home?.score +
      "-" +
      options?.game?.opta_scores?.away?.score +
      " " +
      (options?.game?.away_team?.short_name || options?.game?.away_team?.name);
    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // home team
    }).value = text;

    xml.payload.field.find((item) => {
      return item.attr.name === "122"; // home team
    }).value =
      options?.game?.opta_scores?.status === "Halftime"
        ? "Half Time"
        : options?.game?.opta_scores?.status === "Result"
        ? "Full Time"
        : "Latest";

    if (options.build) {
      await this.updateVizPage({ page: this.page, data: xml }).then(
        async (data) => {
          if (data === "") {
            await this.createPage({
              page: this.page,
              data: xml,
            });
          }
          this.setPageSummary({
            page: this.page,
            summary:
              text +
              " - " +
              (options?.game?.opta_scores?.status === "Halftime"
                ? "Half Time"
                : options?.game?.opta_scores?.status === "Result"
                ? "Full Time"
                : "Latest"),
          });
        }
      );
    } else {
      await this.updateVizPage({ page: this.page, data: xml });
    }
  }

  async buildAll(options) {
    for (let i = 0; i < options?.games?.length; i++) {
      this.page = this.buildPage + i;
      let game = options?.games?.[i];
      await this.buildSingle({ game, build: true });
    }
  }

  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
