/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";
import moment from "moment";
import Date from "./Date";
import { ScrollBar } from "../../Theme/Hyper";
import { UIContext, MODE_CONSTS } from "../../contexts/UIContext";
import { APIContext } from "../../contexts/APIContext";
import CalendarUI from "../UI/Calendar/CalendarUI";
import "../../calendar.css";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";
import ServerStatus from "../ServerStatus/ServerStatus";

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      competition {
        _id
        oval_ID
        opta_ID
        name
        competition_type
        badge
        secondary_badge
        variables
      }
      home_team {
        _id
        name
        short_name
        code
        rugby_league_ID
        opta_ID
      }
      away_team {
        _id
        name
        short_name
        code
        rugby_league_ID
        opta_ID
      }
      win_predictor
    }
  }
`;

const GAMES_BY_MONTH = gql`
  query Games($date: String) {
    gamesByMonth(date: $date) {
      _id
      date
    }
  }
`;

const Main = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
`;
const GamesDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 100px);
`;
const Game = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Team = styled.div`
  display: flex;
  margin: 0.5em;
  width: 45%;
  justify-content: ${(props) => (props.right ? "flex-end" : "")};
`;
const Verses = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Competitions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #202731;
  margin-left: 1px;
  width: ${(props) => (props.iPad ? "500px" : "300px")};
  padding: 1em;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
`;
export default function Games({ onSelectGame, iPad }) {
  const { mode } = useContext(UIContext);
  const history = useHistory();
  const location = useLocation();
  const [fixtures, setFixtures] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [monthFixtures, setMonthFixtures] = useState();

  // TODO - ADDD LOADING AND ERROR STATES
  const [getFixtures, { data }] = useLazyQuery(GAMES_BY_DATE);
  const [getMonthFixtures, { data: month_data }] = useLazyQuery(
    GAMES_BY_MONTH,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (date) {
      getFixtures({ variables: { date: date + "Z" } });
    }
  }, [date, getFixtures]);

  useEffect(() => {
    if (data) {
      setFixtures([...(data.gamesByDate || [])]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedMonth) {
      getMonthData();
    }
  }, [selectedMonth, getMonthFixtures]);

  useEffect(() => {
    if (month_data) {
      setMonthFixtures([...(month_data?.gamesByMonth || [])]);
    }
  }, [month_data]);

  function getMonthData() {
    getMonthFixtures({
      variables: { date: selectedMonth.format("YYYY-MM-DD") + "Z" },
    });
  }

  return (
    <Main>
      <Column>
        <Row>
          <Competitions iPad={iPad}>
            <CalendarUI
              onChangeDate={(date) => {
                setDate(moment(date).format("YYYY-MM-DD"));
              }}
              date={moment()}
              onActiveStartDateChange={({ activeStartDate, value }) => {
                setSelectedMonth(moment(activeStartDate));
              }}
              highlights={monthFixtures?.map((m) => m.date)}
            />
          </Competitions>
          <Column>
            <GamesDiv>
              {fixtures
                ?.sort((a, b) => {
                  if (a.competition > b.competition) {
                    return 1;
                  } else if (a.competition < b.competition) {
                    return -1;
                  }

                  let a_date = moment(a.date + " " + a.time);
                  let b_date = moment(b.date + " " + b.time);
                  // Else go to the 2nd item
                  if (a_date < b_date) {
                    return -1;
                  } else if (a_date > b_date) {
                    return 1;
                  } else {
                    // nothing to split them
                    return 0;
                  }
                })
                ?.map((fixture, index) => {
                  return (
                    <>
                      {(index === 0 ||
                        fixtures[index - 1].competition?._id !==
                          fixture.competition?._id) && (
                        <div>{fixture.competition?.name}</div>
                      )}
                      <Game
                        onClick={() => {
                          onSelectGame(fixture);
                          let path = location;
                          let url =
                            location.pathname.replaceAll("/", "") +
                            "/" +
                            fixture._id;
                          if (path.pathname === "/tackles/") {
                            url =
                              "/" +
                              location.pathname.replaceAll("/", "") +
                              "/" +
                              fixture._id;
                          }
                          if (path.pathname === "/fruit/") {
                            url =
                              "/" +
                              location.pathname.replaceAll("/", "") +
                              "/" +
                              fixture._id;
                          }
                          if (path.pathname === "/sky_fruit/") {
                            url =
                              "/" +
                              location.pathname.replaceAll("/", "") +
                              "/" +
                              fixture._id;
                          }

                          history.push({
                            pathname: url,
                          });
                        }}
                      >
                        <Team right>{fixture.home_team?.name}</Team>
                        <Verses>
                          <div>
                            {moment("01/01/1990 " + fixture.time).format(
                              "HH:mm"
                            )}
                          </div>
                        </Verses>
                        <Team>{fixture.away_team?.name}</Team>
                      </Game>
                    </>
                  );
                })}
            </GamesDiv>
          </Column>
        </Row>
        {(window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && <ServerStatus />}
      </Column>
    </Main>
  );
}
