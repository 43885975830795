import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Stat from "./Stat";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { ScrollBar } from "../../Theme/Hyper";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Scroll = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 100vh;
`;
const Row = styled.div`
  font-size: 25px;
  font-weight: 100;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
  background-color: #1c222b;
`;

const Half = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;

const names = [
  {
    id: "possession",
    name: "Possession ",
    percent: true,
  },
  {
    id: "penalties",
    name: "Penalties conceded",
  },
  {
    id: "goal_line_dropout",
    name: "Goal line dropouts",
  },
  {
    id: "tackles",
    name: "Tackles",
  },
  {
    id: "missed_tackles",
    name: "Missed tackles",
  },
  {
    id: "clean_break",
    name: "Linebreaks",
  },
  {
    id: "errors",
    name: "Errors",
  },
  {
    id: "offload",
    name: "Offloads",
  },
  {
    id: "run_from_dummy_half",
    name: "Runs from dummy half",
  },
  {
    id: "carries",
    name: "Carries",
  },
  {
    id: "set_restart_won",
    name: "Six agains won",
  },
  {
    id: "complete_sets",
    name: "Sets completed",
  },
  // {
  //   id: "incomplete_sets",
  //   name: "Incomplete sets",
  // },
  {
    id: "completionPercentage",
    name: "Completion rate",
    percent: true,
  },
  {
    id: "metres",
    name: "Metres",
  },
];
export default function OPTATeamStatsFruit({ game }) {
  const { previewGraphic, animateOffGraphic, graphicInPreview } =
    useContext(GraphicsContext);
  const [selectedtStat, setSelectedStat] = useState();

  return (
    <Main>
      <Scroll>
        <Row>
          <Half>{game?.home_team?.name}</Half>
          <Half>{game?.away_team?.name}</Half>
        </Row>
        {names
          ?.map((o) => o?.id)
          ?.map((key) => {
            let home_stat = game?.opta_home_team_stats?.[key] || 0;
            let away_stat = game?.opta_away_team_stats?.[key] || 0;
            if (key === "completionPercentage") {
              home_stat = Math.round(home_stat);
              away_stat = Math.round(away_stat);
            }
            if (key === "complete_sets") {
              home_stat =
                home_stat +
                "/" +
                (parseInt(home_stat) +
                  parseInt(
                    game?.opta_home_team_stats?.["incomplete_sets"] || 0
                  ));
              away_stat =
                away_stat +
                "/" +
                (parseInt(away_stat) +
                  parseInt(
                    game?.opta_away_team_stats?.["incomplete_sets"] || 0
                  ));
            }

            return (
              <Row>
                <Stat
                  game={game}
                  category={names?.find((s) => s.id === key)?.name || key}
                  matchStats={[]}
                  previewGraphic={previewGraphic}
                  animateOffGraphic={animateOffGraphic}
                  graphicInPreview={graphicInPreview}
                  stat={{
                    home:
                      home_stat +
                      (names?.find((s) => s.id === key)?.percent ? "%" : ""),
                    away:
                      away_stat +
                      (names?.find((s) => s.id === key)?.percent ? "%" : ""),
                  }}
                  selectedtStat={selectedtStat}
                  setSelectedStat={setSelectedStat}
                  line_2={names?.find((s) => s.id === key)?.line_2 || ""}
                />
              </Row>
            );
          })}
      </Scroll>
    </Main>
  );
}
