/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { useContext } from "react";
import { UIContext } from "../../contexts/UIContext";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

const GraphicButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const Main = styled.div`
  width: 100%;
  margin-top: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function ClockCountdown() {
  const {
    tryTime,
    penaltyTime,
    setPenaltyTime,
    penaltyTimeStatus,
    tryTimeStatus,
    setTryTime,
    setPenaltyTimeStatus,
    setTryTimeStatus,
    onAir,
    setOnAir,
  } = useContext(UIContext);
  const { animateGraphic, animateOffGraphic, updateGraphic } =
    useContext(GraphicsContext);

  useEffect(() => {
    let interval = setInterval(() => {
      if (tryTimeStatus === "ticking") {
        setTryTime(tryTime - 1);
      }
      if (penaltyTimeStatus === "ticking") {
        setPenaltyTime(penaltyTime - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tryTime, penaltyTime, penaltyTimeStatus, tryTimeStatus, onAir]);

  useEffect(() => {
    window.document.getElementById("try_clock").innerText = Math.max(
      0,
      tryTime || 0
    );
    window.document.getElementById("penalty_clock").innerText = Math.max(
      0,
      penaltyTime || 0
    );
    if (onAir === "conv") {
      updateGraphic("Countdown", {
        clock: Math.max(0, tryTime || 0),
      });
    } else if (onAir === "pen") {
      updateGraphic("Countdown", {
        clock: Math.max(0, penaltyTime || 0),
      });
    }
  }, [penaltyTime, tryTime, onAir]);

  return (
    <>
      <Main>
        <Row>
          <Column>
            <Row>Conversion Clock</Row>
            <Row>
              <span id="try_clock"></span>
            </Row>
            <Row>
              <GraphicButton
                onClick={() => {
                  setTryTime(90);
                  setTryTimeStatus("ticking");
                }}
              >
                Start
              </GraphicButton>
              {tryTimeStatus === "ticking" && (
                <GraphicButton
                  onClick={() => {
                    setTryTimeStatus("paused");
                  }}
                >
                  Pause
                </GraphicButton>
              )}
              {tryTimeStatus === "paused" && (
                <GraphicButton
                  onClick={() => {
                    setTryTimeStatus("ticking");
                  }}
                >
                  Resume
                </GraphicButton>
              )}
            </Row>
            <Row>
              <GraphicButton
                green={onAir === "conv"}
                onClick={() => {
                  setOnAir("conv");
                  animateGraphic("Countdown", { type: "CONVERSION" });
                }}
              >
                on
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  animateOffGraphic("Countdown");
                  setOnAir();
                }}
              >
                Off
              </GraphicButton>
            </Row>
          </Column>
        </Row>
      </Main>
      <></>
      <Main>
        <Row>
          <Column>
            <Row>Penalty Clock</Row>

            <Row>
              <span id="penalty_clock"></span>
            </Row>
            <Row>
              <GraphicButton
                onClick={() => {
                  setPenaltyTime(60);
                  setPenaltyTimeStatus("ticking");
                }}
              >
                Start
              </GraphicButton>
              {penaltyTimeStatus === "ticking" && (
                <GraphicButton
                  onClick={() => {
                    setPenaltyTimeStatus("paused");
                  }}
                >
                  Pause
                </GraphicButton>
              )}
              {penaltyTimeStatus === "paused" && (
                <GraphicButton
                  onClick={() => {
                    setPenaltyTimeStatus("ticking");
                  }}
                >
                  Resume
                </GraphicButton>
              )}
            </Row>
            <Row>
              <GraphicButton
                green={onAir === "pen"}
                onClick={() => {
                  setOnAir("pen");
                  animateGraphic("Countdown", { type: "PENALTY" });
                }}
              >
                on
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  animateOffGraphic("Countdown");
                  setOnAir();
                }}
              >
                Off
              </GraphicButton>
            </Row>
          </Column>
        </Row>
      </Main>
    </>
  );
}

export default ClockCountdown;
