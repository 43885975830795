import React, { useState, useEffect } from "react";
import moment from "moment";
import useLocalStorage from "../Hooks/useLocalStorage";
import { ControlContext } from "./ControlContext";
import { useContext } from "react";
const ClockContext = React.createContext([{}, () => {}]);
window.scenesOnAir = [];

window.control_ws = null;
let period_number = 1;
const ClockProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [auto_clock, setAutoClock] = useState();
  const [period, setPeriod] = useState();
  const [clockType, setClockType] = useLocalStorage("clock_type", "stadium");
  const [kick_off_time, setKickOffTime] = useState(0);
  const { hyperClock } = useContext(ControlContext);

  useEffect(() => {
    period_number = period?.number;
  }, [period]);

  useEffect(() => {
    connect();
  }, [hyperClock]);

  function connect() {
    console.log("Clock connecting to server");
    try {
      window.clock_ws = new WebSocket(
        process.env.REACT_APP_CLOCK_WSS ||
          window.ENV?.REACT_APP_CLOCK_WSS ||
          hyperClock
      );
      setWS(window.clock_ws);
    } catch (err) {}
  }

  useEffect(() => {
    if (window.clock_ws) {
      let timeout;
      window.clock_ws.onopen = () => {
        setWSStatus(1);
        console.log("Rugby Control on open");
        clearInterval(window.clock_keep_alive);
        window.clock_keep_alive = setInterval(() => {
          window.clock_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
      };
      window.clock_ws.onmessage = (data) => {
        //console.log("Clock on message");
        try {
          let obj = JSON.parse(data.data);

          if (obj.hyperTime?.game?.time?.indexOf(":") > -1) {
            let half_duration = 40;
            if (period_number === 1) {
              half_duration = 40;
            } else if (period_number === 2) {
              half_duration = 80;
            } else if (period_number === 3) {
              half_duration = 5;
            } else if (period_number === 4) {
              half_duration = 10;
            }
            let mins = obj.hyperTime?.game?.time?.split(":")[0];
            let seconds = obj.hyperTime?.game?.time?.split(":")[1];
            let duration = mins * 60000;
            duration += seconds * 1000;

            //duration = half_duration * 60000 - duration;
            let time = moment.duration(Math.max(duration, 0));
            let minutes = time.minutes() + time.hours() * 60;
            let clock =
              minutes.toString().padStart(2, "0") +
              ":" +
              time.seconds().toString().padStart(2, "0");
            if (
              window.ENV?.REACT_APP_SKY === "true" ||
              process.env?.REACT_APP_SKY === "true"
            ) {
              let moment_duration = moment.duration({
                minutes: obj.hyperTime.game.time?.split(":")?.[0],
                seconds: obj.hyperTime.game.time?.split(":")?.[1],
              });

              let duration = moment
                .duration({ minutes: half_duration })
                .subtract(moment_duration);

              let minutes = duration.minutes() + duration.hours() * 60;
              clock =
                minutes.toString().padStart(2, "0") +
                ":" +
                duration.seconds().toString().padStart(2, "0");
            }
            setAutoClock(clock);

            setKickOffTime(moment().subtract(time).valueOf());
          }

          //console.log(obj);
        } catch (err) {
          console.error(err);
        }
      };
      window.clock_ws.onerror = (err) => {
        console.log("Clock on message", err);
        ws.close();
      };
      window.clock_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Clock on close");
        clearTimeout(timeout);
        clearInterval(window.clock_keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  return (
    <ClockContext.Provider
      value={{
        wsStatus,
        auto_clock,
        clockType,
        setClockType,
        kick_off_time,
        setPeriod,
      }}
    >
      {props.children}
    </ClockContext.Provider>
  );
};

export { ClockContext, ClockProvider };
