import React, { useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import Clock from "./Clock";
import Player from "./Player";
import Subs from "../Subs/Subs";
import EndHalf from "./EndHalf";
import moment from "moment";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import { APIContext } from "../../contexts/APIContext";
import { ControlContext } from "../../contexts/ControlContext";
import Select from "react-select";
import ClockCountdown from "./ClockCountdown";
import WinPredictor from "./WinPredictor";
import EPCRStandings from "./Tables/EPCRStandings";
import URCStandings from "./Tables/URCStandings";
import Teams from "./Teams";
import Attendance from "../Popup/Attendance";
const Main = styled.div`
  display: flex;

  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const SplitGraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 45%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 167px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 12px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.3em;
  margin-top: 0.5em;
  padding: 5px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;
const Row = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-evenly;
`;
const Title = styled.div`
  display: flex;
  margin-top: 10px;
`;
const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

const DECISIONS = [
  "Failing to release",
  "in from the side",
  "collapsing the scrum",
  "offside",
  "high tackle",
  "obstruction",
  "dissent",
  "advantage",
  "try",
  "no try",
  "TMO decision",
];
export default function RightSideBar({
  game,
  updateGame,
  previewGraphic,
  graphicInPreview,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  teams,
  selectedPlayer,
  players,
  stadiums,
  sky,
  squads,
  countries,
  standings,
  kits,
  clearPreview,
  homeManager,
  awayManager,
  setShowAttendance,
  homeSubsMade,
  setHomeSubsMade,
  awaySubsMade,
  setAwaySubsMade,
}) {
  const { user, signOut } = useContext(UserContext);
  const { engines, selectedEngine, setSelectedEngine } =
    useContext(ControlContext);

  const [view, setView] = useState(0);

  const [decision, setDecision] = useState("");
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  let latestGoal =
    game && game.live_data?.home_scorers ? game.live_data?.home_scorers : [];

  latestGoal = latestGoal
    .concat(game.live_data?.away_scorers || [])
    .sort((a, b) => b.time - a.time);
  if (latestGoal.length > 0) {
    latestGoal = latestGoal[0].time;
  }
  let time = moment.duration(latestGoal);
  time =
    time.minutes() +
    time.hours() * 60 +
    ":" +
    time.seconds().toString().padStart(2, "0");

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          {user?.username}
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" &&
        engines?.length > 1 && (
          <SelectContainer
            styles={DropdownStyles}
            setSize={true}
            value={{
              value: selectedEngine,
              label: engines?.find((e) => e.key === selectedEngine)?.name,
            }}
            options={engines.map((item, index) => {
              return {
                value: item.key,
                label: item.name,
              };
            })}
            onChange={(e) => {
              setSelectedEngine(e.value);
            }}
            placeholder="Round"
          />
        )}
      <Logo src={HyperLogo}></Logo>
      <Clock
        game={game}
        teams={teams}
        updateGame={updateGame}
        previewGraphic={previewGraphic}
        animateGraphic={animateGraphic}
        animateOffGraphic={animateOffGraphic}
        updateGraphic={updateGraphic}
        sky={sky}
      />
      <Buttons>
        <NavButton selected={view === 0} onClick={() => setView(0)}>
          MAIN
        </NavButton>
        {(window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <NavButton selected={view === 4} onClick={() => setView(4)}>
            TEAMS
          </NavButton>
        )}
        {(window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <NavButton selected={view === 5} onClick={() => setView(5)}>
            MATCH STATS
          </NavButton>
        )}
        <NavButton selected={view === 1} onClick={() => setView(1)}>
          SUBS
        </NavButton>
        {game?.competition?.competition_type === "union" && (
          <NavButton selected={view === 3} onClick={() => setView(3)}>
            DECISIONS
          </NavButton>
        )}

        {window.ENV?.REACT_APP_SKY !== "true" &&
          process.env.REACT_APP_SKY !== "true" && (
            <NavButton selected={view === 2} onClick={() => setView(2)}>
              TABLES
            </NavButton>
          )}
      </Buttons>
      {selectedPlayer && view === 0 && (
        <Player
          player={selectedPlayer}
          game={game}
          previewGraphic={previewGraphic}
          teams={teams}
          sky={sky}
          countries={countries}
          period={period}
        />
      )}
      {view === 1 &&
        (game.lineup?.home?.filter((p) => p.sub_order).length > 0 ||
          game.lineup?.away?.filter((p) => p.sub_order).length > 0) && (
          <Subs
            sky={sky}
            countries={countries}
            animateGraphic={animateGraphic}
            previewGraphic={previewGraphic}
            animateOffGraphic={animateOffGraphic}
            game={game}
            teams={teams}
            players={players}
            homeSubs={game.lineup?.home?.filter((p) => p.sub_order)}
            awaySubs={game.lineup?.away?.filter((p) => p.sub_order)}
            period={period}
            homeSubsMade={homeSubsMade}
            setHomeSubsMade={setHomeSubsMade}
            awaySubsMade={awaySubsMade}
            setAwaySubsMade={setAwaySubsMade}
          />
        )}
      {view === 0 && (
        <GraphicsList>
          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 2 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    type: "HT",
                  });
                }}
              >
                HT Score
              </GraphicButton>
            )}

          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number >= 3 && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "FT",
                    });
                  }}
                >
                  FT Score
                </GraphicButton>
              </>
            )}

          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 4 && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "ETHT",
                    });
                  }}
                >
                  ET HT Score
                </GraphicButton>
              </>
            )}

          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 5 && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "ETFT",
                    });
                  }}
                >
                  ET FT Score
                </GraphicButton>
              </>
            )}

          {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("MatchID", {
                  game: game,
                  teams: teams,
                  stadiums: stadiums,
                  sky: sky,
                });
              }}
            >
              Match ID
            </GraphicButton>
          )}

          {!selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 &&
            game?.competition?.name === "URC" && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Officials", {
                    game: game,
                    referee: game?.officials?.find((r) => r.type === "referee")
                      ?.official,
                    assistant_1: game?.officials?.filter(
                      (r) => r.type === "touch judge/assistant"
                    )?.[0]?.official,
                    assistant_2: game?.officials?.filter(
                      (r) => r.type === "touch judge/assistant"
                    )?.[1]?.official,
                    tmo: game?.officials?.find(
                      (r) => r.type === "television match official"
                    )?.official,
                  });
                }}
              >
                Officials
              </GraphicButton>
            )}

          {!selectedPlayer && period && period.number === 1 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Versus", {
                  game: game,
                  teams: teams,
                  sky: sky,
                });
              }}
            >
              Versus
            </GraphicButton>
          )}

          {!selectedPlayer &&
            period &&
            (period.number > 1 || period.kick_off) && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    teams: teams,
                    half: period.number,
                    show_half: false,
                  });
                }}
              >
                Score
              </GraphicButton>
            )}

          {/* {!selectedPlayer && period && (period.number > 1 || period.kick_off) && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Score", {
                  game: game,
                  teams: teams,
                  sky: sky,
                  half: 2,
                  show_half: false,
                  no_scorers: true,
                });
              }}
            >
              Score (No Scorers)
            </GraphicButton>
          )} */}

          {game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            period &&
            ((period.number === 1 && period.kick_off !== null) ||
              period.number === 2 ||
              period.number === 3 ||
              period.number === 4) && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FFScore", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half:
                      period.number === 1 ||
                      (period.number === 2 && period.kick_off === null)
                        ? 1
                        : period.number === 2 ||
                          (period.number === 3 && period.kick_off === null)
                        ? 2
                        : period.number,
                    show_half: false,
                    no_scorers: true,
                  });
                }}
              >
                FF Scor
              </GraphicButton>
            )}

          {game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            period &&
            ((period.number === 1 && period.kick_off !== null) ||
              (period.number === 2 && period.kick_off === null)) && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "HT",
                      half: 1,
                    });
                  }}
                >
                  HT Stats
                </GraphicButton>
              </>
            )}
          {!selectedPlayer &&
            period &&
            ((period.number > 1 && period.kick_off !== null) ||
              period.number > 2) && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "FT",
                      half: 2,
                    });
                  }}
                >
                  FT Stats
                </GraphicButton>
              </>
            )}

          {game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("TeamTicker", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    manager: homeManager,
                    start: true,
                  });
                }}
              >
                Home Team Ticker
              </GraphicButton>
            )}

          {game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("TeamTicker", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    manager: awayManager,
                    start: true,
                  });
                }}
              >
                Away Team Ticker
              </GraphicButton>
            )}

          {game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Lineup", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    manager: homeManager,
                    start: true,
                  });
                }}
              >
                Home Team List
              </GraphicButton>
            )}

          {game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Lineup", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    sky: sky,
                    kits: kits,
                    squads: game.lineup?.away,
                    manager: awayManager,
                    start: true,
                  });
                }}
              >
                Away Team List
              </GraphicButton>
            )}

          {game?.competition?.name !== "Super League" &&
            game?.competition?.name !== "World Club Challenge" &&
            game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Double Lineup Subs", {
                    game: game,
                    teams: teams,
                    players: players,
                    home_team: game.home_team,
                    away_team: game.away_team,
                    home_squad: game.lineup?.home,
                    away_squad: game.lineup?.away,
                    home_manager: homeManager,
                    away_manager: awayManager,
                  });
                }}
              >
                Team List Double Subs
              </GraphicButton>
            )}

          {(game?.competition?.name === "Super League" ||
            game?.competition?.name === "Super League OMO" ||
            game?.competition?.name === "World Club Challenge" ||
            game?.competition?.name === "URC") &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationHeads", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    manager: homeManager,
                  });
                }}
              >
                Home Formation
              </GraphicButton>
            )}

          {game?.competition?.name === "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    manager: homeManager,
                  });
                }}
              >
                Home Formation Without Heads
              </GraphicButton>
            )}

          {(game?.competition?.name === "Super League" ||
            game?.competition?.name === "Super League OMO" ||
            game?.competition?.name === "World Club Challenge" ||
            game?.competition?.name === "URC") &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationHeads", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    manager: awayManager,
                  });
                }}
              >
                Away Formation
              </GraphicButton>
            )}

          {game?.competition?.name === "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    manager: awayManager,
                  });
                }}
              >
                Away Formation Without Heads
              </GraphicButton>
            )}

          {game?.competition?.name !== "Super League" &&
            game?.competition?.name !== "World Club Challenge" &&
            game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationForwards", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home?.map((player) => {
                      return {
                        ...player,
                        oval_position: game?.home_player_stats?.find(
                          (p) => p.id === player.squad?.player?.oval_ID
                        )?.position?.name,
                      };
                    }),
                    formation: game.home_formation,
                    manager: homeManager,
                  });
                }}
              >
                Home Formation Forwards
              </GraphicButton>
            )}
          {game?.competition?.name !== "Super League" &&
            game?.competition?.name !== "World Club Challenge" &&
            game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationBacks", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home?.map((player) => {
                      return {
                        ...player,
                        oval_position: game?.home_player_stats?.find(
                          (p) => p.id === player.squad?.player?.oval_ID
                        )?.position?.name,
                      };
                    }),
                    formation: game.home_formation,
                    manager: homeManager,
                  });
                }}
              >
                Home Formation Backs
              </GraphicButton>
            )}

          {game?.competition?.name !== "Super League" &&
            game?.competition?.name !== "World Club Challenge" &&
            game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationForwards", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away?.map((player) => {
                      return {
                        ...player,
                        oval_position: game?.away_player_stats?.find(
                          (p) => p.id === player.squad?.player?.oval_ID
                        )?.position?.name,
                      };
                    }),
                    formation: game.away_formation,
                    manager: awayManager,
                  });
                }}
              >
                Away Formation Forwards
              </GraphicButton>
            )}

          {game?.competition?.name !== "Super League" &&
            game?.competition?.name !== "World Club Challenge" &&
            game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationBacks", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away?.map((player) => {
                      return {
                        ...player,
                        oval_position: game?.away_player_stats?.find(
                          (p) => p.id === player.squad?.player?.oval_ID
                        )?.position?.name,
                      };
                    }),
                    formation: game.away_formation,
                    manager: awayManager,
                  });
                }}
              >
                Away Formation Backs
              </GraphicButton>
            )}

          {game?.competition?.name !== "Super League" &&
            game?.competition?.name !== "World Club Challenge" &&
            game?.competition?.name !== "URC" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("FormationDoubleSubs", {
                    game: game,
                    teams: teams,
                    players: players,

                    home_team: game.home_team,
                    away_team: game.away_team,
                    home_squad: game.lineup?.home,
                    away_squad: game.lineup?.away,
                    home_manager: homeManager,
                    away_manager: awayManager,
                  });
                }}
              >
                Formation Double Subs
              </GraphicButton>
            )}

          {!selectedPlayer && game?.competition?.name === "URC" && (
            <WinPredictor previewGraphic={previewGraphic} game={game} />
          )}
          {!selectedPlayer &&
            period &&
            period.kick_off &&
            game?.competition?.name === "URC" && <ClockCountdown />}
        </GraphicsList>
      )}

      {view === 2 && (
        <GraphicsList>
          {game?.competition?.name === "Challenge Cup" && (
            <EPCRStandings previewGraphic={previewGraphic} game={game} />
          )}
          {game?.competition?.name === "URC" && (
            <URCStandings previewGraphic={previewGraphic} game={game} />
          )}
          {game?.competition?.name !== "Challenge Cup" &&
            game?.competition?.name !== "URC" && (
              <Standings previewGraphic={previewGraphic} game={game} />
            )}
        </GraphicsList>
      )}
      {view === 3 && (
        <GraphicsList>
          {DECISIONS.map((d) => {
            return (
              <GraphicButton
                green={d === decision}
                onClick={() => {
                  if (decision === d) {
                    setDecision("");
                    updateGraphic("CLOCK", {
                      type: "DECISION",
                      decision: "",
                    });
                  } else {
                    setDecision(d);
                    updateGraphic("CLOCK", {
                      type: "DECISION",
                      decision: d,
                    });
                  }
                }}
              >
                {d}
              </GraphicButton>
            );
          })}
        </GraphicsList>
      )}
      {view === 4 && (
        <Teams
          game={game}
          previewGraphic={previewGraphic}
          teams={teams}
          period={period}
          players={players}
          homeManager={homeManager}
          awayManager={awayManager}
        />
      )}
      {view === 5 && (
        <GraphicsList>
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("MatchStats", {
                    game: game,
                  });
                }}
              >
                CB - Match Stats
              </GraphicButton>
            )}
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "outside_backs",
                  });
                }}
              >
                CB - Outside Backs Match Stats
              </GraphicButton>
            )}
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "props",
                  });
                }}
              >
                CB - Middles Forwards Match Stats
              </GraphicButton>
            )}
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "half_backs",
                  });
                }}
              >
                CB - Half Backs Match Stats
              </GraphicButton>
            )}
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "back_row",
                  });
                }}
              >
                CB - 2nd Row Match Stats
              </GraphicButton>
            )}
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "hooker",
                  });
                }}
              >
                CB - Hooker Match Stats
              </GraphicButton>
            )}
          <Title>CB Left Edge Match Stats</Title>
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "left_edge",
                      team: game?.home_team,
                    });
                  }}
                >
                  Home
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "left_edge",
                      team: game?.away_team,
                    });
                  }}
                >
                  Away
                </SplitGraphicButton>
              </Row>
            )}
          <Title>CB Right Edge Match Stats</Title>
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "right_edge",
                      team: game?.home_team,
                    });
                  }}
                >
                  Home
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "right_edge",
                      team: game?.away_team,
                    });
                  }}
                >
                  Away
                </SplitGraphicButton>
              </Row>
            )}
          <Title>CB Middles Match Stats</Title>
          {!selectedPlayer &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "middles",
                      team: game?.home_team,
                    });
                  }}
                >
                  Home
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "middles",
                      team: game?.away_team,
                    });
                  }}
                >
                  Away
                </SplitGraphicButton>
              </Row>
            )}
        </GraphicsList>
      )}
      {graphicInPreview && (
        <CurrentGraphic
          style={{ bottom: window.hideHeader === true ? "130px" : "40px" }}
        >
          {graphicInPreview.name}
          <GraphicButton
            style={{ height: "40px", backgroundColor: "#383f4a" }}
            onClick={() => {
              clearPreview(graphicInPreview.name);
            }}
          >
            CLEAR PVW (CTRL + Q)
          </GraphicButton>

          <GraphicButton
            green
            style={{ height: "40px" }}
            onClick={() => {
              animateGraphic(graphicInPreview.name);
            }}
          >
            IN (F8) or (-)
          </GraphicButton>
          <GraphicButton
            red
            style={{ height: "40px" }}
            onClick={() => {
              animateOffGraphic(graphicInPreview.name);
            }}
          >
            OUT (F9) or (*)
          </GraphicButton>
        </CurrentGraphic>
      )}
    </Main>
  );
}

function Standings({ previewGraphic, teams, game }) {
  const { getTeams } = useContext(APIContext);

  return (
    <>
      <GraphicButton
        onClick={async () => {
          let teams = await getTeams({});
          console.log(teams);

          let data = await fetch(
            (window.ENV?.REACT_APP_RUGBY_API ||
              process.env.REACT_APP_RUGBY_API) + "/superleague/table"
          ).then((res) => res.json());
          let table = Object.keys(data?.data?.table)
            .filter((key) => key !== "deductions")
            .map((key) => {
              return { ...data?.data?.table[key], position: key };
            });

          previewGraphic("Standings", {
            data: table,
            teams,
            game,
            highlight: true,
          });
        }}
      >
        Standings (Highlight)
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          let teams = await getTeams({});
          console.log(teams);

          let data = await fetch(
            (window.ENV?.REACT_APP_RUGBY_API ||
              process.env.REACT_APP_RUGBY_API) + "/superleague/table"
          ).then((res) => res.json());
          let table = Object.keys(data?.data?.table)
            .filter((key) => key !== "deductions")
            .map((key) => {
              return { ...data?.data?.table[key], position: key };
            });

          previewGraphic("Standings", {
            data: table,
            teams,
            game,
          });
        }}
      >
        Standings (No Highlight)
      </GraphicButton>
    </>
  );
}
