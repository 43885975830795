export class CBTable {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CBTable";
    this.scene = "CBTable";
    this.data = [];
    this.downloadPage_top = 7210;
    this.downloadPage_bottom = 7211;
    this.page_top = 54;
    this.page_bottom = 55;
  }

  async preview({ options }) {
    this.onAir = false;
    this.downloadPage =
      options?.top || options?.custom
        ? this.downloadPage_top
        : this.downloadPage_bottom;
    this.page = options?.top ? this.page_top : this.page_bottom;
    let xml = await this.getVizPage({ page: this.downloadPage }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      let standings = options.standings.data.table.comp.group.team;

      if (options?.top) {
        standings = standings?.slice(0, 6);
        xml.payload.field.find((item) => {
          return item.attr.name === "005";
        }).value = "Top " + standings?.length;
      }
      if (options?.bottom) {
        standings = standings?.slice(standings?.length - 6, standings?.length);
        xml.payload.field.find((item) => {
          return item.attr.name === "005";
        }).value = "Bottom " + standings?.length;
      }
      if (options?.custom) {
        xml.payload.field.find((item) => {
          return item.attr.name === "005";
        }).value = "";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = standings?.length;

      standings?.map((row, index) => {
        let team = options?.teams?.find((t) => t?.opta_ID === row?.id);
        let tab_row = (index + 1)?.toString()?.padStart(2, "0");

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "0";
        }).value =
          options?.selectedTeams?.findIndex((t) => t === team?.opta_ID) > -1
            ? "2"
            : "1";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "11.active";
        }).value = "1";

        if (options.sod) {
          xml.payload.field.find((item) => {
            return item.attr.name === tab_row + "11";
          }).value = "0";
        } else {
          xml.payload.field.find((item) => {
            return item.attr.name === tab_row + "11";
          }).value =
            parseInt(row?.rank) < parseInt(row?.lastRank)
              ? "1"
              : parseInt(row?.rank) > parseInt(row?.lastRank)
              ? "-1"
              : "0";
        }

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "10";
        }).value = team?.variables?.badge || "";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "20";
        }).value = row?.rank;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "21";
        }).value = team?.short_name;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "22";
        }).value = row?.played;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "24";
        }).value = row?.points;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "23";
        }).value =
          row?.pointsdiff > 0 ? "+" + row?.pointsdiff : row?.pointsdiff;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "30";
        }).value =
          parseInt(row?.rank) ===
            parseInt(
              options?.game?.competition?.variables?.number_of_playoff_places
            ) ||
          parseInt(row?.rank) ===
            options.standings_length -
              parseInt(
                options?.game?.competition?.variables
                  ?.number_of_relegation_places
              )
            ? "1"
            : "0";
      });

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
