import moment from "moment";

export class LTRedCard {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "LTREDCARD";
    this.scene = "LTREDCARD";
    this.data = [];
    this.page = 7004;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value = options.team?.variables?.badge || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "101";
      }).value = options.show_number ? options.player?.shirt_number || "" : "";

      xml.payload.field.find((item) => {
        return item.attr.name === "102";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "104";
      }).value =
        "IMAGE*__SKY_SPORTS/GLOBALS/FLAGS/" +
        (options.player?.nationality?.code?.toLowerCase() ||
          options.player?.nationality?.opta_code?.toLowerCase() ||
          "");

      // xml.payload.field.find((item) => {
      //   return item.attr.name === "211";
      // }).value = moment().format("HH|mm|ss|DD|MM|YYYY");

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
