import moment from "moment";

export class Clock {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.time = 0;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.name = "CLOCK";
    this.scene = "Clock";
    this.data = [];
    this.home_score = 0;
    this.away_score = 0;
    this.period_number = 0;
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [
      { name: "Home Team Name", value: { text: options.game.home_team.code } },
      { name: "Away Team Name", value: { text: options.game.away_team.code } },
      { name: "Game Clock", value: { text: options.clock } },
      { name: "Ref Decision", value: { text: "" } },
    ];
    this.sendPreview(this.scene, 25, this.data, "IN");
    this.time++;
  }
  animate({ options }) {
    let score = this.getScore(options.game);
    this.onAir = true;
    this.added_time_on_air = false;
    this.data = [];
    let phase = options.game.home_phase;
    let metres_gained = options.game.metres_gained;

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.home_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Team Kit Pattern Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_pattern"
          ] || "",
      },
    });

    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Away Team Kit Pattern Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_pattern"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Competition Type",
      value: { text: options.game.competition?.competition_type || "" },
    });
    this.data.push({
      name: "Home Team Code",
      value: { text: options.game.home_team.code || "" },
    });
    this.data.push({
      name: "Away Team Code",
      value: { text: options.game.away_team.code || "" },
    });
    this.data.push({
      name: "Home Score",
      value: { text: score.home_score || 0 },
    });
    this.data.push({
      name: "Away Score",
      value: { text: score.away_score || 0 },
    });
    this.data.push({
      name: "Home Tries",
      value: { text: score.home_tries || 0 },
    });
    this.data.push({
      name: "Home Convs",
      value: { text: score.home_conv || 0 },
    });
    this.data.push({
      name: "Home Pen Kicks",
      value: { text: score.home_pen_kick || 0 },
    });
    this.data.push({
      name: "Home Pen Tries",
      value: { text: score.home_pen_try || 0 },
    });
    this.data.push({
      name: "Home Drop Goals",
      value: { text: score.home_drop_goal || 0 },
    });

    this.data.push({
      name: "Away Tries",
      value: { text: score.away_tries || 0 },
    });
    this.data.push({
      name: "Away Convs",
      value: { text: score.away_conv || 0 },
    });
    this.data.push({
      name: "Away Pen Kicks",
      value: { text: score.away_pen_kick || 0 },
    });
    this.data.push({
      name: "Away Pen Tries",
      value: { text: score.away_pen_try || 0 },
    });
    this.data.push({
      name: "Away Drop Goals",
      value: { text: score.away_drop_goal || 0 },
    });
    this.data.push({
      name: "Metres Gained",
      value: { text: metres_gained || 0 },
    });

    this.data.push({
      name: "Period",
      value: { text: options.period.number },
    });

    this.data.push({
      name: "Ref Decision",
      value: { text: "" },
    });

    this.data.push({
      name: "Clock Type",
      value: { text: options.clockType },
    });

    let pauseTime = 0;

    options.period.pause &&
      options.period.pause.forEach((pause) => {
        if (pause.end) {
          pauseTime += pause.end - pause.start;
        }
      });
    let latestPause =
      options.period.pause &&
      options.period.pause[options.period.pause.length - 1];
    if (latestPause && latestPause.end) {
      latestPause = null;
    }
    let time = this.kick_off + pauseTime;

    if (options.period.number === 2 && !options.period.kick_off) {
      time = 1000 * 60 * 40;
    } else if (options.period.number === 2) {
      time -= 1000 * 60 * 40;
    }
    if (options.period.number === 3) {
      time -= 1000 * 60 * 80;
    }
    let seconds = moment().valueOf() - time;

    this.data.push({
      name: "Game Clock",
      value: {
        action: latestPause ? "reset" : "start",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });

    let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
    }, 0);

    let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
    }, 0);
    let home_yellows = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev += curr.yellow_card ? 1 : 0);
    }, 0);

    let away_yellows = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev += curr.yellow_card ? 1 : 0);
    }, 0);
    this.home_reds_total = home_reds;
    this.away_reds_total = away_reds;
    this.home_yellows_total = home_yellows;
    this.away_yellows_total = away_yellows;
    this.home_score = score.home_score || 0;
    this.away_score = score.away_score || 0;
    this.period_number = options.period.number;
    this.kick_off = options.period.kick_off;
    this.phase_total = options.game.home_phase;
    this.home_tries = score.home_tries;
    this.home_conv = score.home_conv;
    this.home_pen_kick = score.home_pen_kick;
    this.home_pen_try = score.home_pen_try;
    this.home_drop_goal = score.home_drop_goal;
    this.away_tries = score.away_tries;
    this.away_conv = score.away_conv;
    this.away_pen_kick = score.away_pen_kick;
    this.away_pen_try = score.away_pen_try;
    this.away_pen_try = score.away_pen_try;

    this.data.push({
      name: "Home Red Cards",
      value: {
        text: home_reds || 0,
      },
    });
    this.data.push({
      name: "Away Red Cards",
      value: {
        text: away_reds || 0,
      },
    });
    this.data.push({
      name: "Home Yellow Cards",
      value: {
        text: home_yellows || 0,
      },
    });
    this.data.push({
      name: "Away Yellow Cards",
      value: {
        text: away_yellows || 0,
      },
    });
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.added_time_on_air = false;
    this.sendAnimateOff(this.scene, []);
  }

  update({ options }) {
    let score = this.getScore(options.game);
    this.data = [];

    if (options.type === "PAUSE") {
      this.pause({ options });
    } else if (options.type === "RESUME") {
      this.resume({ options });
    } else if (options.type === "PHASE") {
      this.phase({ options });
    } else if (options.type === "SPONSOR") {
      this.sponsor({ options });
    } else if (options.type === "DECISION") {
      this.decision({ options });
    } else if (options.type === "ADDED_TIME") {
      this.data.push({
        name: "Additional Time",
        value: {
          text: options.added_time,
        },
      });

      this.sendUpdate(this.scene, this.data);
    } else {
      let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
        return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
      }, 0);

      let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
        return (prev += curr.red_card || curr.dbl_yellow_card ? 1 : 0);
      }, 0);

      let home_yellows = options.game?.lineup?.home?.reduce((prev, curr) => {
        return (prev += curr.yellow_card ? 1 : 0);
      }, 0);

      let away_yellows = options.game?.lineup?.away?.reduce((prev, curr) => {
        return (prev += curr.yellow_card ? 1 : 0);
      }, 0);
      let phase = options.game.home_phase;
      let metres_gained = options.game.metres_gained;
      if (
        this.home_score !== (score.home_score || 0) ||
        this.away_score !== (score.away_score || 0) ||
        this.kick_off !== options.period.kick_off ||
        this.period_number !== options.period.number ||
        this.home_reds_total !== home_reds ||
        this.away_reds_total !== away_reds ||
        this.home_yellows_total !== home_yellows ||
        this.away_yellows_total !== away_yellows ||
        this.phase_total !== phase ||
        this.metres_gained_total !== metres_gained ||
        this.home_tries !== score.home_tries ||
        this.home_conv !== score.home_conv ||
        this.home_pen_kick !== score.home_pen_kick ||
        this.home_pen_try !== score.home_pen_try ||
        this.home_drop_goal !== score.home_drop_goal ||
        this.away_tries !== score.away_tries ||
        this.away_conv !== score.away_conv ||
        this.away_pen_kick !== score.away_pen_kick ||
        this.away_pen_try !== score.away_pen_try ||
        this.away_pen_try !== score.away_pen_try
      ) {
        this.data.push({
          name: "Ref Decision",
          value: { text: "" },
        });
        this.data.push({
          name: "Home Red Cards",
          value: {
            text: home_reds || 0,
          },
        });
        this.data.push({
          name: "Away Red Cards",
          value: {
            text: away_reds || 0,
          },
        });
        this.data.push({
          name: "Home Yellow Cards",
          value: {
            text: home_yellows || 0,
          },
        });
        this.data.push({
          name: "Away Yellow Cards",
          value: {
            text: away_yellows || 0,
          },
        });
        this.data.push({
          name: "Home Score",
          value: { text: score.home_score || 0 },
        });
        this.data.push({
          name: "Away Score",
          value: { text: score.away_score || 0 },
        });
        this.data.push({
          name: "Home Tries",
          value: { text: score.home_tries || 0 },
        });
        this.data.push({
          name: "Home Convs",
          value: { text: score.home_conv || 0 },
        });
        this.data.push({
          name: "Home Pen Kicks",
          value: { text: score.home_pen_kick || 0 },
        });
        this.data.push({
          name: "Home Pen Tries",
          value: { text: score.home_pen_try || 0 },
        });
        this.data.push({
          name: "Home Total Tries",
          value: { text: (score.home_tries || 0) + (score.home_pen_try || 0) },
        });
        this.data.push({
          name: "Home Drop Goals",
          value: { text: score.home_drop_goal || 0 },
        });

        this.data.push({
          name: "Away Tries",
          value: { text: score.away_tries || 0 },
        });
        this.data.push({
          name: "Away Convs",
          value: { text: score.away_conv || 0 },
        });
        this.data.push({
          name: "Away Pen Kicks",
          value: { text: score.away_pen_kick || 0 },
        });
        this.data.push({
          name: "Away Pen Tries",
          value: { text: score.away_pen_try || 0 },
        });
        this.data.push({
          name: "Away Drop Goals",
          value: { text: score.away_drop_goal || 0 },
        });
        this.data.push({
          name: "Away Total Tries",
          value: { text: (score.away_tries || 0) + (score.away_pen_try || 0) },
        });

        this.data.push({
          name: "Metres Gained",
          value: { text: metres_gained || 0 },
        });

        this.kick_off = options.period.kick_off;

        let pauseTime = 0;

        options.period.pause &&
          options.period.pause.forEach((pause) => {
            if (pause.end) {
              pauseTime += pause.end - pause.start;
            }
          });
        let latestPause =
          options.period.pause &&
          options.period.pause[options.period.pause.length - 1];
        if (latestPause && latestPause.end) {
          latestPause = null;
        }

        let clock = this.kick_off + pauseTime;
        if (options.period.number === 2 && !options.period.kick_off) {
          clock = 1000 * 60 * 40;
        } else if (options.period.number === 2) {
          clock -= 1000 * 60 * 40;
        }
        let seconds = moment().valueOf() - clock;
        if (!options.period.kick_off) {
          seconds = clock;

          this.data.push({
            name: "Game Clock",
            value: {
              action: "reset",
              time: this.getSeconds({
                period: options.period,
                game: options.game,
              }),
            },
          });
        } else if (latestPause) {
          this.data.push({
            name: "Game Clock",
            value: {
              action: "reset",
              time: this.getSeconds({
                period: options.period,
                game: options.game,
              }),
            },
          });
        } else {
          this.data.push({
            name: "Game Clock",
            value: {
              action: options.period.kick_off ? "start" : "stop",
              time: this.getSeconds({
                period: options.period,
                game: options.game,
              }),
            },
          });
        }

        this.data.push({
          name: "Period",
          value: { text: options.period.number },
        });

        this.sendUpdate(this.scene, this.data);
      }
      this.home_reds_total = home_reds;
      this.away_reds_total = away_reds;
      this.home_yellows_total = home_yellows;
      this.away_yellows_total = away_yellows;
      this.home_score = score.home_score || 0;
      this.away_score = score.away_score || 0;
      this.period_number = options.period.number;
      this.kick_off = options.period.kick_off;
      this.phase_total = options.game.home_phase;
      this.metres_gained_total = options.game.metres_gained;
      this.home_tries = score.home_tries;
      this.home_conv = score.home_conv;
      this.home_pen_kick = score.home_pen_kick;
      this.home_pen_try = score.home_pen_try;
      this.home_drop_goal = score.home_drop_goal;
      this.away_tries = score.away_tries;
      this.away_conv = score.away_conv;
      this.away_pen_kick = score.away_pen_kick;
      this.away_pen_try = score.away_pen_try;
      this.away_pen_try = score.away_pen_try;
    }
  }
  pause({ options }) {
    let pauseTime = 0;

    options.period.pause &&
      options.period.pause.forEach((pause) => {
        if (pause.end) {
          pauseTime += pause.end - pause.start;
        }
      });
    let latestPause =
      options.period.pause &&
      options.period.pause[options.period.pause.length - 1];
    if (latestPause && latestPause.end) {
      latestPause = null;
    }

    let time = this.kick_off + pauseTime;

    if (options.period.number === 2) {
      time -= 1000 * 60 * 40;
    }
    if (options.period.number === 3) {
      time -= 1000 * 60 * 80;
    }
    let seconds = moment().valueOf() - time;
    let data = [];

    data.push({
      name: "Game Clock",
      value: {
        action: "reset",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });
    this.sendUpdate(this.scene, data);
  }
  resume({ options }) {
    let pauseTime = 0;

    options.period.pause &&
      options.period.pause.forEach((pause) => {
        if (pause.end) {
          pauseTime += pause.end - pause.start;
        }
      });
    let latestPause =
      options.period.pause &&
      options.period.pause[options.period.pause.length - 1];
    if (latestPause && latestPause.end) {
      latestPause = null;
    }
    let time = this.kick_off + pauseTime;

    if (options.period.number === 2) {
      time -= 1000 * 60 * 40;
    }
    if (options.period.number === 3) {
      time -= 1000 * 60 * 80;
    }
    console.log(pauseTime);
    let seconds = moment().valueOf() - time;

    let data = [];

    data.push({
      name: "Game Clock",
      value: {
        action: "start",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });
    this.sendUpdate(this.scene, data);
  }

  phase({ options }) {
    let data = [];
    data.push({
      name: "Phase",
      value: {
        text: options.phase,
      },
    });
    this.sendUpdate(this.scene, data);
  }

  sponsor({ options }) {
    let data = [];
    data.push({
      name: "Sponsor",
      value: {
        text: options.sponsor,
      },
    });
    this.sendUpdate(this.scene, data);
  }

  decision({ options }) {
    let data = [];
    data.push({
      name: "Ref Decision",
      value: {
        text: options.decision,
      },
    });
    this.sendUpdate(this.scene, data);
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let home_tries =
      game?.home_scorers?.filter((g) => g.type === "try").length || 0;
    let home_conv =
      game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
    let home_pen_kick =
      game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let home_pen_try =
      game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let home_drop_goal =
      game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_tries =
      game?.away_scorers?.filter((g) => g.type === "try").length || 0;
    let away_conv =
      game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
    let away_pen_kick =
      game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let away_pen_try =
      game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let away_drop_goal =
      game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    return {
      home_score,
      away_score,
      home_tries,
      home_conv,
      home_pen_kick,
      home_pen_try,
      home_drop_goal,
      away_tries,
      away_conv,
      away_pen_kick,
      away_pen_try,
      away_drop_goal,
    };
  }

  getSeconds({ period, game }) {
    if (period.kick_off) {
      let pauseTime = 0;

      period.pause &&
        period.pause.forEach((pause) => {
          if (pause.end) {
            pauseTime += pause.end - pause.start;
          }
        });
      let latestPause = period.pause && period.pause[period.pause.length - 1];
      if (latestPause && latestPause.end) {
        latestPause = null;
      }
      let time = moment.duration(
        ((latestPause && latestPause.start) || Date.now()) -
          period.kick_off -
          pauseTime
      );

      let minutes = time.minutes() + time.hours() * 60;

      if (period.number === 1) {
        if (minutes >= 40 && game?.competition?.competition_type === "league") {
          minutes = 40;
          time = moment.duration(0);
        }
      } else if (period.number === 2) {
        minutes += 40;

        if (minutes >= 80 && game?.competition?.competition_type === "league") {
          minutes = 80;
          time = moment.duration(0);
        }
      } else if (period.number === 3) {
        minutes += 0;
      } else if (period.number === 4) {
        minutes += 5;
      }
      let mins = minutes;
      let secs = time.seconds();
      let total_seconds = mins * 60 + secs;
      return total_seconds * 1000;
    }
    if (period.number === 1) {
      return 0;
    } else if (period.number === 2) {
      return 40 * 60 * 1000;
    }
    return 0;
  }
}
