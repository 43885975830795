import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import Grid from "styled-components-grid";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { GraphicsContext } from "../../contexts/GraphicsContext";

const Main = styled(ScrollBar)`
  overflow-y: auto;
  height: 90vh;
`;
const ActionPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0.5em;
  margin-left: 0.5em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  margin-bottom: 10px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  > tbody > tr {
    background-color: rgb(32, 39, 49);
  }

  th {
    padding: 1em;
  }
  td {
    padding: 0.2em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    width: ${(props) => props.width};
    height: 30px;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: black;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #818283;
  text-align: left;
`;
const TR = styled.tr`
  > td {
    background-color: ${(props) => (props.highlight ? "#db0a41" : "")};
    border-top: ${(props) =>
      props.dots ? "1px dashed white" : " 2px solid rgb(24, 29, 37)"};
  }
`;
function SeasonRankings({ game }) {
  const { getSeasonStatRankingsBySeason } = useContext(APIContext);
  const { previewGraphic, graphics } = useContext(GraphicsContext);
  const [seasonStats, setSeasonStats] = useState();

  useEffect(() => {
    if (game) {
      getSeasonStatRankingsBySeason({ season_id: game?.season?._id }).then(
        (data) => {
          setSeasonStats(data);
        }
      );
    }
  }, [game, getSeasonStatRankingsBySeason]);
  return (
    <Main>
      <Grid>
        {seasonStats?.opta_player_stats?.map((stat) => {
          return (
            <Grid.Unit size={1 / 6} style={{ margin: "5px" }}>
              <ActionPanel>
                <Table>
                  <thead>
                    <tr>
                      <TH colSpan={3}>{stat?.type}</TH>
                      <TH>
                        <Button
                          onClick={() => {
                            previewGraphic("CBSEASONSTATS", {
                              category: stat?.type,
                              push_up:
                                graphics?.find((g) => g?.name === "CLOCK")
                                  ?.onAir ||
                                graphics?.find((g) => g?.name === "ScoreBug")
                                  ?.onAir,
                              players: stat?.players?.slice(0, 5),
                            });
                          }}
                        >
                          PVW
                        </Button>
                      </TH>
                    </tr>
                  </thead>
                  <tbody>
                    {stat?.players?.slice(0, 5)?.map((player) => {
                      return (
                        <TR>
                          <td>{player?.rank}</td>
                          <td>{player?.team?.code}</td>
                          <td>
                            {(player?.squad?.player?.first_name !== ""
                              ? player?.squad?.player?.first_name + " "
                              : "") + player?.squad?.player?.last_name}
                          </td>
                          <td class="value">{player?.value}</td>
                        </TR>
                      );
                    })}
                  </tbody>
                </Table>
              </ActionPanel>
            </Grid.Unit>
          );
        })}
      </Grid>
    </Main>
  );
}

export default SeasonRankings;
