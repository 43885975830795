import React, { useContext } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
function Tags({ game, homeSquad, awaySquad, previewGraphic }) {
  return (
    <Main>
      <GraphicButton
        onClick={() => {
          previewGraphic("AllTag", {
            game: game,
            build: true,
            homeLineup: game?.lineup?.home,
            awayLineup: game?.lineup?.away,
            home_squad: homeSquad,
            away_squad: awaySquad,
          });
        }}
      >
        All tags (1250+/1270+)
      </GraphicButton>

      {/* <GraphicButton
        onClick={() => {
          previewGraphic("GoalTag", {
            game: game,
            build: true,
            homeLineup: game?.lineup?.home,
            awayLineup: game?.lineup?.away,
          });
        }}
      >
        Try Tags (1251+)
      </GraphicButton>
      <GraphicButton
        onClick={() => {
          previewGraphic("OtherTag", {
            game: game,
            build: true,
            homeLineup: game?.lineup?.home,
            awayLineup: game?.lineup?.away,
          });
        }}
      >
        Sinbin/Cards/DG Tags (1270+)
      </GraphicButton> */}
    </Main>
  );
}

export default Tags;
