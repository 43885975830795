export class CarryingStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CarryingStats";
    this.scene = "CarryingStats";
    this.data = [];
    this.page = 7053;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value = `CARRYING
STATS`;
      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = "6";
      xml.payload.field.find((item) => {
        return item.attr.name === "006";
      }).value = options?.game?.home_team?.code || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "007";
      }).value = options?.game?.away_team?.code || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "013";
      }).value = "Completed sets";

      let home_total =
        parseInt(options?.game?.opta_home_team_stats?.["complete_sets"] || 0) +
        parseInt(options?.game?.opta_home_team_stats?.["incomplete_sets"] || 0);

      let away_total =
        parseInt(options?.game?.opta_away_team_stats?.["complete_sets"] || 0) +
        parseInt(options?.game?.opta_away_team_stats?.["incomplete_sets"] || 0);

      xml.payload.field.find((item) => {
        return item.attr.name === "015";
      }).value =
        (options?.game?.opta_home_team_stats?.["complete_sets"] || 0) +
        "/" +
        home_total;

      xml.payload.field.find((item) => {
        return item.attr.name === "016";
      }).value =
        (options?.game?.opta_away_team_stats?.["complete_sets"] || 0) +
        "/" +
        away_total;

      xml.payload.field.find((item) => {
        return item.attr.name === "023";
      }).value = "Carries";

      xml.payload.field.find((item) => {
        return item.attr.name === "025";
      }).value = options?.game?.opta_home_team_stats?.["carries"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "026";
      }).value = options?.game?.opta_away_team_stats?.["carries"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "033";
      }).value = "Metres";

      xml.payload.field.find((item) => {
        return item.attr.name === "035";
      }).value = (options?.game?.opta_home_team_stats?.["metres"] || 0) + "m";

      xml.payload.field.find((item) => {
        return item.attr.name === "036";
      }).value = (options?.game?.opta_away_team_stats?.["metres"] || 0) + "m";

      xml.payload.field.find((item) => {
        return item.attr.name === "043";
      }).value = "Post-contact metres";

      xml.payload.field.find((item) => {
        return item.attr.name === "045";
      }).value =
        (options?.game?.opta_home_team_stats?.["post_contact_metres"] || 0) +
        "m";

      xml.payload.field.find((item) => {
        return item.attr.name === "046";
      }).value =
        (options?.game?.opta_away_team_stats?.["post_contact_metres"] || 0) +
        "m";

      xml.payload.field.find((item) => {
        return item.attr.name === "053";
      }).value = "Errors";

      xml.payload.field.find((item) => {
        return item.attr.name === "055";
      }).value = options?.game?.opta_home_team_stats?.["errors"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "056";
      }).value = options?.game?.opta_away_team_stats?.["errors"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "063";
      }).value = "Offloads";

      xml.payload.field.find((item) => {
        return item.attr.name === "065";
      }).value = options?.game?.opta_home_team_stats?.["offload"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "066";
      }).value = options?.game?.opta_away_team_stats?.["offload"] || 0;

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
