export class PlayerCB {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "PLAYERCB";
    this.scene = "PLAYERCB";
    this.data = [];
    this.page = 7057;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "00101";
      }).value =
        options?.team?.variables?.alt_badge ||
        options?.team?.variables?.badge ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "002";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = options?.subline || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "0003";
      }).value = options?.subline === "" ? "0" : "1";

      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = options?.stats?.length;

      options?.stats?.map((stat, index) => {
        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (index + 1) + "3";
        }).value = stat?.name;

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + (index + 1) + "5";
        }).value = stat?.value;
      });

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
