export class SOFTable {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "SOFTable";
    this.scene = "SOFTable";
    this.data = [];
    this.downloadPage = 7205;
    this.page = 52;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.downloadPage }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      let standings = options.standings.data.table.comp.group.team;

      xml.payload.field.find((item) => {
        return item.attr.name === "00002";
      }).value = standings?.length;

      standings?.map((row, index) => {
        let team = options?.teams?.find((t) => t?.opta_ID === row?.id);
        let tab_row = (index + 1)?.toString()?.padStart(2, "0");

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "0";
        }).value =
          options?.selectedTeams?.findIndex((t) => t === team?.opta_ID) > -1
            ? "2"
            : "1";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "11.active";
        }).value = "1";

        if (options.sod) {
          xml.payload.field.find((item) => {
            return item.attr.name === tab_row + "11";
          }).value = "0";
        } else {
          xml.payload.field.find((item) => {
            return item.attr.name === tab_row + "11";
          }).value =
            parseInt(row?.rank) < parseInt(row?.lastRank)
              ? "1"
              : parseInt(row?.rank) > parseInt(row?.lastRank)
              ? "-1"
              : "0";
        }

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "20";
        }).value = row?.rank;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "21";
        }).value = team?.short_name;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "22";
        }).value = row?.played;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "24";
        }).value = row?.points;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "23";
        }).value =
          row?.pointsdiff > 0 ? "+" + row?.pointsdiff : row?.pointsdiff;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "30";
        }).value =
          parseInt(row?.rank) ===
            parseInt(
              options?.game?.competition?.variables?.number_of_playoff_places
            ) ||
          parseInt(row?.rank) ===
            options.standings_length -
              parseInt(
                options?.game?.competition?.variables
                  ?.number_of_relegation_places
              )
            ? "1"
            : "0";
      });

      await this.updateVizPage({
        page: this.page,
        data: xml,
        escape: false,
      }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
