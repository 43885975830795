import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { useContext } from "react";
import { useCallback } from "react";
import Select from "react-select";
import { GraphicsContext } from "../../contexts/GraphicsContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const HalfGraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 32%;
  font-size: 13px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  margin-top: 10px;
  background-color: #1c222b;
  border-bottom: 2px solid #202731;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
  margin-top: 1em;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1c222b",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#1c222b",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1c222b",
    borderColor: "#384454",
    boxShadow: "",
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#1c222b",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

function LatestScores({ show, game, previewGraphic }) {
  const { getScores, getCompetition } = useContext(APIContext);
  const { graphics } = useContext(GraphicsContext);
  const [games, setGames] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  useEffect(() => {
    let interval;
    interval = setInterval(async () => {
      await getData();
    }, 5000);
    getData();
    getCompetition()?.then((comps) => {
      setCompetitions(comps);
    });
    return () => {
      clearInterval(interval);
    };
  }, [selectedCompetition]);

  const getData = useCallback(async () => {
    let games = await getScores({ date: game?.date?.replace("Z", "") });

    games = games?.filter(
      (g) =>
        g?.competition?._id ===
          (selectedCompetition ?? game?.competition?._id) &&
        g?._id !== game?._id
    );

    setGames(games);
  }, [game, getScores, selectedCompetition]);

  return (
    <Main style={{ display: show ? "flex" : "none" }}>
      <GraphicButton
        onClick={async () => {
          previewGraphic("GameScoresTag", {
            game: game,
            build: true,
            games,
          });
        }}
      >
        Other Score Tags (160+)
      </GraphicButton>

      <GraphicButton
        onClick={async () => {
          previewGraphic("LatestScoresCB", {
            game: game,
            build: true,
            games,
            push_up:
              graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
              graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
          });
        }}
      >
        CB - Other Scores (85)
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          previewGraphic("GameScoresBug", {
            game: game,
            build: true,
            games,
          });
        }}
      >
        Score updates (150+)
      </GraphicButton>
      <SelectContainer
        styles={DropdownStyles}
        setSize={true}
        value={{
          value: selectedCompetition,
          label: competitions?.find(
            (e) => e._id === (selectedCompetition ?? game?.competition?._id)
          )?.name,
        }}
        options={competitions
          ?.filter((s) => s?.name && s?.name !== "")
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map((item, index) => {
            return {
              value: item._id,
              label: item.name,
            };
          })}
        onChange={(e) => {
          setSelectedCompetition(e.value);
          getData();
        }}
        placeholder="Stat"
      />
      {games?.map((game, index) => {
        return (
          <Column>
            {game?.home_team?.short_name} {game?.opta_scores?.home?.score} -{" "}
            {game?.opta_scores?.away?.score} {game?.away_team?.short_name}
            <Row>
              <HalfGraphicButton
                onClick={() => {
                  previewGraphic("GameScoresTag", {
                    game: game,
                    build: false,
                    games,
                    single: true,
                  });
                }}
              >
                Tag (160)
              </HalfGraphicButton>
              <HalfGraphicButton
                onClick={() => {
                  previewGraphic("GameScoresBug", {
                    game: game,
                    build: false,
                    games,
                    page: 150 + index,
                  });
                }}
              >
                Score Update ({150 + index})
              </HalfGraphicButton>
              <HalfGraphicButton
                onClick={async () => {
                  debugger;
                  //HOME CRAWL
                  await previewGraphic("TeamCrawl", {
                    game: game,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    other: true,
                  });
                  //AWAY CRAWL
                  await previewGraphic("TeamCrawl", {
                    game: game,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    other: true,
                  });
                }}
              >
                Team crawl (6960)
              </HalfGraphicButton>
            </Row>
          </Column>
        );
      })}
    </Main>
  );
}

export default LatestScores;
