import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Lineup from "./Lineup";
import { DataContext } from "../../contexts/DataContext";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import TeamStats from "./TeamStats";
import PlayerStats from "../PlayerStats/PlayerStats";
import TeamCalculatedStats from "../TeamStats/TeamCalculatedStats";
import OPTATeamStats from "../TeamStats/OPTATeamStats";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  border-bottom: 1px solid #dadde1;
  box-sizing: border-box;
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  /* padding: 1em; */
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 100%;
`;

const Lineups = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
`;

const Centre = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  background-color: #0e1219;
`;

const SubsConfirm = styled.div`
  display: flex;
`;

const TeamSubs = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;
const Button = styled.div`
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;

  margin-top: 0.5em;
  padding: 0.5em;
`;

export default function Game({
  game,
  setSelectedPlayer,
  selectedPlayer,
  goal,
  setGoal,
  optaLineup,
  countries,
  sky,
  setHomeManager,
  setAwayManager,
}) {
  const { update } = useContext(DataContext);
  const { clear } = useContext(ControlContext);
  const { updateGame } = useContext(APIContext);

  return (
    <Main>
      {game && game.home_team && game.away_team && (
        <Content>
          <ActionPanel>
            <Lineups>
              {game && (
                <Lineup
                  setTeamManager={setHomeManager}
                  countries={countries}
                  optaLineup={optaLineup}
                  game={game}
                  kickedOff={true}
                  kickOffTime={game.actual_kick_off}
                  team={game.home_team}
                  lineup={game.lineup?.home || game.opta_lineup?.home}
                  scorers={[
                    ...game?.home_scorers,
                    ...game?.away_scorers?.filter((g) => g.own_goal),
                  ]}
                  selectedPlayer={selectedPlayer}
                  selectPlayer={(player) => {
                    if (goal) {
                      //setSelectedPlayer({ ...player, team: game.home_team });
                      let own_goal = goal.team._id !== game.home_team._id;
                      let newScorers = [
                        ...(own_goal ? game.away_scorers : game.home_scorers),
                      ];
                      let index = newScorers.findIndex(
                        (g) => g._id === goal._id
                      );
                      if (index > -1) {
                        setGoal();
                        let period =
                          game.live_data &&
                          game.live_data.periods &&
                          game.live_data.periods.sort(
                            (a, b) => b.number - a.number
                          )[0];

                        newScorers[index] = {
                          ...newScorers[index],
                          player: player.squad._id,
                          time: goal.time,
                          own_goal: goal.team._id !== game.home_team._id,
                          penalty: goal.penalty,
                          period: period.number,
                        };

                        if (own_goal) {
                          updateGame({
                            id: game._id,
                            away_scorers: newScorers,
                          });
                        } else {
                          updateGame({
                            id: game._id,
                            home_scorers: newScorers,
                          });
                        }
                      }
                    } else {
                      if (player) {
                        setSelectedPlayer({ ...player, team: game.home_team });
                      } else {
                        setSelectedPlayer();
                      }
                    }
                  }}
                  removeGoal={(goal) => {
                    let new_scorers = game?.home_scorers?.filter(
                      (s) => s.player !== goal.player || s.time !== goal.time
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: new_scorers,
                    });
                  }}
                />
              )}
              <Centre>
                <TeamStats game={game} />
                {selectedPlayer && (
                  <PlayerStats game={game} player={selectedPlayer} />
                )}
                {!selectedPlayer && <TeamCalculatedStats game={game} />}
                {(game?.opta_home_team_stats || game?.opta_away_team_stats) && (
                  <OPTATeamStats game={game} />
                )}
              </Centre>
              {game && (
                <Lineup
                  setTeamManager={setAwayManager}
                  countries={countries}
                  optaLineup={optaLineup}
                  game={game}
                  kickedOff={true}
                  kickOffTime={game.actual_kick_off}
                  team={game.away_team}
                  scorers={[
                    ...game?.away_scorers,
                    ...game?.home_scorers?.filter((g) => g.own_goal),
                  ]}
                  lineup={game.lineup?.away || game.opta_lineup?.away}
                  selectedPlayer={selectedPlayer}
                  selectPlayer={(player) => {
                    if (goal) {
                      //setSelectedPlayer({ ...player, team: game.away_team });

                      let own_goal = goal.team._id !== game.away_team._id;
                      let newScorers = [
                        ...(own_goal ? game.home_scorers : game.away_scorers),
                      ];
                      let index = newScorers.findIndex(
                        (g) => g._id === goal._id
                      );
                      if (index > -1) {
                        setGoal();
                        let period =
                          game.live_data &&
                          game.live_data.periods &&
                          game.live_data.periods.sort(
                            (a, b) => b.number - a.number
                          )[0];

                        newScorers[index] = {
                          ...newScorers[index],
                          player: player.squad._id,
                          time: goal.time,
                          own_goal: goal.team._id !== game.away_team._id,
                          penalty: goal.penalty,
                          period: period.number,
                        };

                        if (own_goal) {
                          updateGame({
                            id: game._id,
                            home_scorers: newScorers,
                          });
                        } else {
                          updateGame({
                            id: game._id,
                            away_scorers: newScorers,
                          });
                        }
                      }
                    } else {
                      if (player) {
                        setSelectedPlayer({ ...player, team: game.away_team });
                      } else {
                        setSelectedPlayer();
                      }
                    }
                  }}
                  removeGoal={(goal) => {
                    let new_scorers = game?.away_scorers?.filter(
                      (s) => s.player !== goal.player || s.time !== goal.time
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: new_scorers,
                    });
                  }}
                />
              )}
            </Lineups>
          </ActionPanel>
        </Content>
      )}
    </Main>
  );
}
