import React, { useContext, useState } from "react";
import styled from "styled-components";
import Stat from "./Stat";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { APIContext } from "../../contexts/APIContext";
import { UIContext } from "../../contexts/UIContext";

const defaultStats = {
  possessionPercentage: 0,
  touches: 0,
  interception: 0,
  lostCorners: 0,
  fkFoulWon: 0,
  totalPass: 0,
  accuratePass: 0,
  passAccuracy: 0,
  totalPassInclCross: 0,
  totalTackle: 0,
  wonCorners: 0,
  cornerTaken: 0,
  totalCross: 0,
  totalThrows: 0,
  fkFoulLost: 0,
  totalYellowCard: 0,
  totalYellowCardOnPitch: 0,
  totalScoringAtt: 0,
  shotOffTarget: 0,
  shotAccuracy: 0,
  attRfTotal: 0,
  attIbox: 0,
  blockedScoringAtt: 0,
  attHdTotal: 0,
  wonTackle: 0,
  attLfTotal: 0,
  totalOffside: 0,
  ontargetScoringAtt: 0,
  goalKicks: 0,
  attFreekickTotal: 0,
  secondYellow: 0,
  totalRedCard: 0,
  secondYellowOnPitch: 0,
  totalRedCardOnPitch: 0,
  penaltyWon: 0,
  goals: 0,
  attRfGoal: 0,
  penaltyTaken: 0,
  attIboxGoal: 0,
  assistPenaltyWon: 0,
};
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: ${(props) =>
    props.selected ? "#db0a41" : props.green ? "#0adb93" : "#202731"};
  color: ${(props) => (props.green ? "#000" : "#fff")};
  justify-content: center;
  align-items: center;
  height: 55%;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
    cursor: pointer;
  }
`;
export default function TeamStats({ game }) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { previewGraphic } = useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);
  const [showPhase, setShowPhase] = useState(true);
  const [localPhase, setLocalPhase] = useState(0);
  return (
    <Main>
      <Row>
        <Stat
          game={game}
          category={"Tries"}
          matchStats={[]}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "try").length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "try").length || 0,
          }}
          updateHomeStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "try" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "try")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "try" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "try")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Conversion"}
          matchStats={[]}
          // updateStat={updateStat}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "conversion")
                .length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "conversion")
                .length || 0,
          }}
          updateHomeStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "conversion" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "conversion")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "conversion" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "conversion")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Penalty Kick"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "penalty_kick")
                .length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "penalty_kick")
                .length || 0,
          }}
          updateHomeStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "penalty_kick" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "penalty_kick")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "penalty_kick" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "penalty_kick")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Penalty Try"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "penalty_try")
                .length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "penalty_try")
                .length || 0,
          }}
          updateHomeStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "penalty_try" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "penalty_try")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "penalty_try" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "penalty_try")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        />
      </Row>
      <Row>
        <Stat
          game={game}
          category={"Drop Goal"}
          matchStats={[]}
          // updateStat={updateStat}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home:
              game?.home_scorers?.filter((g) => g.type === "drop_goal")
                .length || 0,
            away:
              game?.away_scorers?.filter((g) => g.type === "drop_goal")
                .length || 0,
          }}
          updateHomeStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.home_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  home_scorers: [
                    ...game.home_scorers,
                    { time: time, _id, type: "drop_goal" },
                  ],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "drop_goal")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
          updateAwayStat={({ e }) => {
            switch (e.button) {
              case 0:
                let period =
                  game.live_data &&
                  game.live_data?.periods &&
                  game.live_data?.periods.sort(
                    (a, b) => b.number - a.number
                  )[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 1) {
                  if (time > 1000 * 60 * 40) {
                    time = 1000 * 60 * 40;
                  }
                }
                if (period.number === 2) {
                  time += 1000 * 60 * 40;
                  if (time > 1000 * 60 * 80) {
                    time = 1000 * 60 * 80;
                  }
                }

                let _id = Date.now();
                setGoal({
                  team: game.away_team,
                  time: time + 1000 * 60,
                  _id: _id,
                });
                updateGame({
                  id: game._id,
                  away_scorers: [
                    ...game.away_scorers,
                    { time: time, _id, type: "drop_goal" },
                  ],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];

                  let latestGoals = newScorers
                    .filter((g) => g.type === "drop_goal")
                    .sort((a, b) => {
                      return b.time - a.time;
                    });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        />
      </Row>
    </Main>
  );
}
