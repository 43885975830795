export class GuestName {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "GuestName";
    this.scene = "GuestName";
    this.data = [];
    this.page = 7006;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = 1;

      xml.payload.field.find((item) => {
        return item.attr.name === "0005";
      }).value =
        options?.story?.badge && options?.story?.badge !== "" ? "1" : "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value = options?.story?.badge || "";
      xml.payload.field.find((item) => {
        return item.attr.name === "102";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "101";
      }).value = "";

      xml.payload.field.find((item) => {
        return item.attr.name === "200";
      }).value = options.story?.story;

      xml.payload.field.find((item) => {
        return item.attr.name === "300";
      }).value = "";

      xml.payload.field.find((item) => {
        return item.attr.name === "104";
      }).value = "";

      xml.payload.field.find((item) => {
        return item.attr.name === "109.active";
      }).value = options?.player?.ss_logo ? "1" : "0";

      await this.updateVizPage({
        page: this.page,
        data: xml,
      }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
