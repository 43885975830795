export class Formation {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "FormationHeads";
    this.scene = "Formation";
    this.data = [];
    this.download_page = 7100;
    this.home_page = 42;
    this.away_page = 44;
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = [];
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      if (options?.game?.home_team?._id === options?.team?._id) {
        this.page = this.home_page;
      } else if (options?.game?.away_team?._id === options?.team?._id) {
        this.page = this.away_page;
      }
      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = options.team?.name || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "400";
      }).value =
        options.team?.variables?.alt_badge ||
        options.team?.variables?.badge ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "4005";
      }).value = options.manager?.player?.title || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "401";
      }).value =
        options.manager?.player?.first_name_upper ||
        options.manager?.player?.first_name?.toUpperCase() ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "402";
      }).value =
        options.manager?.player?.last_name_upper ||
        options.manager?.player?.last_name?.toUpperCase() ||
        "";

      new Array(13).fill(1).forEach((_, index) => {
        let row = index + 1;
        let tab_row = row?.toString().padStart("2", "0");

        let player;
        if (
          row <=
          (options?.game?.competition?.variables?.number_of_players_on_field ??
            13)
        ) {
          player = options.squads?.find((s) => s.order === row);
        }

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "1";
        }).value = player?.squad?.shirt_number || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "2";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "3";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });

      new Array(5).fill(1).forEach((_, index) => {
        let row = index + 1;
        let tab_row = "6" + row;

        let player = options.squads?.find(
          (s) =>
            s.order ===
            row +
              (options?.game?.competition?.variables
                ?.number_of_players_on_field ?? 13)
        );

        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "1";
        }).value = player?.squad?.shirt_number || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";
        xml.payload.field.find((item) => {
          return item.attr.name === tab_row + "2";
        }).value = name;
      });
    }

    await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
      console.log(err);
    });

    await this.sendPreview("page:read " + this.page);
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
