import React, { useState } from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  background-color: #1c222b;
  border-bottom: 2px solid #202731;
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 80%;
  padding: 0.5em;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
  :hover {
    background-color: #db0a41;
  }
`;

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;

  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const Row = styled.div`
  display: flex;
`;
export default function Stat({
  category,
  value,
  previewGraphic,
  game,
  player,
  selectedStat,
  setSelectedStat,
  updateGraphic,
}) {
  const [onClock, setOnClock] = useState();
  return (
    <Main>
      <StatDiv
        onClick={() => {
          setSelectedStat(category);
          // previewGraphic("PlayerStat", {
          //   player: player,
          //   subtext: value + " " + category,
          //   team: player?.team,
          //   game,
          //   category,
          //   value,
          //   type: "BUG",
          // });
        }}
      >
        <Category>{category}</Category>
        <Value
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {parseInt(value)}
        </Value>
      </StatDiv>
      {selectedStat === category && (
        <Row>
          <GraphicButton
            green
            onClick={() => {
              previewGraphic("PlayerStat", {
                player: player,
                subtext: value + " " + category,
                team: player?.team,
                game,
                category,
                value:
                  parseInt(value) + (category === "Tackle success" ? "%" : ""),
                type: "BUG",
              });
            }}
          >
            BUG/PIC/STAT
          </GraphicButton>
          <GraphicButton
            green
            onClick={() => {
              previewGraphic("PlayerStat", {
                player: player,
                subtext: value + " " + category,
                team: player?.team,
                game,
                category,
                value:
                  parseInt(value) + (category === "Tackle success" ? "%" : ""),
                type: "LT",
              });
            }}
          >
            LT STAT
          </GraphicButton>

          {!onClock && (
            <GraphicButton
              green
              onClick={() => {
                setOnClock(true);
                updateGraphic("CLOCK", {
                  type: "PENALTY_TEXT",
                  caption:
                    player?.first_name +
                    " " +
                    player?.last_name +
                    " - " +
                    category +
                    ": " +
                    parseInt(value) +
                    (category === "Tackle success" ? "%" : ""),
                  team:
                    player?.team?._id === game?.home_team?._id
                      ? "1"
                      : player?.team?._id === game?.away_team?._id
                      ? "2"
                      : "0",
                });
              }}
            >
              ON CLOCK
            </GraphicButton>
          )}
          {onClock && (
            <GraphicButton
              red
              onClick={() => {
                setOnClock();
                updateGraphic("CLOCK", {
                  type: "PENALTY_TEXT",
                  caption: "",
                  team:
                    player?.team?._id === game?.home_team?._id
                      ? "1"
                      : player?.team?._id === game?.away_team?._id
                      ? "2"
                      : "0",
                });
              }}
            >
              OFF CLOCK
            </GraphicButton>
          )}
        </Row>
      )}
    </Main>
  );
}
