import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Lineup from "../Lineup/Lineup";
import { DataContext } from "../../contexts/DataContext";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import TeamStats from "../TeamStats/TeamStats";
import PlayerStats from "../PlayerStats/PlayerStats";
import TeamCalculatedStats from "../TeamStats/TeamCalculatedStats";
import TeamStatsUnion from "../TeamStats/TeamStatsUnion";
import PlayerSeasonStats from "../PlayerStats/PlayerSeasonStats";
import OPTATeamStats from "../TeamStats/OPTATeamStats";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import Squad from "../Lineup/Squad";
import moment from "moment";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { Button } from "../../Theme/Hyper";
import TopPerformers from "../TeamStats/TopPerformers";
import Tags from "../Tags/Tags";
import LatestScores from "../LatestScores/LatestScores";
import Tables from "../Tables/Tables";
import useLocalStorage from "../../Hooks/useLocalStorage";
import CornerBoxes from "../CornerBoxes/CornerBoxes";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  border-bottom: 1px solid #dadde1;
  box-sizing: border-box;
  font-size: 1.5em;
  width: 100%;
  padding: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  /* padding: 1em; */
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 100%;
`;

const Lineups = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
`;

const Centre = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  background-color: #0e1219;
`;

const SubsConfirm = styled.div`
  display: flex;
`;

const TeamSubs = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;
const NavButton = styled(Button)`
  padding: 5px;
  margin: 0.3em;
  margin-top: 0.5em;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  justify-content: space-evenly;
`;

export default function Game({
  game,
  setSelectedPlayer,
  selectedPlayer,
  goal,
  setGoal,
  optaLineup,
  countries,
  sky,
  setHomeManager,
  setAwayManager,
  homeSubsMade,
  setHomeSubsMade,
  awaySubsMade,
  setAwaySubsMade,
}) {
  const { update } = useContext(DataContext);
  const { previewGraphic, updateGraphic } = useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);
  const { view, selectedTeam } = useContext(UIContext);
  const [statView, setStatView] = useState(0);
  const [numberOfTackles, setNumberOfTackles] = useLocalStorage(
    "number-of-tackles",
    0
  );

  return (
    <Main>
      {game && game.home_team && game.away_team && (
        <Content>
          <ActionPanel>
            <Lineups>
              {(view !== VIEW_CONSTS.squad ||
                selectedTeam?._id !== game?.home_team?._id) &&
                game && (
                  <Lineup
                    selecting_goal={goal}
                    setTeamManager={setHomeManager}
                    countries={countries}
                    optaLineup={optaLineup}
                    game={game}
                    kickedOff={true}
                    kickOffTime={game.actual_kick_off}
                    team={game.home_team}
                    lineup={game.lineup?.home || game.opta_lineup?.home}
                    scorers={[
                      ...game?.home_scorers,
                      ...game?.away_scorers?.filter((g) => g.own_goal),
                    ]}
                    selectedPlayer={selectedPlayer}
                    selectPlayer={(player) => {
                      if (goal) {
                        //setSelectedPlayer({ ...player, team: game.home_team });
                        let own_goal = goal.team._id !== game.home_team._id;
                        let newScorers = [
                          ...(own_goal ? game.away_scorers : game.home_scorers),
                        ];
                        let index = newScorers.findIndex(
                          (g) => g._id === goal._id
                        );
                        if (index > -1) {
                          setGoal();
                          let period =
                            game.live_data &&
                            game.live_data.periods &&
                            game.live_data.periods.sort(
                              (a, b) => b.number - a.number
                            )[0];

                          newScorers[index] = {
                            ...newScorers[index],
                            player: player.squad._id,
                            time: goal.time,
                            game_time_minutes: goal.game_time_minutes,
                            game_time_seconds: goal.game_time_seconds,
                            own_goal: goal.team._id !== game.home_team._id,
                            penalty: goal.penalty,
                            period: period.number,
                          };

                          let time = moment.duration(goal.time);

                          if (newScorers[index]?.type === "try") {
                            previewGraphic("PlayerScored", {
                              goal: goal,
                              player: player,
                              time: time.minutes() + time.hours() * 60,
                              game_time_minutes: goal.game_time_minutes,
                              game_time_seconds: goal.game_time_seconds,
                              seconds: time.seconds(),
                              team: goal.team,
                              game: { ...game, home_scorers: newScorers },
                              type: goal.type,
                            });
                          } else if (
                            newScorers[index].type === "conversion" ||
                            newScorers[index].type === "missed_conversion" ||
                            newScorers[index].type === "penalty_kick" ||
                            newScorers[index].type === "missed_penalty_kick"
                          ) {
                            let made =
                              newScorers?.filter((g) => {
                                return (
                                  g.player &&
                                  newScorers[index]?.player &&
                                  g?.player === newScorers[index]?.player &&
                                  (g?.type === "conversion" ||
                                    g?.type === "penalty_kick")
                                );
                              })?.length || 0;
                            let missed =
                              newScorers?.filter((g) => {
                                return (
                                  g.player &&
                                  newScorers[index]?.player &&
                                  g?.player === newScorers[index]?.player &&
                                  (g?.type === "missed_conversion" ||
                                    g?.type === "missed_penalty_kick")
                                );
                              })?.length || 0;

                            previewGraphic("PlayerStat", {
                              player: player,
                              subtext:
                                "goals  -  " +
                                made +
                                "/" +
                                (made + missed) +
                                "   (" +
                                Math.floor((made / (made + missed)) * 100) +
                                "%)",
                              team: goal.team,
                              game,
                              category: "goals",
                              value:
                                made +
                                "/" +
                                (made + missed) +
                                "   (" +
                                Math.floor((made / (made + missed)) * 100) +
                                "%)",
                              type: "LT",
                            });
                          }

                          if (own_goal) {
                            updateGame({
                              id: game._id,
                              away_scorers: newScorers,
                            });
                          } else {
                            updateGame({
                              id: game._id,
                              home_scorers: newScorers,
                            });
                          }
                        }
                      } else {
                        if (player) {
                          setSelectedPlayer({
                            ...player,
                            team: game.home_team,
                          });
                        } else {
                          setSelectedPlayer();
                        }
                      }
                    }}
                    removeGoal={(goal) => {
                      let new_scorers = game?.home_scorers?.filter(
                        (s) => s._id !== goal._id
                      );
                      updateGame({
                        id: game._id,
                        home_scorers: new_scorers,
                      });
                    }}
                    subsMade={homeSubsMade}
                    setSubsMade={setHomeSubsMade}
                    failedHIA={({ squad_id }) => {
                      let newLineup = JSON.parse(
                        JSON.stringify(game?.lineup?.home)
                      );
                      let index = newLineup?.findIndex(
                        (p) => p?.squad?._id === squad_id
                      );
                      if (index > -1) {
                        newLineup[index].hia = false;
                      }
                      newLineup = {
                        home: newLineup,
                        away: game.lineup.away,
                      };

                      updateGame({
                        id: game._id,
                        lineup: newLineup,
                      });
                      setHomeSubsMade(homeSubsMade + 1);
                    }}
                    passedHIA={({ squad_id }) => {
                      let newLineup = JSON.parse(
                        JSON.stringify(game?.lineup?.home)
                      );
                      let index = newLineup?.findIndex(
                        (p) => p?.squad?._id === squad_id
                      );
                      if (index > -1) {
                        newLineup[index].hia = false;
                      }
                      newLineup = {
                        home: newLineup,
                        away: game.lineup.away,
                      };

                      updateGame({
                        id: game._id,
                        lineup: newLineup,
                      });
                    }}
                  />
                )}
              {view === VIEW_CONSTS.squad &&
                selectedTeam._id === game.home_team._id && (
                  <Squad
                    countries={countries}
                    game={game}
                    team={game.home_team}
                    lineup={game.lineup?.home || game.opta_lineup?.home}
                    selectedPlayer={selectedPlayer}
                    selectPlayer={(player) => {
                      if (player) {
                        setSelectedPlayer({ ...player, team: game.home_team });
                      } else {
                        setSelectedPlayer();
                      }
                    }}
                  />
                )}

              <Centre>
                <TeamStats game={game} />
                {(window.ENV?.REACT_APP_CATALANS === "true" ||
                  process.env.REACT_APP_CATALANS === "true") && (
                  <Row>
                    Tackles
                    <Button
                      onClick={() => {
                        updateGraphic("CLOCK", {
                          type: "METRES_OFF",
                          metres: "",
                        });
                        updateGraphic("CLOCK", {
                          type: "PHASE",
                          phase: Math.max(0, numberOfTackles - 1),
                        });
                        setNumberOfTackles(Math.max(0, numberOfTackles - 1));
                      }}
                    >
                      -
                    </Button>
                    {numberOfTackles}
                    <Button
                      onClick={() => {
                        updateGraphic("CLOCK", {
                          type: "METRES_OFF",
                          metres: "",
                        });
                        updateGraphic("CLOCK", {
                          type: "PHASE",
                          phase: Math.min(6, numberOfTackles + 1),
                        });
                        if (Math.min(6, numberOfTackles + 1) === 6) {
                          setNumberOfTackles(0);
                        } else {
                          setNumberOfTackles(Math.min(6, numberOfTackles + 1));
                        }
                      }}
                    >
                      +
                    </Button>
                    <Button
                      onClick={() => {
                        setNumberOfTackles(0);
                        updateGraphic("CLOCK", {
                          type: "METRES_OFF",
                          metres: "",
                        });
                        updateGraphic("CLOCK", {
                          type: "PHASE",
                          phase: 6,
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => {
                        setNumberOfTackles(0);
                        updateGraphic("CLOCK", {
                          type: "METRES_OFF",
                          metres: "",
                        });
                        updateGraphic("CLOCK", {
                          type: "PHASE",
                          phase: 0,
                        });
                      }}
                    >
                      Zero
                    </Button>
                  </Row>
                )}
                {selectedPlayer && (
                  <>
                    Player Season stats
                    <PlayerSeasonStats game={game} player={selectedPlayer} />
                    Player Game Stats
                    <PlayerStats game={game} player={selectedPlayer} />
                  </>
                )}

                {!selectedPlayer &&
                  game?.competition?.competition_type === "union" && (
                    <TeamStatsUnion game={game} />
                  )}
                {!selectedPlayer && (
                  <Tabs>
                    <NavButton
                      selected={statView === 0 ? true : false}
                      onClick={() => {
                        setStatView(0);
                      }}
                    >
                      TEAM STATS
                    </NavButton>
                    <NavButton
                      selected={statView === 1 ? true : false}
                      onClick={() => {
                        setStatView(1);
                      }}
                    >
                      TOP PERFORMERS
                    </NavButton>
                    <NavButton
                      selected={statView === 2 ? true : false}
                      onClick={() => {
                        setStatView(2);
                      }}
                    >
                      OTHER GAMES
                    </NavButton>
                    <NavButton
                      selected={statView === 4 ? true : false}
                      onClick={() => {
                        setStatView(4);
                      }}
                    >
                      MANUAL CB's
                    </NavButton>
                    {/* <NavButton
                      selected={statView === 3 ? true : false}
                      onClick={() => {
                        setStatView(3);
                      }}
                    >
                      TABLES
                    </NavButton> */}
                  </Tabs>
                )}
                {!selectedPlayer &&
                  statView === 0 &&
                  game?.competition?.competition_type === "league" &&
                  game?.competition?.variables?.logging_type === "manual" && (
                    <TeamCalculatedStats game={game} />
                  )}

                {!selectedPlayer &&
                  (game?.opta_home_team_stats || game?.opta_away_team_stats) &&
                  statView === 0 &&
                  game?.competition?.variables?.logging_type !== "manual" && (
                    <OPTATeamStats game={game} />
                  )}

                {!selectedPlayer &&
                  (game?.opta_home_player_stats ||
                    game?.opta_away_player_stats ||
                    game?.competition?.variables?.logging_type === "manual") &&
                  statView === 1 && (
                    <TopPerformers
                      game={game}
                      previewGraphic={previewGraphic}
                    />
                  )}
                {!selectedPlayer && (
                  <LatestScores
                    show={statView === 2}
                    game={game}
                    previewGraphic={previewGraphic}
                  />
                )}
                {!selectedPlayer && statView === 3 && (
                  <Tables game={game} previewGraphic={previewGraphic} />
                )}
                {!selectedPlayer && statView === 4 && (
                  <CornerBoxes game={game} previewGraphic={previewGraphic} />
                )}
              </Centre>
              {(view !== VIEW_CONSTS.squad ||
                selectedTeam?._id !== game?.away_team?._id) &&
                game && (
                  <Lineup
                    selecting_goal={goal}
                    setTeamManager={setAwayManager}
                    countries={countries}
                    optaLineup={optaLineup}
                    game={game}
                    kickedOff={true}
                    kickOffTime={game.actual_kick_off}
                    team={game.away_team}
                    scorers={[
                      ...game?.away_scorers,
                      ...game?.home_scorers?.filter((g) => g.own_goal),
                    ]}
                    lineup={game.lineup?.away || game.opta_lineup?.away}
                    selectedPlayer={selectedPlayer}
                    selectPlayer={(player) => {
                      if (goal) {
                        //setSelectedPlayer({ ...player, team: game.away_team });

                        let own_goal = goal.team._id !== game.away_team._id;
                        let newScorers = [
                          ...(own_goal ? game.home_scorers : game.away_scorers),
                        ];
                        let index = newScorers.findIndex(
                          (g) => g._id === goal._id
                        );
                        if (index > -1) {
                          setGoal();
                          let period =
                            game.live_data &&
                            game.live_data.periods &&
                            game.live_data.periods.sort(
                              (a, b) => b.number - a.number
                            )[0];

                          newScorers[index] = {
                            ...newScorers[index],
                            player: player.squad._id,
                            time: goal.time,
                            game_time_minutes: goal.game_time_minutes,
                            game_time_seconds: goal.game_time_seconds,
                            own_goal: goal.team._id !== game.away_team._id,
                            penalty: goal.penalty,
                            period: period.number,
                          };

                          let time = moment.duration(goal.time);

                          if (newScorers[index]?.type === "try") {
                            previewGraphic("PlayerScored", {
                              goal: goal,
                              player: player,
                              time: time.minutes() + time.hours() * 60,
                              game_time_minutes: goal.game_time_minutes,
                              game_time_seconds: goal.game_time_seconds,
                              seconds: time.seconds(),
                              team: goal.team,
                              game: { ...game, away_scorers: newScorers },
                              type: goal.type,
                            });
                          } else if (
                            newScorers[index].type === "conversion" ||
                            newScorers[index].type === "missed_conversion" ||
                            newScorers[index].type === "penalty_kick" ||
                            newScorers[index].type === "missed_penalty_kick"
                          ) {
                            let made =
                              newScorers?.filter((g) => {
                                return (
                                  g.player &&
                                  newScorers[index]?.player &&
                                  g?.player === newScorers[index]?.player &&
                                  (g?.type === "conversion" ||
                                    g?.type === "penalty_kick")
                                );
                              })?.length || 0;
                            let missed =
                              newScorers?.filter((g) => {
                                return (
                                  g.player &&
                                  newScorers[index]?.player &&
                                  g?.player === newScorers[index]?.player &&
                                  (g?.type === "missed_conversion" ||
                                    g?.type === "missed_penalty_kick")
                                );
                              })?.length || 0;

                            previewGraphic("PlayerStat", {
                              player: player,
                              subtext:
                                "goals  -  " +
                                made +
                                "/" +
                                (made + missed) +
                                "   (" +
                                Math.floor((made / (made + missed)) * 100) +
                                "%)",
                              team: goal.team,
                              game,
                              category: "goals",
                              value:
                                made +
                                "/" +
                                (made + missed) +
                                "   (" +
                                Math.floor((made / (made + missed)) * 100) +
                                "%)",
                              type: "LT",
                            });
                          }

                          if (own_goal) {
                            updateGame({
                              id: game._id,
                              home_scorers: newScorers,
                            });
                          } else {
                            updateGame({
                              id: game._id,
                              away_scorers: newScorers,
                            });
                          }
                        }
                      } else {
                        if (player) {
                          setSelectedPlayer({
                            ...player,
                            team: game.away_team,
                          });
                        } else {
                          setSelectedPlayer();
                        }
                      }
                    }}
                    removeGoal={(goal) => {
                      let new_scorers = game?.away_scorers?.filter(
                        (s) => s._id !== goal._id
                      );
                      updateGame({
                        id: game._id,
                        away_scorers: new_scorers,
                      });
                    }}
                    subsMade={awaySubsMade}
                    setSubsMade={setAwaySubsMade}
                    failedHIA={({ squad_id }) => {
                      let newLineup = JSON.parse(
                        JSON.stringify(game?.lineup?.away)
                      );

                      let index = newLineup?.findIndex(
                        (p) => p?.squad?._id === squad_id
                      );
                      if (index > -1) {
                        newLineup[index].hia = false;
                      }

                      newLineup = {
                        away: newLineup,
                        home: game.lineup.home,
                      };

                      updateGame({
                        id: game._id,
                        lineup: newLineup,
                      });
                      setAwaySubsMade(awaySubsMade + 1);
                    }}
                    passedHIA={({ squad_id }) => {
                      let newLineup = JSON.parse(
                        JSON.stringify(game?.lineup?.away)
                      );

                      let index = newLineup?.findIndex(
                        (p) => p?.squad?._id === squad_id
                      );
                      if (index > -1) {
                        newLineup[index].hia = false;
                      }

                      newLineup = {
                        away: newLineup,
                        home: game.lineup.home,
                      };

                      updateGame({
                        id: game._id,
                        lineup: newLineup,
                      });
                    }}
                  />
                )}
              {view === VIEW_CONSTS.squad &&
                selectedTeam._id === game.away_team._id && (
                  <Squad
                    countries={countries}
                    game={game}
                    team={game.away_team}
                    lineup={game.lineup?.away || game.opta_lineup?.away}
                    selectedPlayer={selectedPlayer}
                    selectPlayer={(player) => {
                      if (player) {
                        setSelectedPlayer({ ...player, team: game.away_team });
                      } else {
                        setSelectedPlayer();
                      }
                    }}
                  />
                )}
            </Lineups>
          </ActionPanel>
        </Content>
      )}
    </Main>
  );
}
