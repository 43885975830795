import { useContext } from "react";
import { ScrollBar } from "../../../Theme/Hyper";
import { APIContext } from "../../../contexts/APIContext";
import styled from "styled-components";
import { UIContext } from "../../../contexts/UIContext";

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;

export default function URCStandings({ previewGraphic, game, teams }) {
  const { getTables } = useContext(APIContext);
  const { setManualTable, manualTable } = useContext(UIContext);
  return (
    <GraphicsList>
      <GraphicButton
        onClick={() => {
          getTables().then((data) => {
            let irish_standings = data.groups
              ?.filter((row) => {
                return data.pools?.Irish?.indexOf(row?.team?.id) > -1;
              })
              .map((team, index) => {
                return { ...team, position: index + 1 };
              });
            previewGraphic("FF_TABLES", {
              game: game,
              teams: teams,
              standings: irish_standings,
              colour: "#00B446",
              title: "IRISH",
            });
          });
        }}
      >
        Irish Shield
      </GraphicButton>

      <GraphicButton
        onClick={() => {
          getTables().then((data) => {
            let sa_standings = data.groups
              ?.filter((row) => {
                return (
                  data.pools?.["South African"]?.indexOf(row?.team?.id) > -1
                );
              })
              .map((team, index) => {
                return { ...team, position: index + 1 };
              });
            previewGraphic("FF_TABLES", {
              game: game,
              teams: teams,
              standings: sa_standings,
              colour: "#FFAA00",
              title: "SOUTH AFRICAN",
            });
          });
        }}
      >
        South African Shield
      </GraphicButton>

      <GraphicButton
        onClick={() => {
          getTables().then((data) => {
            let si_standings = data.groups
              ?.filter((row) => {
                return (
                  data.pools?.["Scottish Italian"]?.indexOf(row?.team?.id) > -1
                );
              })
              .map((team, index) => {
                return { ...team, position: index + 1 };
              });
            previewGraphic("FF_TABLES", {
              game: game,
              teams: teams,
              standings: si_standings,
              colour: "#0069E6",
              title: "ITALIAN SCOTTISH",
            });
          });
        }}
      >
        Scottish/Italian Shield
      </GraphicButton>

      <GraphicButton
        onClick={() => {
          getTables().then((data) => {
            let welsh_standings = data.groups
              ?.filter((row) => {
                return data.pools?.Welsh?.indexOf(row?.team?.id) > -1;
              })
              .map((team, index) => {
                return { ...team, position: index + 1 };
              });
            previewGraphic("FF_TABLES", {
              game: game,
              teams: teams,
              standings: welsh_standings,
              colour: "#EB322D",
              title: "WELSH",
            });
          });
        }}
      >
        Welsh Shield
      </GraphicButton>
      <GraphicButton
        onClick={() => {
          getTables().then((data) => {
            let standings = data.groups;
            previewGraphic("FF_OVERALL_TABLES", {
              game: game,
              teams: teams,
              standings: standings,
            });
          });
        }}
      >
        Overall Standings
      </GraphicButton>

      <GraphicButton
        selected={manualTable}
        onClick={() => {
          setManualTable(!manualTable);
        }}
      >
        Overall Standings (Manual)
      </GraphicButton>
    </GraphicsList>
  );
}
