export class ClockTryTimeline {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TryTimeline";
    this.scene = "TryTimeline";
    this.data = [];
    this.page = 10000;
  }

  async preview({ options }) {}
  animate({ options }) {
    let home_tries = options?.game?.home_scorers
      ?.filter((s) => s.type === "try" && s?.period === options.half)
      ?.map((s) => {
        return "H" + (s?.game_time_minutes + 1);
      });

    let away_tries = options?.game?.away_scorers
      ?.filter((s) => s.type === "try" && s?.period === options.half)
      ?.map((s) => {
        return "A" + (s?.game_time_minutes + 1);
      });
    let string =
      "SMMSystem_SetValue|SHMSend_PenaltyTimeline|" +
      options.half +
      "," +
      home_tries?.join(",") +
      "," +
      away_tries?.join(",");

    this.vizSharedMemory({
      shared_memory: string,
    });

    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_StatComponents|" +
        (options?.half === 1 ? "1st half tries" : "2nd half tries") +
        "," +
        home_tries?.length +
        "," +
        away_tries?.length +
        ",,PenaltyTimeline",
    });
  }

  animateOff({ options }) {
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_StatComponents|REG_Score,#,#",
    });
  }

  update({ options }) {}
}
