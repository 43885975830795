export class TeamBottomBar {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TeamBottomBar";
    this.scene = "TeamBottomBar";
    this.data = [];
    this.download_page_home = [7120, 7121, 7122, 7123, 7124, 7125];
    this.download_page_away = [7120, 7121, 7122, 7123, 7124, 7125];
    this.home_page = [6800, 6801, 6802, 6803, 6804, 6805];
    this.away_page = [6810, 6811, 6812, 6813, 6814, 6815];
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = [];

    if (options?.game?.home_team?._id === options?.team?._id) {
      this.page = this.home_page[0];
      this.download_page = this.download_page_home[0];
      await this.buildFullBack({ options });
      this.page = this.home_page[1];
      this.download_page = this.download_page_home[1];
      await this.buildOutsideBacks({ options });
      this.page = this.home_page[2];
      this.download_page = this.download_page_home[2];
      await this.buildHalfBacks({ options });
      this.page = this.home_page[3];
      this.download_page = this.download_page_home[3];
      await this.buildFontRow({ options });
      this.page = this.home_page[4];
      this.download_page = this.download_page_home[4];
      await this.buildBackRow({ options });
      this.page = this.home_page[5];
      this.download_page = this.download_page_home[5];
      await this.buildSubs({ options });
      this.page = this.home_page[0];
    } else if (options?.game?.away_team?._id === options?.team?._id) {
      this.page = this.away_page[0];
      this.download_page = this.download_page_away[0];
      await this.buildFullBack({ options });
      this.page = this.away_page[1];
      this.download_page = this.download_page_away[1];
      await this.buildOutsideBacks({ options });
      this.page = this.away_page[2];
      this.download_page = this.download_page_away[2];
      await this.buildHalfBacks({ options });
      this.page = this.away_page[3];
      this.download_page = this.download_page_away[3];
      await this.buildFontRow({ options });
      this.page = this.away_page[4];
      this.download_page = this.download_page_away[4];
      await this.buildBackRow({ options });
      this.page = this.away_page[5];
      this.download_page = this.download_page_away[5];
      await this.buildSubs({ options });
      this.page = this.away_page[0];
    }

    await this.sendPreview("page:read " + this.page);
  }
  async buildFullBack({ options }) {
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "10";
      }).value = options?.team?.variables?.badge;

      new Array(1).fill(1).forEach((_, index) => {
        let row = index + 1;

        let player = options.squads?.find((s) => s.order === row);

        xml.payload.field.find((item) => {
          return item.attr.name === "1121";
        }).value = player?.squad?.shirt_number || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === "1122";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === "1124";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });
    }
    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });
  }
  async buildOutsideBacks({ options }) {
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "10";
      }).value = options?.team?.variables?.badge;

      new Array(4).fill(1).forEach((_, index) => {
        let row = index + 2;

        let player = options.squads?.find((s) => s.order === row);

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "1";
        }).value = player?.squad?.shirt_number || "";
        let name = "";
        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.irst_name_upper?.[0] ===
                  player?.squad?.player?.irst_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "2";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "4";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });
    }
    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });
  }
  async buildHalfBacks({ options }) {
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "10";
      }).value = options?.team?.variables?.badge;

      new Array(2).fill(1).forEach((_, index) => {
        let row = index + 6;

        let player = options.squads?.find((s) => s.order === row);

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "1";
        }).value = player?.squad?.shirt_number || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "2";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "4";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });
    }
    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });
  }
  async buildFontRow({ options }) {
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "10";
      }).value = options?.team?.variables?.badge;

      new Array(3).fill(1).forEach((_, index) => {
        let row = index + 8;

        let player = options.squads?.find((s) => s.order === row);

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "1";
        }).value = player?.squad?.shirt_number || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "2";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "4";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });
    }
    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });
  }
  async buildBackRow({ options }) {
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "10";
      }).value = options?.team?.variables?.badge;

      new Array(3).fill(1).forEach((_, index) => {
        let row = index + 11;
        if (index === 1) {
          row = 13;
        }
        if (index === 2) {
          row = 12;
        }

        let player = options.squads?.find((s) => s.order === row);

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "1";
        }).value = player?.squad?.shirt_number || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "2";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "4";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });
    }
    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });
  }
  async buildSubs({ options }) {
    let xml = await this.getVizPage({ page: this.download_page }).catch(
      (err) => {
        console.log(err);
      }
    );
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "10";
      }).value = options?.team?.variables?.badge;

      new Array(5).fill(1).forEach((_, index) => {
        let row = index + 14;

        let player = options.squads?.find((s) => s.order === row);

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "1";
        }).value =
          player?.squad?.shirt_number + (index === 4 ? " (18th)" : "") || "";

        let name = "";

        if (
          options.squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.player?.first_name_upper?.[0] ===
                  player?.squad?.player?.first_name_upper?.[0] &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            name = player?.squad?.player?.first_name_upper + " ";
          } else {
            name = player?.squad?.player?.first_name_upper?.[0] + ". ";
          }
        }
        name +=
          player?.squad?.player?.last_name_upper ||
          player?.squad?.player?.last_name?.toUpperCase() ||
          "";

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "2";
        }).value = name;

        xml.payload.field.find((item) => {
          return item.attr.name === "11" + (index + 2) + "4";
        }).value =
          options?.team?.variables?.player_image_path +
          "/" +
          player?.squad?.player?.first_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_") +
          "_" +
          player?.squad?.player?.last_name
            ?.toUpperCase()
            ?.replaceAll("'", "")
            ?.replaceAll(" ", "_");
      });
    }
    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
