export class KickPredictorGauge {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "KickPredictorGauge";
    this.scene = "KickPredictorGauge";
    this.data = [];
    this.page = 7012;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value =
        options.team?.variables?.alt_badge ||
        options.team?.variables?.badge ||
        "";

      xml.payload.field.find((item) => {
        return item.attr.name === "102";
      }).value = options.player.first_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options.player.last_name_upper || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "200";
      }).value = options.percent;

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
