export class TeamList {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamList";
    this.scene = "LT - Team List";
    this.data = [];
    this.page = 0;
  }

  preview({ options }) {
    this.options = options;
    this.onAir = false;

    this.data = [
      {
        name: "TEAM",
        value: {
          text: options.game[options.team + "_team"].variables[
            "name_uppercase"
          ],
        },
      },
      {
        name: "MANAGER",
        value: {
          text:
            options.game[options.team + "_team"].variables["manager_title"] +
            ": " +
            options.game[options.team + "_team"].variables["manager_first"] +
            " " +
            options.game[options.team + "_team"].variables["manager_last"],
        },
      },
    ];
    if (options.game[options.team + "_team"].variables["badge_home"]) {
      this.data.push({
        name: "HOME_BADGE",
        value: {
          image:
            "http://localhost:6060/uploads/PREMIER SPORTS/" +
            window.encodeURIComponent(
              "Badges/" +
                (options.game[options.team + "_team"].variables["badge_home"] &&
                  options.game[options.team + "_team"].variables["badge_home"])
            ),
        },
      });
    }

    options.game.live_data[options.team + "_team_lineup"]
      .sort((a, b) => a.order - b.order)
      .filter((p) => p.order <= 11)
      .forEach((player, index) => {
        this.data.push({
          name: "NUMBER-1_" + (index + 1),
          value: {
            text: player.shirt_number,
          },
        });
        this.data.push({
          name: "FIRST_" + (index + 1),
          value: {
            text: player.first_name,
          },
        });
        this.data.push({
          name: "LAST_" + (index + 1),
          value: {
            text: player.last_name_upper,
          },
        });
      });

    this.sendPreview(this.scene, 100, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.page = 0;
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
