export class TopPerformers {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TopPerformers";
    this.scene = "TopPerformers";
    this.data = [];
    this.page = 7054;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      let titles = this.getTitles({ title: options.title });
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "0003";
      }).value = options?.total_players > options?.top ? 1 : 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "002";
      }).value = titles?.length > 1 ? titles?.[0]?.toUpperCase() : "";

      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value =
        titles?.length === 1
          ? titles?.[0]?.toUpperCase()
          : titles?.[1]?.toUpperCase();

      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = options.subline;

      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = options.players?.length;

      xml.payload.field.find((item) => {
        return item.attr.name === "0008.ColBadge";
      }).value = options.team_cb ? 0 : 1;

      xml.payload.field.find((item) => {
        return item.attr.name === "00101.active.active";
      }).value = options.team_cb ? 1 : 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "00101";
      }).value =
        options.team?.variables?.alt_badge || options.team?.variables?.badge;

      options?.players?.map((player, index) => {
        let row = index + 1;

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + row + "2";
        }).value = player?.team?.variables?.badge;

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + row + "3";
        }).value =
          (player?.squad?.player?.first_name || "") +
          " " +
          (player?.squad?.player?.last_name || "");

        xml.payload.field.find((item) => {
          return item.attr.name === "0" + row + "5";
        }).value =
          parseInt(player?.stats?.[options?.stat_key]) +
          (options?.stat_key === "tackle_efficiency" ? "%" : "");
      });

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }

  getTitles({ title }) {
    let titles = [];
    let split = title?.split(" ");
    let number_of_words = split?.length;
    if (number_of_words === 1) {
      titles.push(title);
    } else if (number_of_words === 2) {
      if (title?.length <= 12) {
        titles.push(title);
      } else {
        titles.push(split[0]);
        titles.push(split[1]);
      }
    } else if (number_of_words === 3) {
      if (title?.length <= 12) {
        titles.push(title);
      } else {
        titles.push(split[0] + " " + split[1]);
        titles.push(split[2]);
      }
    } else if (number_of_words === 4) {
      if (title?.length <= 12) {
        titles.push(title);
      } else {
        titles.push(split[0] + " " + split[1]);
        titles.push(split[2] + " " + split[3]);
      }
    } else if (number_of_words === 5) {
      if (title?.length <= 12) {
        titles.push(title);
      } else {
        titles.push(split[0] + " " + split[1] + " " + split[2]);
        titles.push(split[3] + " " + split[4]);
      }
    }
    return titles;
  }
}
