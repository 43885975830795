import { useContext } from "react";
import { ScrollBar } from "../../../Theme/Hyper";
import { APIContext } from "../../../contexts/APIContext";
import styled from "styled-components";

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;

export default function EPCRStandings({ previewGraphic, game }) {
  const { getStandings, getTeams } = useContext(APIContext);

  return (
    <GraphicsList>
      <GraphicButton
        onClick={async () => {
          let teams = await getTeams({});
          let data = await getStandings();
          let table = data?.standings?.find(
            (data) =>
              data.oval_comp_ID === game?.competition?.oval_ID &&
              data.oval_season_ID === game?.season?.oval_ID
          );

          previewGraphic("Standings", {
            title: "POOL A",
            data: table?.data?.groups
              ?.filter((d) => d.name === "Pool A")
              ?.sort((a, b) => {
                return a?.position - b?.position;
              })
              ?.map((row) => {
                return {
                  ...row,
                  teamName: row?.team?.name,
                  P: row?.played || 0,
                  W: row?.won || 0,
                  L: row?.lost || 0,
                  D: row?.drawn || 0,
                  DIFF: row?.pointsDiff || 0,
                  PTS: row?.points || 0,
                };
              }),
            teams,
          });
        }}
      >
        Pool A
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          let teams = await getTeams({});
          let data = await getStandings();
          let table = data?.standings?.find(
            (data) =>
              data.oval_comp_ID === game?.competition?.oval_ID &&
              data.oval_season_ID === game?.season?.oval_ID
          );

          previewGraphic("Standings", {
            title: "POOL B",
            data: table?.data?.groups
              ?.filter((d) => d.name === "Pool B")
              ?.sort((a, b) => {
                return a?.position - b?.position;
              })
              ?.map((row) => {
                return {
                  ...row,
                  teamName: row?.team?.name,
                  P: row?.played || 0,
                  W: row?.won || 0,
                  L: row?.lost || 0,
                  D: row?.drawn || 0,
                  DIFF: row?.pointsDiff || 0,
                  PTS: row?.points || 0,
                };
              }),
            teams,
          });
        }}
      >
        Pool B
      </GraphicButton>
    </GraphicsList>
  );
}
