import React, { useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { VizContext } from "../../contexts/VizContext";
import { useState } from "react";

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  opacity: 0.7;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  padding: 0.5em;
  font-weight: 100;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelColour};
  width: 700px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.highlightColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;

const Group = styled.div`
  display: flex;
  padding: 1em;
  border-bottom: 1px solid black;
`;

const Command = styled.div`
  display: flex;
  padding: 1em;
  min-width: 200px;
`;

const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
`;

export default function Attendance({ title, onClose, game, updateGame }) {
  const [newAttendance, setNewAttendance] = useState(null);

  return (
    <>
      <Screen></Screen>
      <Main>
        <Content>
          <Header>{title}</Header>
          <Group>
            <Command>Enter attendance </Command>
            <Input
              value={newAttendance === null ? game?.attendance : newAttendance}
              onChange={(e) => setNewAttendance(e.currentTarget.value)}
            />
          </Group>
          <Buttons>
            <Button
              onClick={() => {
                updateGame({ id: game._id, attendance: newAttendance });
                onClose();
              }}
            >
              Save
            </Button>
          </Buttons>
        </Content>
      </Main>
    </>
  );
}
