import React, { useRef, useContext, useCallback } from "react";
import styled from "styled-components";
import { UserContext } from "../../contexts/UserContext";
import MicrosoftButton from "./MicrosoftButton";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

export const H1 = styled.h1`
  font-size: 2em;
  font-weight: 100;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(14, 18, 25);
  opacity: 1;
  transition: 0.5s opacity;
  z-index: 100;
`;

const Main = styled.div`
  width: 300px;
  margin-top: -24%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  background-color: #1f222b !important;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #cacaca;
`;
const Button = styled.div`
  background-color: #c11938;
  padding: 1em;
  border-radius: 4px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 2em;
`;
const Error = styled.div`
  color: #c11938;
  padding: 1em;
  display: flex;
  justify-content: center;
`;
export default function Login() {
  const { login, signinError } = useContext(UserContext);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = useCallback(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect({
        scopes: ["User.Read"],
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <Container>
      <Main>
        <H1>Welcome</H1>

        <Form>
          <Input
            ref={usernameRef}
            placeholder={"Username"}
            autoComplete="username"
            tabIndex="1"
          />
          <Input
            tabIndex="2"
            ref={passwordRef}
            placeholder={"Password"}
            type={"password"}
            autoComplete="current-password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                login({
                  username: usernameRef.current.value,
                  password: passwordRef.current.value,
                });
              }
            }}
          />
          {signinError && <Error>{signinError}</Error>}
          <Button
            tabIndex="3"
            ref={buttonRef}
            onClick={() => {
              login({
                username: usernameRef.current.value,
                password: passwordRef.current.value,
              });
            }}
          >
            Login
          </Button>
          {(window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
            process.env.REACT_APP_MICROSOFT_CLIENT_ID) && (
            <MicrosoftButton
              onClick={() => {
                handleLogin();
              }}
            />
          )}
        </Form>
      </Main>
    </Container>
  );
}
