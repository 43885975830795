export class AttackingStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "AttackingStats";
    this.scene = "AttackingStats";
    this.data = [];
    this.page = 7053;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "003";
      }).value = `ATTACKING
STATS`;
      xml.payload.field.find((item) => {
        return item.attr.name === "0004";
      }).value = "4";
      xml.payload.field.find((item) => {
        return item.attr.name === "006";
      }).value = options?.game?.home_team?.code || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "007";
      }).value = options?.game?.away_team?.code || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "013";
      }).value = "Tries";

      xml.payload.field.find((item) => {
        return item.attr.name === "015";
      }).value = options?.game?.opta_home_team_stats?.["tries"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "016";
      }).value = options?.game?.opta_away_team_stats?.["tries"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "023";
      }).value = "Tackle busts";

      xml.payload.field.find((item) => {
        return item.attr.name === "025";
      }).value = options?.game?.opta_home_team_stats?.["tackle_busts"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "026";
      }).value = options?.game?.opta_away_team_stats?.["tackle_busts"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "033";
      }).value = "PTBs in opp. 20";

      xml.payload.field.find((item) => {
        return item.attr.name === "035";
      }).value = options?.game?.opta_home_team_stats?.["ptb_opp_20m"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "036";
      }).value = options?.game?.opta_away_team_stats?.["ptb_opp_20m"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "043";
      }).value = "Attacking kicks";

      xml.payload.field.find((item) => {
        return item.attr.name === "045";
      }).value = options?.game?.opta_home_team_stats?.["attacking_kicks"] || 0;

      xml.payload.field.find((item) => {
        return item.attr.name === "046";
      }).value = options?.game?.opta_away_team_stats?.["attacking_kicks"] || 0;

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
