export class LTScore {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "Score";
    this.scene = "Score";
    this.data = [];
    this.page = 7040;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      let score = this.getScore(options?.game);
      xml.payload.field.find((item) => {
        return item.attr.name === "100";
      }).value = options?.game?.home_team?.variables?.badge || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "120";
      }).value = options?.game?.away_team?.variables?.badge || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "103";
      }).value = options?.game?.home_team?.short_name?.toUpperCase() || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "123";
      }).value = options?.game?.away_team?.short_name?.toUpperCase() || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "210";
      }).value =
        options?.type === "HT"
          ? "Half Time"
          : options?.type === "FT"
          ? "Full Time"
          : "";

      xml.payload.field.find((item) => {
        return item.attr.name === "105";
      }).value = score?.home_score + "-" + score?.away_score;

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let home_tries =
      game?.home_scorers?.filter((g) => g.type === "try").length || 0;
    let home_conv =
      game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
    let home_pen_kick =
      game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let home_pen_try =
      game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let home_drop_goal =
      game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_tries =
      game?.away_scorers?.filter((g) => g.type === "try").length || 0;
    let away_conv =
      game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
    let away_pen_kick =
      game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let away_pen_try =
      game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let away_drop_goal =
      game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    return {
      home_score,
      away_score,
      home_tries,
      home_conv,
      home_pen_kick,
      home_pen_try,
      home_drop_goal,
      away_tries,
      away_conv,
      away_pen_kick,
      away_pen_try,
      away_drop_goal,
    };
  }
}
