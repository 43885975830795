export class PositionMatchStats {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "PositionMatchStats";
    this.scene = "PositionMatchStats";
    this.data = [];
    this.page = 7055;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "006";
      }).value = options?.game?.home_team?.code || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "007";
      }).value = options?.game?.away_team?.code || "";

      xml.payload.field.find((item) => {
        return item.attr.name === "0003";
      }).value = 1;

      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value = "Match stats";

      if (options.position === "outside_backs") {
        let outside_backs = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return (
              p?.order === 1 ||
              p?.order === 2 ||
              p?.order === 3 ||
              p?.order === 4 ||
              p?.order === 5
            );
          });

        let home_player_stats = options?.game?.opta_home_player_stats?.filter(
          (p) => {
            return (
              outside_backs?.findIndex(
                (o) => o?.squad?.player?.opta_ID === p?.player
              ) > -1
            );
          }
        );

        let away_player_stats = options?.game?.opta_away_player_stats?.filter(
          (p) => {
            return (
              outside_backs?.findIndex(
                (o) => o?.squad?.player?.opta_ID === p?.player
              ) > -1
            );
          }
        );

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 3;

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "OUTSIDE BACKS";

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "016";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          home_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "026";
        }).value =
          away_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Tackle breaks";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackle_busts"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "036";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackle_busts"]) || 0);
        }, 0);
      } else if (options.position === "props") {
        let props = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return p?.order === 8 || p?.order === 10 || p?.order === 13;
          });

        let home_player_stats = options?.game?.opta_home_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        let away_player_stats = options?.game?.opta_away_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "MIDDLES";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "016";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          home_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "026";
        }).value =
          away_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Offloads";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["offloads"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "036";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["offloads"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Tackles";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "046";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);
      } else if (options.position === "half_backs") {
        let props = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return p?.order === 6 || p?.order === 7;
          });

        let home_player_stats = options?.game?.opta_home_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        let away_player_stats = options?.game?.opta_away_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "HALF BACKS";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "016";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Passes";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["passes"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "026";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["passes"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Line break assists";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["break_assist"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "036";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["break_assist"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Kicks in general play";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["kicks_in_gen_play"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "046";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["kicks_in_gen_play"]) || 0);
        }, 0);
      } else if (options.position === "back_row") {
        let props = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return p?.order === 11 || p?.order === 12;
          });

        let home_player_stats = options?.game?.opta_home_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        let away_player_stats = options?.game?.opta_away_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "SECOND ROWS";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Carries";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "016";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["carries"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          home_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "026";
        }).value =
          away_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Offloads";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["offloads"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "036";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["offloads"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Tackles";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "046";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);
      } else if (options.position === "hooker") {
        let props = options?.game?.lineup?.home
          ?.concat(options?.game?.lineup?.away)
          ?.filter((p) => {
            return p?.order === 9;
          });

        let home_player_stats = options?.game?.opta_home_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        let away_player_stats = options?.game?.opta_away_player_stats?.filter(
          (p) => {
            return (
              props?.findIndex((o) => o?.squad?.player?.opta_ID === p?.player) >
              -1
            );
          }
        );

        xml.payload.field.find((item) => {
          return item.attr.name === "003";
        }).value = "HOOKERS";

        xml.payload.field.find((item) => {
          return item.attr.name === "0004";
        }).value = 4;

        xml.payload.field.find((item) => {
          return item.attr.name === "013";
        }).value = "Passes";

        xml.payload.field.find((item) => {
          return item.attr.name === "015";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["passes"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "016";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["passes"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "023";
        }).value = "Metres from dummy half";

        xml.payload.field.find((item) => {
          return item.attr.name === "025";
        }).value =
          home_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["dummy_half_metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "026";
        }).value =
          away_player_stats?.reduce((prev, curr) => {
            return prev + (parseInt(curr?.stats?.["dummy_half_metres"]) || 0);
          }, 0) + "m";

        xml.payload.field.find((item) => {
          return item.attr.name === "033";
        }).value = "Kicks in general play";

        xml.payload.field.find((item) => {
          return item.attr.name === "035";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["kicks_in_gen_play"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "036";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["kicks_in_gen_play"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "043";
        }).value = "Tackles";

        xml.payload.field.find((item) => {
          return item.attr.name === "045";
        }).value = home_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);

        xml.payload.field.find((item) => {
          return item.attr.name === "046";
        }).value = away_player_stats?.reduce((prev, curr) => {
          return prev + (parseInt(curr?.stats?.["tackles"]) || 0);
        }, 0);
      }

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
