import moment from "moment";

export class Versus {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Versus";
    this.scene = "Versus";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.options = options;

    this.data = [];
    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit BG Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour String",
      value: {
        text:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour String",
      value: {
        text:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.home_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge || "",
      },
    });

    this.data.push({
      name: "Away Team Third Badge",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.third_badge || "",
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Score",
      value: {
        text: options.game?.home_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: options.game?.away_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Secondary Badge",
      value: {
        image: "${user}" + options.game?.competition?.secondary_badge || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: { text: options.game?.stadium?.name || "" },
    });

    this.data.push({
      name: "Comm First Name 1",
      value: { text: options.game?.commentators?.[0]?.first_name || "" },
    });

    this.data.push({
      name: "Comm Last Name 1",
      value: { text: options.game?.commentators?.[0]?.last_name || "" },
    });

    this.data.push({
      name: "Comm Full Name 1",
      value: {
        text:
          (options.game?.commentators?.[0]?.first_name || "") +
          " " +
          (options.game?.commentators?.[0]?.last_name || ""),
      },
    });

    this.data.push({
      name: "Comm First Name 2",
      value: { text: options.game?.commentators?.[1]?.first_name || "" },
    });

    this.data.push({
      name: "Comm Last Name 2",
      value: { text: options.game?.commentators?.[1]?.last_name || "" },
    });

    this.data.push({
      name: "Comm Full Name 2",
      value: {
        text:
          (options.game?.commentators?.[1]?.first_name || "") +
          " " +
          (options.game?.commentators?.[1]?.last_name || ""),
      },
    });

    this.data.push({
      name: "Comm First Name 3",
      value: { text: options.game?.commentators?.[2]?.first_name || "" },
    });

    this.data.push({
      name: "Comm Last Name 3",
      value: { text: options.game?.commentators?.[2]?.last_name || "" },
    });

    this.data.push({
      name: "Comm Full Name 3",
      value: {
        text:
          (options.game?.commentators?.[2]?.first_name || "") +
          " " +
          (options.game?.commentators?.[2]?.last_name || ""),
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
