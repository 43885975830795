import React, { useContext, useEffect } from "react";
import Login from "./components/Login/Login";
import App from "./App";
import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { UserContext, UserProvider } from "./contexts/UserContext";
import { MsalProvider } from "@azure/msal-react";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

function Main() {
  const { loggedIn } = useContext(UserContext);

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  window.hideHeader = getParameterByName("show_header") === "false";

  return (
    <>
      {!loggedIn && <Login />}
      {loggedIn && <App />}
    </>
  );
}

export default function Startup({ msalInstance }) {
  return (
    <MSALChecker msalInstance={msalInstance}>
      <UserProvider user_client={user_client}>
        <Main />
      </UserProvider>
    </MSALChecker>
  );
}

function MSALChecker({ msalInstance, children }) {
  if (msalInstance) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  } else {
    return <>{children}</>;
  }
}
