import React, { useContext } from "react";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import Stat from "./Stat";
import { ScrollBar } from "../../Theme/Hyper";
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;

export default function TeamCalculatedStats({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);
  let home_stats = game?.home_player_stats || [];
  let away_stats = game?.away_player_stats || [];
  debugger;
  function getTotalPosssession(sequences) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total_home = 0;
    let total_away = 0;
    sequences
      .filter((s) => s.team === game?.home_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_home += end_time - start_time;
      });

    sequences
      .filter((s) => s.team === game?.away_team?._id)
      .forEach((s) => {
        let sorted_tackles = s?.tackles?.sort((a, b) => a.number - b.number);
        let start_time = sorted_tackles[0]?.time || 0;
        let end_time = sorted_tackles?.at(-1)?.time || 0;
        total_away += end_time - start_time;
      });
    let home_percent = parseInt((total_home / (total_home + total_away)) * 100);

    return {
      home: home_percent || 0,
      away: 100 - home_percent || 0,
    };
  }

  function getSetTotals(sequences, team) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total = 0;
    let complete = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total += 1;
        complete += s?.status === "complete" ? 1 : 0;
      });
    return {
      total,
      complete,
      percent: parseInt((complete / total) * 100) || 0,
    };
  }
  function getTotalMetres(sequences, team) {
    sequences = JSON.parse(JSON.stringify(sequences));
    let total = 0;
    sequences
      .filter((s) => s.team === team)
      .forEach((s) => {
        total +=
          Math.abs(
            Math.round(
              (Math.max(82, Math.min(933, s?.tackles?.at(-1)?.x)) -
                Math.max(82, Math.min(933, s?.tackles?.[0]?.x))) /
                7
            )
          ) || 0;
      });
    return total;
  }

  return (
    <Main>
      <Stat
        game={game}
        category={"Completed Tackles"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: game?.home_sets?.reduce(function (previous, s) {
            return previous + s?.tackles?.filter((s) => s.number > -1)?.length;
          }, 0),
          away: game?.away_sets?.reduce(function (previous, s) {
            return previous + s?.tackles?.filter((s) => s.number > -1)?.length;
          }, 0),
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Possession"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: getTotalPosssession([
            ...(game?.home_sets || []),
            ...(game?.away_sets || []),
          ])?.home,
          away: getTotalPosssession([
            ...(game?.home_sets || []),
            ...(game?.away_sets || []),
          ])?.away,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Completed Sets"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home:
            getSetTotals(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.home_team?._id
            )?.complete +
            "/" +
            getSetTotals(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.home_team?._id
            )?.total,
          away:
            getSetTotals(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.away_team?._id
            )?.complete +
            "/" +
            getSetTotals(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.away_team?._id
            )?.total,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Completion rate"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home:
            getSetTotals(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.home_team?._id
            )?.percent + "%",
          away:
            getSetTotals(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.away_team?._id
            )?.percent + "%",
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      {
        <Stat
          game={game}
          category={"Tackles"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: Object.keys(home_stats).reduce(function (previous, key) {
              return previous + (home_stats[key]?.Tackles || 0);
            }, 0),
            away: Object.keys(away_stats).reduce(function (previous, key) {
              return previous + (away_stats[key]?.Tackles || 0);
            }, 0),
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
      {
        <Stat
          game={game}
          category={"Tackles Missed"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: Object.keys(home_stats).reduce(function (previous, key) {
              return previous + (home_stats[key]?.["Missed Tackles"] || 0);
            }, 0),
            away: Object.keys(away_stats).reduce(function (previous, key) {
              return previous + (away_stats[key]?.["Missed Tackles"] || 0);
            }, 0),
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
      <Stat
        game={game}
        category={"Metres"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home:
            getTotalMetres(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.home_team?._id
            ) + "m",
          away:
            getTotalMetres(
              [...(game?.home_sets || []), ...(game?.away_sets || [])],
              game?.away_team?._id
            ) + "m",
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      {
        <Stat
          game={game}
          category={"Carries"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: Object.keys(home_stats).reduce(function (previous, key) {
              return previous + (home_stats[key]?.["Carries"] || 0);
            }, 0),
            away: Object.keys(away_stats).reduce(function (previous, key) {
              return previous + (away_stats[key]?.["Carries"] || 0);
            }, 0),
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
      {
        <Stat
          game={game}
          category={"Handling Errors"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: Object.keys(home_stats).reduce(function (previous, key) {
              return previous + (home_stats[key]?.["Handling errors"] || 0);
            }, 0),
            away: Object.keys(away_stats).reduce(function (previous, key) {
              return previous + (away_stats[key]?.["Handling errors"] || 0);
            }, 0),
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
      {
        <Stat
          game={game}
          category={"Offloads"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: Object.keys(home_stats).reduce(function (previous, key) {
              return previous + (home_stats[key]?.["Offloads"] || 0);
            }, 0),
            away: Object.keys(away_stats).reduce(function (previous, key) {
              return previous + (away_stats[key]?.["Offloads"] || 0);
            }, 0),
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
      {
        <Stat
          game={game}
          category={"Breaks"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: Object.keys(home_stats).reduce(function (previous, key) {
              return previous + (home_stats[key]?.["Breaks"] || 0);
            }, 0),
            away: Object.keys(away_stats).reduce(function (previous, key) {
              return previous + (away_stats[key]?.["Breaks"] || 0);
            }, 0),
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
      {
        <Stat
          game={game}
          category={"Penalties"}
          matchStats={[]}
          previewGraphic={(name, options) => {
            previewGraphic(name, {
              ...options,
            });
          }}
          stat={{
            home: game?.home_team_stats?.Penalties || 0,
            away: game?.away_team_stats?.Penalties || 0,
          }}
          updateHomeStat={({ e }) => {}}
          updateAwayStat={({ e }) => {}}
        />
      }
    </Main>
  );
}
