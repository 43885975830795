import React from "react";
import styled from "styled-components";

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
function KickPredictor({ game, previewGraphic, player }) {
  let teams = game?.kick_predictor?.teams?.team?.[1];
  let kicks = [];
  debugger;
  try {
    if (Array.isArray(game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent)) {
      kicks = kicks.concat(
        game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent
      );
    } else {
      kicks.push(game?.kick_predictor?.teams?.team?.[0]?.goalKickEvent);
    }
    if (Array.isArray(game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent)) {
      kicks = kicks.concat(
        game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent
      );
    } else {
      kicks.push(game?.kick_predictor?.teams?.team?.[1]?.goalKickEvent);
    }
    kicks = kicks
      ?.filter((k) => k?.player_id === player?.opta_ID)
      ?.sort((a, b) => {
        if (a?.period === "First Half" && b?.period === "Second Half") {
          return 1;
        }
        if (a?.period === "Second Half" && b?.period === "First Half") {
          return -1;
        }
        if (parseInt(a?.time) < parseInt(b?.time)) {
          return 1;
        }
        if (parseInt(a?.time) > parseInt(b?.time)) {
          return -1;
        }
        if (parseInt(a?.secs) < parseInt(b?.secs)) {
          return 1;
        }
        if (parseInt(a?.secs) > parseInt(b?.secs)) {
          return -1;
        }
      });
  } catch (err) {
    console.log(err);
  }

  return (
    <>
      {kicks?.slice(0, 1)?.map((kick) => {
        return (
          <>
            <GraphicButton
              onClick={() => {
                previewGraphic("Name", {
                  player: player,
                  subtext:
                    "Distance: " +
                    Math.floor(kick?.distance) +
                    "m     Angle: " +
                    Math.floor(kick?.angle) +
                    "°     Success probability: " +
                    Math.floor(kick?.playerProb * 100) +
                    "%",

                  team: player?.team,
                  game,
                  show_number: true,
                });
              }}
            >
              <div>
                <Column>
                  <div>Kick metrics L3</div>
                  <div>
                    {kick?.time?.toString()?.padStart(2, "0")}:
                    {kick?.secs?.toString()?.padStart(2, "0")} -{" "}
                    {kick?.statname}
                  </div>
                </Column>
              </div>
            </GraphicButton>
            <GraphicButton
              onClick={() => {
                previewGraphic("KickPredictorGauge", {
                  player: player,
                  percent: Math.floor(kick?.playerProb * 100),
                  team: player?.team,
                  game,
                  show_number: true,
                });
              }}
            >
              <div>
                <Column>
                  <div>Kick success % gauge</div>
                  <div>
                    {kick?.time?.toString()?.padStart(2, "0")}:
                    {kick?.secs?.toString()?.padStart(2, "0")} -{" "}
                    {Math.floor(kick?.playerProb * 100)}% - {kick?.statname}
                  </div>
                </Column>
              </div>
            </GraphicButton>
          </>
        );
      })}
    </>
  );
}

export default KickPredictor;
