import React, { useContext } from "react";
import { ControlContext } from "../../contexts/ControlContext";
import styled from "styled-components";
import Select from "react-select";
import { Button } from "../../Theme/Hyper";

const Main = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  padding: 0.5em;
  color: #474e5f;
  align-items: center;
  height: 80px;
  margin: 1em;
`;
const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;
const Column = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

const GraphicButton = styled(Button)`
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  margin-right: 1em;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

export default function Bigscreen({ game }) {
  const { engines, selectedEngine, setSelectedEngine, sendData } =
    useContext(ControlContext);

  function getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let home_tries =
      game?.home_scorers?.filter((g) => g.type === "try").length || 0;
    let home_conv =
      game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
    let home_pen_kick =
      game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let home_pen_try =
      game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let home_drop_goal =
      game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_tries =
      game?.away_scorers?.filter((g) => g.type === "try").length || 0;
    let away_conv =
      game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
    let away_pen_kick =
      game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let away_pen_try =
      game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let away_drop_goal =
      game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    return {
      home_score,
      away_score,
      home_tries,
      home_conv,
      home_pen_kick,
      home_pen_try,
      home_drop_goal,
      away_tries,
      away_conv,
      away_pen_kick,
      away_pen_try,
      away_drop_goal,
    };
  }

  return (
    <Main>
      <Column>
        <SelectContainer
          styles={DropdownStyles}
          setSize={true}
          value={{
            value: selectedEngine,
            label: [
              { name: "BIG SCREEN 1", key: "BIG_SCREEN_1" },
              { name: "BIG SCREEN 2", key: "BIG_SCREEN_2" },
              { name: "BIG SCREEN 3", key: "BIG_SCREEN_3" },
            ]?.find((e) => e.key === selectedEngine)?.name,
          }}
          options={[
            { name: "BIG SCREEN 1", key: "BIG_SCREEN_1" },
            { name: "BIG SCREEN 2", key: "BIG_SCREEN_2" },
            { name: "BIG SCREEN 3", key: "BIG_SCREEN_3" },
          ].map((item, index) => {
            return {
              value: item.key,
              label: item.name,
            };
          })}
          onChange={(e) => {
            setSelectedEngine(e.value);
          }}
          placeholder="Engine"
        />
      </Column>
      <Column>
        <GraphicButton
          green
          onClick={() => {
            let score = getScore(game);

            let period = [...(game?.live_data?.periods || [])].sort(
              (a, b) => b.number - a.number
            )[0];

            sendData(
              {
                action: "animate",
                data: {
                  scene: "BS-Clock",
                  timeline: "IN",
                  data: [
                    {
                      name: "HomeScore",
                      value: { text: score?.home_score },
                    },
                    {
                      name: "AwayScore",
                      value: { text: score?.away_score },
                    },
                    {
                      name: "Period",
                      value: { text: period.number },
                    },
                    {
                      name: "HomeName",
                      value: {
                        text: game?.home_team?.variables?.big_screen_name?.toUpperCase(),
                      },
                    },
                    {
                      name: "AwayName",
                      value: {
                        text: game?.away_team?.variables?.big_screen_name?.toUpperCase(),
                      },
                    },
                  ],
                },
              },
              false
            );
          }}
        >
          Animate
        </GraphicButton>
        <GraphicButton
          green
          onClick={() => {
            sendData(
              {
                action: "animate",
                data: {
                  scene: "BS-Clock",
                  timeline: "OUT",
                  data: [],
                },
              },
              false
            );
          }}
        >
          Animate Out
        </GraphicButton>
        <GraphicButton
          green
          onClick={() => {
            let score = getScore(game);

            let period = [...(game?.live_data?.periods || [])].sort(
              (a, b) => b.number - a.number
            )[0];

            sendData(
              {
                action: "update",
                data: {
                  scene: "BS-Clock",
                  timeline: "IN",
                  data: [
                    {
                      name: "HomeScore",
                      value: { text: score?.home_score },
                    },
                    {
                      name: "AwayScore",
                      value: { text: score?.away_score },
                    },
                    {
                      name: "Period",
                      value: { text: period.number },
                    },
                    {
                      name: "HomeName",
                      value: {
                        text: game?.home_team?.short_name?.toUpperCase(),
                      },
                    },
                    {
                      name: "AwayName",
                      value: {
                        text: game?.away_team?.short_name?.toUpperCase(),
                      },
                    },
                  ],
                },
              },
              false
            );
          }}
        >
          Update
        </GraphicButton>
        <GraphicButton
          green
          onClick={() => {
            sendData(
              {
                action: "update",
                data: {
                  scene: "BS-Clock",
                  timeline: "OUT",
                  data: [
                    {
                      name: "ClckTEXT",
                      value: {
                        visible: false,
                      },
                    },
                    {
                      name: "Text",
                      value: {
                        visible: false,
                      },
                    },
                  ],
                },
              },
              false
            );
          }}
        >
          Hide Clock
        </GraphicButton>
        <GraphicButton
          green
          onClick={() => {
            sendData(
              {
                action: "update",
                data: {
                  scene: "BS-Clock",
                  data: [
                    {
                      name: "ClckTEXT",
                      value: {
                        visible: true,
                      },
                    },
                    {
                      name: "Text",
                      value: {
                        visible: true,
                      },
                    },
                  ],
                },
              },
              false
            );
          }}
        >
          Show Clock
        </GraphicButton>
        <GraphicButton
          green
          onClick={() => {
            sendData(
              {
                action: "update",
                data: {
                  scene: "BS-Clock",
                  timeline: "OUT",
                  data: [
                    {
                      name: "SS_Logo",
                      value: {
                        visible: false,
                      },
                    },
                  ],
                },
              },
              false
            );
          }}
        >
          Hide SS Logo
        </GraphicButton>
        <GraphicButton
          green
          onClick={() => {
            sendData(
              {
                action: "update",
                data: {
                  scene: "BS-Clock",
                  data: [
                    {
                      name: "SS_Logo",
                      value: {
                        visible: true,
                      },
                    },
                  ],
                },
              },
              false
            );
          }}
        >
          Show SS Logo
        </GraphicButton>
      </Column>
    </Main>
  );
}
